

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const Hotel = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                ward: "",
                address: "",
                hotelType: "",
                facility: "",
                room: "",
                bed: "",
                level: "",
                domestic: "",
                foreigner: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.address) {
                //     errors.address = 'Required';
                // }
                // if (!values.clubType) {
                //     errors.clubType = 'Required';
                // }
                // if (!values.establishedAt) {
                //     errors.establishedAt = 'Required';
                // }
                // if (!values.memberCount) {
                //     errors.memberCount = 'Required';
                // }
                // if (!values.area) {
                //     errors.area = 'Required';
                // }
                // if (!values.clubUpdate) {
                //     errors.clubUpdate = 'Required';
                // }
                // if (!values.clubMainWork) {
                //     errors.clubMainWork = 'Required';
                // }
                // if (!values.presidentName) {
                //     errors.presidentName = 'Required';
                // }
                // if (!values.presidentContact) {
                //     errors.presidentContact = 'Required';
                // }
                // else 
                if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>

                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="text" className="form-control" placeholder="ठेगाना" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <h3>क्षमता</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कोठा संख्या</label>
                                        <input type="number" className="form-control" id="example-date-input" placeholder="कोठा संख्या" name="room" onChange={handleChange} value={values.room} />
                                        <ErrorMessage className="invalid-feedback" name="room" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वेड संख्या</label>
                                        <input type="number" className="form-control" id="example-date-input" placeholder="वेड संख्या" name="bed" onChange={handleChange} value={values.bed} />
                                        <ErrorMessage className="invalid-feedback" name="bed" component="div" />
                                    </div>
                                </div>
                                <h3>वार्षिक पर्यटक संख्या</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >आन्तरिक</label>
                                        <input type="number" className="form-control" id="domestic" placeholder="आन्तरिक" name="domestic" onChange={handleChange} value={values.domestic} />
                                        <ErrorMessage className="invalid-feedback" name="domestic" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >विदेशी</label>
                                        <input type="number" className="form-control" id="example-date-input" placeholder="विदेशी" name="foreigner" onChange={handleChange} value={values.foreigner} />
                                        <ErrorMessage className="invalid-feedback" name="foreigner" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >होटेलको स्तर</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="level" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कुन किसिम</label>
                                        <select className="form-control" id="hotelType" name="hotelType" onChange={handleChange} value={values.hotelType}>
                                            <option disabled selected>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="hotelType" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >उपलब्ध सेवाहरु</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="facility" onChange={handleChange} value={values.facility} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="facility" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                </div>
                                <hr />
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </>

                // <Form>
                //     <Field type="area" name="area" />
                //     <ErrorMessage className="invalid-feedback" name="area" component="div" />
                //     <Field type="password" name="password" />
                //     <ErrorMessage className="invalid-feedback" name="password" component="div" />
                //     <button type="submit" disabled={isSubmitting}>
                //         Submit
                //     </button>
                // </Form>
            )}
        </Formik>
    </div>

);

export default Hotel;