import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const Hospital = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                ward: "",
                address: "",
                shayaCount: "",
                healths: "",
                vaccines: "",
                healthType: "",
                healthFacility: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.ward) {
                //     errors.ward = 'Required';
                // }
                // if (!values.infrastructure) {
                //     errors.infrastructure = 'Required';
                // }
                // if (!values.haatbazaarName) {
                //     errors.haatbazaarName = 'Required';
                // }
                // if (!values.haatrWard) {
                //     errors.haatrWard = 'Required';
                // }
                // if (!values.haatMarket) {
                //     errors.haatMarket = 'Required';
                // }
                // if (!values.haatInfra) {
                //     errors.haatInfra = 'Required';
                // }
                // if (!values.haatType) {
                //     errors.haatType = 'Required';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>
                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >संस्थान प्रकार</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>---------</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >शैया संख्या</label>
                                        <input type="number" className="form-control" placeholder="" name="shayaCount" onChange={handleChange} value={values.shayaCount} />
                                        <ErrorMessage className="invalid-feedback" name="shayaCount" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                </div>
                                <h3>चिकित्सक र अन्य कर्मचारीको ढरबन्दी /संख्या</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >मिति</label>
                                        <input type="date" className="form-control" id="example-date-input" placeholder="" name="date" onChange={handleChange} value={values.date} />
                                        <ErrorMessage className="invalid-feedback" name="date" component="div" />
                                    </div>

                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >चिकित्सक र अन्य कर्मचारी</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>---------</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ढरबन्दी</label>
                                        <input type="text" className="form-control" placeholder="" name="shayaCount" onChange={handleChange} value={values.shayaCount} />
                                        <ErrorMessage className="invalid-feedback" name="shayaCount" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >उपलब्धा संख्या</label>
                                        <input type="text" className="form-control" placeholder="" name="shayaCount" onChange={handleChange} value={values.shayaCount} />
                                        <ErrorMessage className="invalid-feedback" name="shayaCount" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >खोप लिने र ५ बर्ष मुनिका बालबालिका संख्या</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>---------</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >संख्या</label>
                                        <input type="text" className="form-control" placeholder="" name="shayaCount" onChange={handleChange} value={values.shayaCount} />
                                        <ErrorMessage className="invalid-feedback" name="shayaCount" component="div" />
                                    </div>
                                </div>
                                <h3>स्वास्थ्य संस्था र उपलब्ध सेवाहरुको विवरण</h3>
                                <div className="form-group row">
                                    <label className="form-control-label" >स्वास्थ्य संस्थाका सेवाहरु</label>
                                    <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                        <option disabled selected>---------</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                </div>
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>

                    </form>
                </>
            )}
        </Formik>
    </div >
);

export default Hospital;