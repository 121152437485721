import axios from "axios";
import axiosHandler from "../../../../redux/axiosHandler";
// export const LOGIN_URL = "api/auth/login";
export const LOGIN_URL = "users/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const FETCH_USER_DETAILS = "/users/user";
export const SAVE_USER_DETAILS = "/users/editUser";
export const ADD_USER_DETAILS = "/users/register";
export const ME_URL = "api/me";
const CHANGE_PASSWORD = "/users/change-password";

// export function login(email, password) {
//   return axios.post(LOGIN_URL, { email, password });
// }
export function login(emailOrUsername, password) {
	return axiosHandler.post(LOGIN_URL, { emailOrUsername, password });
}

export function register(email, fullname, username, password) {
	return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
	// Authorization head should be fulfilled in interceptor.
	return axios.get(ME_URL);
}

export function fetchUserDetails(userId) {
	return axiosHandler.get(`${FETCH_USER_DETAILS}/${userId}`);
}
export function saveUserDetails(userBody) {
	return axiosHandler.post(`${SAVE_USER_DETAILS}`, userBody);
}

export function registerUserDetails(userBody) {
	return axiosHandler.post(`${ADD_USER_DETAILS}`, userBody);
}

export function changePassword(changeBody) {
	return axiosHandler.post(`${CHANGE_PASSWORD}`, changeBody);
}
