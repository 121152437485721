import Table from "rc-table";
import React from "react";
import { useEffect } from "react";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
const FullReport = (props) => {
	const tableId = "fullReportTable";
	const { data } = props;
	useEffect(() => {
		const table = document.getElementsByTagName("table")[0];
		table.setAttribute("id", tableId);
	}, [data]);
	return (
		<>
			{data && (
				<>
					<ReactHTMLTableToExcel
						id={`${tableId}-button`}
						className="btn btn-primary float-right mb-4"
						table={tableId}
						filename={props.tableHeader}
						sheet="tablexls"
						buttonText="डाउनलोडगर्नुहोस् XLS"
					/>
					<Table
						columns={data.mapColumn}
						data={data.data}
						className="table table-bordered table-responsive"
					/>
				</>
			)}
		</>
	);
};

export default FullReport;
