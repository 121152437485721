import React from "react";
import { Formik, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	remove,
	countTitle,
	boka,
	bhakhra,
	pathyangri,
	khasi,
	pathapathi,
	annualExpenditure,
	annualIncome,
	wardQuestion,
	name,
	photo,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";

const GoatFarm = () => (
	<div>
		<Formik
			initialValues={{
				name: "",
				count: {
					boka: "",
					bhakhra: "",
					pathyangri: "",
					khasi: "",
					pathapathi: "",
				},
				ward: "",
				annualExpenditure: "",
				annualIncome: "",
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					alert(JSON.stringify(values, null, 2));
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<h3>{countTitle[selectedLanguage]}</h3>
									<br />
									<div className="col-md-6">
										<label className="form-control-label">
											{boka[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={boka[selectedLanguage]}
											name="boka"
											onChange={handleChange}
											value={values?.count.boka}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="boka"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{bhakhra[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={bhakhra[selectedLanguage]}
											name="bhakhra"
											onChange={handleChange}
											value={values?.count.bhakhra}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="bhakhra"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{pathyangri[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={pathyangri[selectedLanguage]}
											name="pathyangri"
											onChange={handleChange}
											value={values?.count.pathyangri}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="pathyangri"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{khasi[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={khasi[selectedLanguage]}
											name="khasi"
											onChange={handleChange}
											value={values?.count.khasi}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="khasi"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{pathapathi[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={pathapathi[selectedLanguage]}
											name="pathapathi"
											onChange={handleChange}
											value={values?.count.pathapathi}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="pathapathi"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{annualExpenditure[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={annualExpenditure[selectedLanguage]}
											name="annualExpenditure"
											onChange={handleChange}
											value={values?.annualExpenditure}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualExpenditure"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{annualIncome[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={annualIncome[selectedLanguage]}
											name="annualIncome"
											onChange={handleChange}
											value={values?.annualIncome}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualIncome"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default GoatFarm;
