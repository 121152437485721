import React from 'react'
import { Card } from 'react-bootstrap';
import { selectedLanguage } from '../../data/institute';

const SingleProgressBarCard = ({ datum, index }) => {
    const getdataClassName = () => {
        switch (index) {
            case 0:
                return 'primary';
            case 1:
                return 'warning';
            case 2:
                return 'success';
            default:
                break;
        }
    }
    return (

        <Card className={`card-custom card-stretch gutter-b`}>
            <Card.Body>

                <a href="#" className={`card-title font-weight-bolder text-${getdataClassName()} text-hover-state-dark font-size-h6 mb-4 d-block`}>{datum.title[selectedLanguage]}</a>
                <div className=" font-weight-bold font-size-md d-flex justify-content-end">
                    <span className={`text-${getdataClassName()}`}>{datum.data}</span>/
                    <span >{datum.total}</span>
                </div>
                <div className={`progress progress-xs my-7 bg-light-o-60`}>
                    <div className={`progress-bar bg-${getdataClassName()}`} role="progressbar" style={{ width: `${datum.percentage}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="font-weight-bold font-size-md d-flex justify-content-between">
                    <span>{datum.subtitle[selectedLanguage]}</span>
                    <span className="text-dark-75 font-size-h6 font-weight-bolder mr-2">{datum.percentage}%</span>
                </div>
            </Card.Body>
        </Card>
    );
}

export default SingleProgressBarCard;