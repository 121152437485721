import React from 'react'
import { Card } from 'react-bootstrap';
import { agriCropType, agriProductionType, foodForMonth, insecticideType } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const AgricultureDetail = ({ agriculture }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    कृषि उत्पादन
                </Card.Title>
                <h6>कृषि उत्पादन: {agriculture.agricultureProductSales ? "छ" : "छैन"}</h6>
                {
                    agriculture.agricultureProductSales && (
                        <>
                            <h6>{agriculture.agricultureProductionOrNot}</h6>
                            <h6>{agriProductionType.find(i => i.value === agriculture.agricultureProductSalesType) ? agriProductionType.find(i => i.value === agriculture.agricultureProductSalesType)[selectedLanguage] : ""}</h6>
                            <h6>कृषि बाली अनुसार किट नाशक औषधि/मल को :{agriculture.insecticides ? "छ" : "छैन"}</h6>
                            <h6>{foodForMonth.find(i => i.value === agriculture.foodForMonth) ? foodForMonth.find(i => i.value === agriculture.foodForMonth)[selectedLanguage] : ""}</h6>
                            <h6>{agriculture.toolUsed}</h6>
                            <h6>बिषादीको प्रकार :{insecticideType.find(i => i.value === agriculture.chemicalUsed) !== undefined ? insecticideType.find(i => i.value === agriculture.chemicalUsed)[selectedLanguage] : ""}</h6>
                            <h6>मलको प्रयोग :{agriculture.fertilizerUse ? "छ" : "छैन"}</h6>
                            <h6>मलको प्रकार :{agriculture.fertilizerType}</h6>
                            <h6> छ भने कस्तो किसिमको मल प्रयोग गर्नु हुन्छ?:{agriculture.seedNeed ? "छ" : "छैन"}</h6>
                        </>
                    )
                }
                {
                    agriculture.agricultureDetail?.map((agro) => {
                        console.log(agriculture.title)
                        return (
                            <>
                                <h6>अन्नवालिको नाम :{agriCropType.find(i => i.value === agro.title) !== undefined ? agriCropType.find(i => i.value === agro.title)[selectedLanguage] : ""}</h6>
                                <h6>अन्नवालिको उत्पादन परिमाण :{agro?.production?.plantCount}</h6>
                                <h6>अन्नवालिको उत्पादन परिमाण :{agro?.production?.quintal !== 0 && `${agro?.production?.quintal} quintal,`}{agro?.production?.kg !== 0 && `${agro?.production?.kg} kg`}</h6>
                                <h6>अन्नवालिको विक्री परिमाण :{agro?.sale?.plantCount}</h6>
                                <h6>अन्नवालिको विक्री परिमाण :{agro?.sale?.quintal !== 0 && `${agro?.sale?.quintal} quintal,`}{agro?.sale?.kg !== 0 && `${agro?.sale?.kg} kg`}</h6>
                                <hr />
                            </>
                        )
                    })
                }
            </Card.Body>
        </Card>
    );
}

export default AgricultureDetail;