import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import {
	add,
	wardQuestion,
	photo,
	disasterType,
	family,
	amount,
	disasterEffectQn,
	descriptions,
	disasterEffectTitle,
	name,
	disasterQn,
	disaster,
	ward,
	selectedLanguage,
	cancel,
} from "../data/institute";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	data: {
		ward: "",
		disasterType: "",
		disasterTypeOther: "",
		description: [{ name: "", amount: 0, family: 0 }],
		uniqueIdentifier: GetUUID(),
	}
};

const DisasterEffect = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data:
					{
						...props.data,
						description: props.data.description?.length ? props.data.description : [{ name: "", amount: 0, family: 0 }],
					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const addFormRow = () => {
		const prevdescription = formik.values?.description;
		prevdescription.push({ name: "", amount: 0, family: 0 });
		formik.setValues({
			...formik.values,
			description: prevdescription,
		})
	};
	const deleteFormRow = (index) => {
		const prevdescription = formik.values.description;
		prevdescription.splice(index, 1);
		formik.setValues({
			...formik.values,
			description: prevdescription,
		})
	};
	const getFormRowList = () => {
		return formik.values?.description.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-10">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>{disaster[selectedLanguage]}</label>
							<select
								className="form-control"
								id="ward"
								name={`description[${i}].disaster`}
								onChange={formik.handleChange}
								value={formik.values?.description[i].disaster}
							>
								{descriptions.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{amount[selectedLanguage]}</label>
							<input
								type="number"
								name={`description[${i}].amount`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.description[i].amount}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{family[selectedLanguage]}</label>
							<input
								type="number"
								name={`description[${i}].family`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.description[i].family}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<a
								href="javascript:;"
								onClick={() => deleteFormRow(i)}
								className="btn btn-sm font-weight-bolder btn-light-danger"
							>
								<i className="la la-trash-o"></i>
								{cancel[selectedLanguage]}
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{disasterEffectQn[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="disasterType"
								name="disasterType"
								onChange={formik.handleChange}
								value={formik.values?.disasterType}
							>
								{disasterType.map((type) => (
									<option value={type.value}>
										{type[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<input
								type="text"
								className="form-control"
								placeholder="अन्य"
								name="disasterTypeOther"
								onChange={formik.handleChange}
								value={formik.values?.disasterTypeOther}
							/>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								{ward.map((woda) => (
									<option value={woda.value}>
										{woda[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="form-group row">
						<div className="col-md-6">
							<label>{photo[selectedLanguage]}</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div>
					</div>
					<hr />
					<div>
						<h3>{disasterEffectTitle[selectedLanguage]}</h3>
						{getFormRowList()}
						<div className="form-group row">
							<div className="col-lg-4">
								<a
									href="javascript:;"
									onClick={addFormRow}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div>
					</div>
					<div className="card-footer text-right">
						<FormFooterButtons />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
}

export default DisasterEffect;
