import React from 'react'
import { Card } from 'react-bootstrap';

const AnimalDetail = ({ livestock }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    पशु पन्छी जानकारी
                </Card.Title>
                <h6>पशु पन्छी पाल्नु भएको :{livestock.animalBirdInHouse}</h6>
                <h6>पाल्नु भएको प्रायोजन :{livestock.animalHousePurpose}</h6>
                {
                    livestock.animalDetail?.map((animal) => (
                        <>
                            <h6>पशु पन्छी: {animal.name}</h6>
                            <h6>वार्षिक आम्दानी: {animal.annualIncome}</h6>
                            <h6>वार्षिक खर्च: {animal.sale}</h6>
                            <h6>पशु पन्छी : {animal.sale}</h6>
                            {
                                animal.specification.map((spec) => (
                                    <>
                                        <h6>पशु पन्छी प्रकार: {spec.title}</h6>
                                        <h6>पशु पन्छी मात्रा: {spec.quantity}</h6>
                                        <h6>पशु पन्छी एकाई: {spec.unit}</h6>
                                    </>
                                ))
                            }
                        </>
                    ))
                }
            </Card.Body>
        </Card>
    );
}

export default AnimalDetail;