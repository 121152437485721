import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage, useFormik } from "formik";
import FormFooterButtons from "../components/FormFooterButtons";
import { wardSelect } from "../data/advanceSearch";
import {
	selectedLanguage,
	add,
	remove,
	wardQuestion,
	socialSecurityTitle,
	optionList,
	fiscalYear,
	ward,
	cancel,
} from "../data/institute";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	data: {
		ward: "",
		fiscalYear: [""],
		socialSecurities: [[{ option: "", male: 0, female: 0, amount: 0 }]],
		uniqueIdentifier: GetUUID(),
	}
};
const SocialSecurity = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						socialSecurities: props.data.socialSecurities?.length ? props.data.socialSecurities : [[{ option: "", male: 0, female: 0, amount: 0 }]],

					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const deleteFormRowParent = (index) => {
		const prevSecurity = [...formik.values.socialSecurities];
		prevSecurity.splice(index, 1);
		const prevFiscal = [...formik.values.fiscalYear];
		prevFiscal.splice(index, 1);
		formik.setValues({
			...formik.values,
			socialSecurities: prevSecurity,
			fiscalYear: prevFiscal,
		})
	}
	// 	this.setState((prevState) => ({
	// 		data: {
	// 			...prevState.data,
	// 			socialSecurities: prevSecurity,
	// 			fiscalYear: prevFiscal,
	// 		},
	// 	}));
	// };
	const addFormRow = (parentIndex) => {
		const prevSecurity = [...formik.values.socialSecurities];
		prevSecurity[parentIndex] = [
			...prevSecurity[parentIndex],
			{ option: "", male: 0, female: 0, amount: 0 },
		];
		formik.setValues({
			...formik.values,
			socialSecurities: prevSecurity,
		})
	};
	const deleteFormRow = (index, parentIndex) => {
		const prevSecurity = [...formik.values.socialSecurities];
		prevSecurity[parentIndex].splice(index, 1);
		formik.setValues({
			...formik.values,
			socialSecurities: prevSecurity,
		})
	};
	const getFormRowListParent = () => {
		return formik.values?.socialSecurities?.map((row, i) => (
			<Card key={i} className="mb-3">
				<Card.Body>
					<div>
						<div className="form-group row">
							<div className="col-12 d-flex justify-content-between">
								<h3>सामाजिक सुरक्षा कार्याक्रम विवरण</h3>
								{i !== 0 && (
									<a
										href="javascript:;"
										onClick={(e) => deleteFormRowParent(i)}
										className="btn btn-sm font-weight-bolder btn-light-danger"
									>
										<i className="la la-plus"></i>
										{cancel[selectedLanguage]}
									</a>
								)}
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">मिति</label>
								<input
									type="number"
									className="form-control"
									name={`fiscalYear[${i}]`}
									value={formik.values?.fiscalYear[i]}
									onChange={formik.handleChange}
									placeholder="YYYY"
									min="2017"
									max="2100"
								/>
							</div>
						</div>

						<div className="form-group row">
							<div className="col-lg-3">
								<a
									href="javascript:;"
									onClick={(e) => addFormRow(i)}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div>
						{getFormRowList(i)}
					</div>
				</Card.Body>
			</Card>
		));
	};
	const getFormRowList = (parentIndex) => {
		return formik.values?.socialSecurities[parentIndex].map((row, i) => (
			<div className="form-group row" key={`child-${parentIndex}-${i}`}>
				<div className="col-md-4">
					{i == 0 && <label className="form-control-label">श्रेणी</label>}
					<select
						className="form-control"
						id="incidentType"
						name={`socialSecurities[${parentIndex}][${i}].option`}
						onChange={formik.handleChange}
						value={formik.values?.socialSecurities[parentIndex][i]?.option}
					>
						{optionList.map((info, index) => (
							<option value={info.value}>{info[selectedLanguage]}</option>
						))}
					</select>
				</div>
				<div className="col-md-2">
					{i == 0 && <label className="form-control-label">पुरुष </label>}
					<input
						type="number"
						className="form-control"
						placeholder="पुरुष"
						name={`socialSecurities[${parentIndex}][${i}].male`}
						onChange={formik.handleChange}
						value={formik.values?.socialSecurities[parentIndex][i]?.male}
					/>
				</div>
				<div className="col-md-2">
					{i == 0 && <label className="form-control-label">महिला </label>}
					<input
						type="number"
						className="form-control"
						placeholder="महिला"
						name={`socialSecurities[${parentIndex}][${i}].female`}
						onChange={formik.handleChange}
						value={formik.values?.socialSecurities[parentIndex][i]?.female}
					/>
				</div>
				{/* <div className="col-md-2">
					{i == 0 && <label className="form-control-label">महिला </label>}
					<p>
						({formik.values?.socialSecurities[parentIndex][i]?.female} +
						{formik.values?.socialSecurities[parentIndex][i]?.male})
					</p>
				</div> */}

				<div className="col-md-2">
					{i == 0 && <label className="form-control-label">रकम </label>}
					<input
						type="number"
						className="form-control"
						placeholder="रकम"
						name={`socialSecurities[${parentIndex}][${i}].amount`}
						onChange={formik.handleChange}
						value={formik.values?.socialSecurities[parentIndex][i]?.amount}
					/>
				</div>
				{/* <div className="col-md-2">
                    <label className="form-control-label" >
                        स्त्री</label>
                    <input type="text" className="form-control" placeholder="स्त्री" name="female" onChange={formik.handleChange} value={values.female} />
                    <ErrorMessage className="invalid-feedback" name="female" component="div" />
                </div> */}
				{i !== 0 && (
					<div className="col-md-2">
						<a
							href="javascript:;"
							onClick={(e) => deleteFormRow(i, parentIndex)}
							className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
						>
							<i className="la la-trash-o"></i>
							{cancel[selectedLanguage]}
						</a>
					</div>
				)}
			</div>
		));
	};

	const addFormRowParent = () => {
		const prevSecurity = [...formik.values.socialSecurities];
		prevSecurity.push([{ incidents: "", total: 0 }]);
		const prevFiscal = [...formik.values.fiscalYear];
		prevFiscal.push("");
		formik.setValues({
			...formik.values,
			socialSecurities: prevSecurity,
			fiscalYear: prevFiscal,
		})
		// this.setState((prevState) => ({
		// 	data: {
		// 		...prevState.data,
		// 		socialSecurities: prevSecurity,
		// 		fiscalYear: prevFiscal,
		// 	},
		// }));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="incidentType"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								<option disabled selected value="">
									वडा नम्बर छान्नुहोस{" "}
								</option>
								{wardSelect.map((ward, index) => (
									<option key={index} value={ward.value}>
										{ward[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
					</div>
				</Card.Body>
			</Card>
			{getFormRowListParent()}
			<div className="form-group row">
				<div className="col-12 text-center">
					<a
						href="javascript:;"
						onClick={addFormRowParent}
						className="btn btn-sm font-weight-bolder btn-primary"
					>
						<i className="la la-plus"></i>खण्ड थप्नुहोस्
					</a>
				</div>
			</div>
			<div className="card-footer text-right">
				<FormFooterButtons isSubmitting={formik.isSubmitting} />
			</div>
		</form>
	);
}
export default SocialSecurity;
