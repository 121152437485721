import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const PravidikGraph = () => {
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle className="w-100 d-flex" as={Button} variant="primary-light" eventKey="2">
                    <h3 className="p-5">
                        प्राविधिक शिक्षा  विवरण
                    </h3>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
                <Card.Body>
                    <div
                        id="prabidhik_chart"
                        className="card-rounded-bottom"
                        style={{ height: "500px" }}
                    >
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default PravidikGraph;