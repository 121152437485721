import React from 'react'
import { Card } from 'react-bootstrap';
import { drinkingwaterLists, economic, loanTakingReason, mainIncomeSource } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const AarthikKshetraJankari = ({ economics }) => {
    return (

        <Card>
            <Card.Body className="house-detail">
                <Card.Title>
                    <h3>आर्थिक क्षेत्र जानकारी</h3>
                </Card.Title>
                <h6>1. वार्षिक आम्दानीको मुख्य स्रोत : {mainIncomeSource.find(i => i.value === economics.mainIncomeSource) ? mainIncomeSource.find(i => i.value === economics.mainIncomeSource)[selectedLanguage] : ""}</h6>
                <h6>2. वार्षिक खर्च : {economics.nonAgriExpense}</h6>
                <h6>3. वार्षिक आम्दानी : {economics.nonAgriIncome}</h6>
                <h6>4. आफ्नो उत्पादनले तपाई को परिवारलाई कति महिना खान पुग्छ {drinkingwaterLists.find(i => i.value === economics.waterSource) ? drinkingwaterLists.find(i => i.value === economics.waterSource)[selectedLanguage] : ""}:</h6>
                {/* <h6>5. नगद ऋण दिनु भएको छ : {drinkingwaterLists.find(i => i.value === economics.waterSource) ? drinkingwaterLists.find(i => i.value === economics.waterSource)[selectedLanguage] : ""}</h6> */}
                <h6>6. ऋण लिनु भएको छ : {economics.loanTakenInFamily ? "छ" : "छैन"}</h6>
                {
                    economics.loanTakenInFamily && (
                        <h6>7. ऋणको उदेश्यके हो ? : {economics.loanTakingReason.map((reason) => {
                            return loanTakingReason.find(i => i.value === reason) ? loanTakingReason.find(i => i.value === reason)[selectedLanguage] + ", " : ""
                        })}</h6>
                    )
                }
                {/* <h6>8. ऋणको प्रकर : {drinkingwaterLists.find(i => i.value === economics.waterSource) ? drinkingwaterLists.find(i => i.value === economics.waterSource)[selectedLanguage] : ""}</h6> */}
            </Card.Body>
        </Card>
    );
}

export default AarthikKshetraJankari;