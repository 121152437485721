import Table from 'rc-table';
import React, { useEffect } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const SchoolReport = (props) => {
    const { data, mapColumn } = props.data;
    const table1Columns = ["ward", "schoolName", "address", "schoolType", "schoolLevel", "email"]
    const table1MapColumn = mapColumn?.filter(i => table1Columns.includes(i.dataIndex));
    const table2Columns = ["विद्यालयको नाम", "वडा नम्बर", "विद्यालयले भोग चलन गरेको जग्गाको क्षेत्रफल", "विद्यालको नाममा रहेको जग्गा क्षेत्रफल", "सुधारिएको मापदण्ड (भूकम्प प्रक्षतरोधी ) अनुसार निर्माण", "विद्यालयले प्रयोग गरेको जग्गाको लालपुजा", "रेट्रोक्षफक्षिङ्ग गरेको", "अपाङ्गता तथा विशेष शिक्षा स्रोत कक्षा संचालन"];
    const table2MapColumn = mapColumn?.filter(i => table2Columns.includes(i.title));
    // const table3Columns = ["विद्यालयको नाम", "वडा नम्बर", "विद्यालयले भोग चलन गरेको जग्गाको क्षेत्रफल", "विद्यालको नाममा रहेको जग्गा क्षेत्रफल", "सुधारिएको मापदण्ड (भूकम्प प्रक्षतरोधी ) अनुसार निर्माण", "विद्यालयले प्रयोग गरेको जग्गाको लालपुजा", "रेट्रोक्षफक्षिङ्ग गरेको", "अपाङ्गता तथा विशेष शिक्षा स्रोत कक्षा संचालन"];
    // const table3MapColumn = mapColumn?.filter(i => table3Columns.includes(i.title));
    const table4aColumns = ["विद्यालयको नाम", "वडा नम्बर", "भवन सम्बन्धि", "कोठा संख्या", "शौचालय", "अपङ्गमैत्री शौचालय", "हात्धुने ठाउँको व्यवस्था", "शुद्ध खानेपानी"];
    const table4aMapColumn = mapColumn?.filter(i => table4aColumns.includes(i.title));
    //प्रयोगशाला(विज्ञान/कम्पियूटर/अन्य) key not found
    const table4bColumns = ["विद्यालयको नाम", "वडा नम्बर", "पुस्तकालय र अध्ययन केन्द्र", "डेस्क/बेन्चको क्षमता (जना)", "खेल मैदान", "कमपाउण्ड वाल", "फोहर विसर्जन गर्ने उचित ठाउँ", "निर्माण भएको बर्ष"];
    const table4bMapColumn = mapColumn?.filter(i => table4bColumns.includes(i.title));
    const table5Columns = ["विद्यालयको नाम", "वडा नम्बर", "बिद्यालयको प्रकार", "student report"];
    const table5MapColumn = mapColumn?.filter(i => table5Columns.includes(i.title));
    const table6Columns = ["वडा नम्बर", "विद्यालयको नाम", "शिक्षक शिक्षीका report"];
    const table6MapColumn = mapColumn?.filter(i => table6Columns.includes(i.title));
    const table7Columns = ["वडा नम्बर", "विद्यालयको नाम", "भर्ना दर , निरन्तरता दर , सिकाई उपलब्धी दर report"];
    const table7MapColumn = mapColumn?.filter(i => table7Columns.includes(i.title));
    const table8Columns = ["वडा नम्बर", "विद्यालयको नाम", "विद्यार्थी उतिर्ण दर तथा विद्यार्थीले पाउने सेवा सुविधा सम्बन्धी"];
    const table8MapColumn = mapColumn?.filter(i => table8Columns.includes(i.title));
    const table9Columns = ["वडा नम्बर", "विद्यालयको नाम", "अपाङ्गता तथा विशेष शिक्षा स्रोत कक्षा संचालन", "CBERGA कार्यनयाँन भएको ?", "एकीकृत पाठ्यक्रम", "इन्टरनेटको सुविधा", "छुट्टै प्रधानाध्यापक दरबन्दी"];
    const table9MapColumn = mapColumn?.filter(i => table9Columns.includes(i.title));
    const table10Columns = ["वडा नम्बर", "विद्यालयको नाम", "बालमैत्री शिक्षा सम्बन्धि विवरण",];
    const table10MapColumn = mapColumn?.filter(i => table10Columns.includes(i.title));
    const table11Columns = ["वडा नम्बर", "विद्यालयको नाम", "छात्रवृत्ति तथा लक्षित सुविधाको विवरण"];
    const table11MapColumn = mapColumn?.filter(i => table11Columns.includes(i.title));
    const table12Columns = ["वडा नम्बर", "विद्यालयको नाम", "छाडेका वालक वालिका report"];
    const table12MapColumn = mapColumn?.filter(i => table12Columns.includes(i.title));
    // const table13Columns = ["वडा नम्बर", "विद्यालयको नाम", "शिक्षक शिक्षीका report"];
    // const table13MapColumn = mapColumn?.filter(i => table13Columns.includes(i.title));
    const mapColumnArr = [
        { value: "1", title: "टेवल १", columns: table1MapColumn },
        { value: "2", title: "टेवल २", columns: table2MapColumn },
        { value: "4a", title: "१. विद्यालयको भौतिक अवस्था", columns: table4aMapColumn },
        { value: "4b", title: "२. विद्यालयको भौतिक अवस्था", columns: table4bMapColumn },
        { value: "5", title: "३. तहगत रुपमा विद्यालय तथा विद्यार्थी संख्या", columns: table5MapColumn },
        { value: "6", title: "४. शिक्षक विवरण", columns: table6MapColumn },
        { value: "7", title: "५.  भर्ना दर , निरन्तरता दर , सिकाई उपलब्धी दर   (विद्यालयका लागि )", columns: table7MapColumn },
        { value: "8", title: "६. विद्यार्थी उतिर्ण दर  तथा  विद्यार्थीले पाउने सेवा  सुविधा सम्बन्धी", columns: table8MapColumn },
        { value: "9", title: "७. शैक्षिक गुणस्तर सम्बन्धी विवरण", columns: table9MapColumn },
        { value: "10", title: "८. बालमैत्री  शिक्षा सम्बन्धी विवरण", columns: table10MapColumn },
        { value: "11", title: "९. छात्रवृत्ति तथा लक्षित सुविधाको विवरण", columns: table11MapColumn },
        { value: "12", title: "१०. विद्यालय छाडेका वाल वालिका संख्या", columns: table12MapColumn },
    ]
    useEffect(() => {
        const tables = document.getElementsByTagName("table")
        tables.forEach((table, index) => {
            table.setAttribute("id", `schoolTable${index}`)

        });
    }, [data])
    return (
        <>
            <Card>
                <Card.Body>

                    <Tabs size="sm" variant="pills" defaultActiveKey={"1"} id="uncontrolled-tab-example" >
                        {mapColumnArr.map((mapColumnCurr, index) => {

                            return (
                                <Tab key={mapColumnCurr.value} eventKey={mapColumnCurr.value} title={mapColumnCurr.title}>
                                    <div className="d-flex justify-content-between mb-4">
                                        <h3>{mapColumnCurr.title}</h3>
                                        <ReactHTMLTableToExcel
                                            id={`${index}-button`}
                                            className="btn btn-primary float-right mb-4"
                                            table={`schoolTable${index}`}
                                            filename={mapColumnCurr.title}
                                            sheet={mapColumnCurr.title}
                                            buttonText="डाउनलोडगर्नुहोस् XLS" />

                                    </div>
                                    <Table columns={mapColumnCurr.columns} data={data} className="table table-bordered table-responsive" />
                                </Tab>)
                        })}
                    </Tabs>
                </Card.Body>
            </Card>
            {/* {
                mapColumnArr.map((mapColumnCurr, index) => (
                    <>
                    </>

                ))
            } */}
        </>
    );
}

export default SchoolReport;