/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { AsideMenuItem } from "./AsideMenuItem";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import SmsModal from "../../../../../app/components/SmsModal";

export function AsideMenuList({ layoutProps }) {
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
			: "";
	};
	const [show, setShow] = useState(false);
	const [phoneNumbersForSms, setphoneNumbersForSms] = useState([]);
	const smsLoading = false;
	const openSmsModal = () => {
		setShow(true);
	};
	const menuItems = [
		{
			title: "गृह पृष्ठ",
			icon: "/media/svg/icons/Layout/Layout-4-blocks.svg",
			subMenuItems: [],
			link: "/dashboard",
			action: null,
		},
		{
			title: "विवरण",
			icon: "/media/svg/icons/General/User.svg",
			subMenuItems: [
				// {
				//   title: "संस्था विवरण",
				//   link: "/organization"
				// action: null
				// },
				{
					title: "घरधुरी तथा पारिवारिक विवरण",
					link: "/home-survey",
					action: null,
				},
				{
					title: "कृषि तथा पशुपन्छी विवरण",
					link: "/agriculture-survey",
					action: null,
				},
			],
			link: "/organization",
			action: null,
		},
		{
			title: "खोज्नुहोस्",
			icon: "/media/svg/icons/General/Search.svg",
			subMenuItems: [
				{
					title: "घर विस्तृत खोजी",
					link: "/search",
					action: null,
				},
			],
			link: "/search",
			action: null,
		},
		{
			title: "सर्वेक्षण",
			icon: "/media/svg/icons/Shopping/Sort3.svg",
			subMenuItems: [
				{
					title: "घर सर्वेक्षण",
					link: "/home-survey/list",
					action: null,
				},
				// {
				//   title: "बाल विवाह सर्वेक्षण",
				//   link: "/survey2"
				// action: null
				// },
				{
					title: "संस्था सर्वेक्षण",
					link: "/survey",
					action: null,
				},
			],
			link: "/survey",
			action: null,
		},
		{
			title: "नक्शा",
			icon: "/media/svg/icons/Map/Location-arrow.svg",
			subMenuItems: [],
			link: "/map",
			action: null,
		},
		{
			title: "SMS",
			icon: "/media/svg/icons/Communication/Sending.svg",
			subMenuItems: [],
			link: "javascript:void()",
			action: openSmsModal,
		},
		{
			title: "लग",
			icon: "/media/svg/icons/Text/Bullet-list.svg",
			subMenuItems: [],
			link: "/log",
			action: null,
		},
		{
			title: "प्रयोगकर्ता",
			icon: "/media/svg/icons/General/User.svg",
			subMenuItems: [],
			link: "/users/list",
			action: null,
		},
	];
	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{menuItems.map(function(menuItem, index) {
					return (
						<AsideMenuItem menuItem={menuItem} key={index}></AsideMenuItem>
					);
				})}
			</ul>
			<SmsModal
				setShow={setShow}
				show={show}
				setphoneNumbersForSms={setphoneNumbersForSms}
				phoneNumbersForSms={phoneNumbersForSms}
				loading={smsLoading}
			/>

			{/* end::Menu Nav */}
		</>
	);
}
