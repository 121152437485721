import React from 'react'
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useLocation } from "react-router";

export function AsideMenuSubItem({ subMenuItem }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>

            <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                    {/*begin::2 Level*/}
                    <li
                        className={`menu-item menu-item-submenu ${getMenuItemActive(
                            subMenuItem.link
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <NavLink className="menu-link menu-toggle" to={subMenuItem.link}>
                            <i className="menu-bullet menu-bullet-dot">
                                <span />
                            </i>
                            <span className="menu-text">{subMenuItem.title}</span>
                        </NavLink>
                    </li>
                    {/*end::2 Level*/}
                </ul>
            </div>
        </>
    )
}