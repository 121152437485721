import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	beating,
	mentalAbuse,
	wardQuestion,
	contempt,
	gharnikala,
	divorce,
	photo,
	sale,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	beating: "",
	mentalAbuse: "",
	contempt: "",
	gharnikala: "",
	divorce: "",
	sale: "",
	uniqueIdentifier: GetUUID(),
};

const GenderCrime = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{beating[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="beating"
											onChange={handleChange}
											value={values.beating}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="beating"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{mentalAbuse[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="mentalAbuse"
											onChange={handleChange}
											value={values.mentalAbuse}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="mentalAbuse"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{contempt[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="contempt"
											onChange={handleChange}
											value={values.contempt}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="contempt"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{gharnikala[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="gharnikala"
											onChange={handleChange}
											value={values.gharnikala}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="gharnikala"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{divorce[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="divorce"
											onChange={handleChange}
											value={values.divorce}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="divorce"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{sale[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="sale"
											onChange={handleChange}
											value={values.sale}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="sale"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default GenderCrime;
