import React from 'react'
import { Card } from 'react-bootstrap';
import { roadLists, wastemanagementLists } from '../../data/advanceSearch';
import { houseType, rooftype, wasteDisposal } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const GharJagga = ({ houseLand }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    घरजग्गा जानकारी
                </Card.Title>
                <h6>1. घर संख्या : {houseLand.houseCount}</h6>
                <h6>2. जम्मा तल्ला : {houseLand.totalStorey}</h6>
                <h6>3. जम्मा कोठा : {houseLand.totalRoom}</h6>
                <h6>4. नक्सा पास : {houseLand.hasHouseMapPass ? "छ" : "छैन"}</h6>
                <h6>5. मापदण्ड : {houseLand.doHouseCriteriaFullfill ? "छ" : "छैन"}</h6>
                {/* <h6>6. स्वामित्व : {houseLand.}</h6> */}
                <h6>6. छानाको प्रकार :  {rooftype.find(i => i.value === houseLand.roofType) ? rooftype.find(i => i.value === houseLand.roofType)[selectedLanguage] : ""}</h6>
                <h6>7. घर को प्रकार :{houseType.find(i => i.value === houseLand.houseType) ? houseType.find(i => i.value === houseLand.houseType)[selectedLanguage] : ""}</h6>
                <h6>8. सडकको सुबिधा :{roadLists.find(i => i.value === houseLand.road) ? roadLists.find(i => i.value === houseLand.road)[selectedLanguage] : ""}</h6>
                <h6>9. घर भाडामा : {houseLand.houseRent ? "छ" : "छैन"}</h6>
                <h6>10. जम्मा जग्गा क्षेत्रफल :{`${houseLand.nambari.ropani}-${houseLand.nambari.aana}-${houseLand.nambari.paisa}`}</h6>
            </Card.Body>
        </Card>
    );
}

export default GharJagga;