import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import FullReport from '../components/FullReport';
import Loader from '../components/Loader';
import SchoolReport from '../components/SchoolReport';
import { Categories } from '../data/Categories';
import { viewDataDetailsById, viewInstituteReportById } from '../modules/InstituteSurvey/_redux/surveyCrud';

const SurveyReport = (props) => {
    const subCatRoute = props.match.params.subCatId;
    const categoryId = props.match.params.id;
    const [data, setData] = useState(undefined)
    const [loading, setloading] = useState(true)
    const tableHeader = Categories.find(i => i.id == categoryId).subCategories.find(j => j.route == subCatRoute)
    useEffect(() => {
        viewInstituteReportById(subCatRoute).then((res) => {
            setloading(false)
            if (res.data) {
                console.log(res.data)
                setData(res.data)
            }
        }).catch((err) => {
            setloading(false)
        })
    }, [])
    return (
        <>
            <Card style={{ width: '100%' }}>
                <Card.Body >
                    <Card.Title className="d-flex just">
                        <h1 className="mb-10">{tableHeader.title}</h1>
                    </Card.Title>

                    {
                        loading && <Loader loading={loading} />
                    }
                    {
                        !loading && (
                            <>
                                {data && tableHeader.route !== "school" && (<FullReport data={data} tableHeader={tableHeader} />)}
                                {data && tableHeader.route === "school" && (<SchoolReport data={data} tableHeader={tableHeader} />)}
                            </>
                        )
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default SurveyReport;