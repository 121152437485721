import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import SingleDataReport from '../components/SingleDataReport';
import { Categories } from '../data/Categories';
import { janjati } from '../data/institute';
import { viewDataDetailsById } from '../modules/InstituteSurvey/_redux/surveyCrud';

const InstituteSurveyView = (props) => {
    const categoryId = props.match.params.id;
    const subCatId = props.match.params.subCatId;
    const dataId = props.match.params.dataId;
    const category = Categories.find(i => i.id == categoryId);
    const selectedSubCat = category.subCategories.find(i => i.id == subCatId);
    const [data, setData] = useState(undefined)
    useEffect(() => {
        viewDataDetailsById(dataId, selectedSubCat.route).then((res) => {
            if (res.data) {
                console.log(res.data)
                setData(res.data)
            }
        })
    }, [])
    return (
        <>
            <Card style={{ width: '100%' }}>
                <Card.Body >
                    <Card.Title>
                        <h1 className="mb-10">{data?.header}</h1>
                    </Card.Title>
                    {data && (<SingleDataReport data={data} />)}
                </Card.Body>
            </Card>
        </>
    );
}
export default InstituteSurveyView;