// import { render } from 'node-sass'
import React, { useContext } from 'react'
import { Button, Card } from 'react-bootstrap'
import { Categories } from '../data/Categories.js';
import { addInstituteData, deleteInstituteData } from '../modules/InstituteSurvey/_redux/surveyCrud.js';
import { SubCatRouteContext } from '../providers/SubCatRouteProvider.js';
import SurveyList from './SurveyList.js';

export const SubCatRoute = React.createContext();
export function useSubCatRoute() {
    return useContext(SubCatRoute)
}
export class InstituteSurveyForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            category:
            {
                id: 0,
                title: "",
                pageTitle: "",
                subCategories: [
                ],
            },
            subCategories: [],
            selectedSubCategoryId: 0,
            subCategoryComponent: null,
            subCatRoute: "",
            savedSubCatRoute: "",
            createOn: false,
            data: null,
            fileList: {

            }
        };
        // // this.change = this.change.bind(this);
    }
    componentDidMount() {
        const categoryId = this.props.match.params.id;
        const category = Categories.find(i => i.id == categoryId);
        this.setState({
            subCategories: category.subCategories, category,
            selectedSubCategoryId: category.subCategories[0].id,
            subCatRoute: category.subCategories[0].route,
            savedSubCatRoute: category.subCategories[0].route,
            subCategoryComponent: category.subCategories[0].component,
            fileList: {
                [category.subCategories[0].route]: {
                    image: "",
                    fileName: "",
                },
            }
        })
    }
    change = function (event) {
        let subCatId = event.target.value;
        let subCat = this.state.subCategories.find(i => i.id == subCatId);
        this.setState({
            subCategoryComponent: subCat.component,
            selectedSubCategoryId: subCatId,
            subCatRoute: subCat.route,
            savedSubCatRoute: subCat.route,
            fileList: {
                [subCat.route]: {
                    image: "",
                    fileName: "",
                },
            }
        })
    };
    newData = () => {
        console.log(this.state)
        this.setState({
            ...this.state,
            createOn: true
        })
    }
    editData = (id) => {
        this.props.history.push(`/survey/form/${this.state.category.id}/${this.state.selectedSubCategoryId}/edit/${id}`);
    }
    viewData = (id) => {
        this.props.history.push(`/survey/form/${this.state.category.id}/${this.state.selectedSubCategoryId}/view/${id}`);
    }
    submitData = (formData) => {
        console.log(formData)
        addInstituteData(this.state.subCatRoute, formData).then((res) => {
            console.log(res)
            if (res.data) {
                console.log(res.data)
                this.setState(prev => {
                    return {
                        ...prev,
                        createOn: false,
                        subCatRoute: ""
                    }
                }, () => this.setState(pre => {
                    return {
                        ...pre,
                        subCatRoute: pre.savedSubCatRoute
                    }
                }))
            }
        })
    }
    deleteData = (id) => {
        if (window.confirm("के तपाई यो डाटा हटाउन चाहनुहुन्छ?")) {
            deleteInstituteData(this.state.subCatRoute, id).then((res) => {
                this.setState(prev => {
                    return {
                        ...prev,
                        subCatRoute: ""
                    }
                }, () => this.setState(pre => {
                    return {
                        ...pre,
                        subCatRoute: pre.savedSubCatRoute
                    }
                }))
            })
        }
    }
    report = () => {
        this.props.history.push(`/survey/report/${this.props.match.params.id}/${this.state.subCatRoute}`);
    }
    render() {

        return (
            <>
                <Card style={{ width: '100%' }}>
                    <Card.Body >
                        <Card.Title>
                            <h1 className="mb-10">{this.state.category.title}</h1>
                        </Card.Title>
                        <div className="form-group row">
                            <div className="col-md-6">
                                <label className="form-control-label" >{this.state.category.pageTitle} किसिम चयन गनुहोस</label>
                                <select className="form-control" id="exampleSelect1" onChange={(event) => this.change(event)}>
                                    {/* <option disabled selected>{this.state.category.pageTitle} किसिम चयन गनुहोस</option> */}
                                    {
                                        this.state.subCategories.map((subCategory, index) => {
                                            return (
                                                <option selected={subCategory.id === this.state.selectedSubCategoryId} key={index} value={subCategory.id}>{subCategory.title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            {
                                !this.state.createOn ? (
                                    <div className="col-md-6 d-flex justify-content-end align-items-center">
                                        <Button variant="light-success" className="mr-3" onClick={this.report}>
                                            {/* <i style={{ position: "relative", bottom: "1px" }} className="flaticon2-add-1 icon-nm"></i> */}
                                            रिपोर्ट
                                        </Button>
                                        <Button variant="light-primary" onClick={this.newData}><i style={{ position: "relative", bottom: "1px" }} className="flaticon2-add-1 icon-nm"></i>थप्नुहोस</Button>
                                    </div>) : null
                            }
                        </div>
                        {
                            !this.state.createOn && (<SurveyList editData={this.editData} deleteData={this.deleteData} viewData={this.viewData} subCatRoute={this.state.subCatRoute} />)
                        }
                    </Card.Body>
                </Card>
                {
                    this.state.createOn && (this.state.subCategoryComponent && (
                        <SubCatRouteContext route={this.state.subCatRoute} fileListContent={this.state.fileList}>
                            <this.state.subCategoryComponent submit={this.submitData} fileListContent={this.state.fileList} />
                        </SubCatRouteContext>
                    ))
                }
            </>
        )
    }
}
