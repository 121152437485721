import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import {
	add,
	wardQuestion,
	photo,
	address,
	riskArea,
	dead,
	injured,
	effectFromDisasterQn,
	disaster,
	newEffectFromDistaters,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
// import { KTFormRepeater } from '../data/helpers/Repeater_form.js'
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import ImageUploader from "../../app/components/ImageUploader";

const initData = {
	data: {
		ward: "",
		riskArea: "",
		address: "",
		effectFromDisaster: [{ disaster: "", dead: 0, injured: 0 }],
		uniqueIdentifier: GetUUID(),
	}
};
const NaturalDisaster = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						effectFromDisaster: props.data.effectFromDisaster?.length ? props.data.effectFromDisaster : [{ disaster: "", dead: 0, injured: 0 }],
					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const addFormRow = () => {
		const preveffectFromDisaster = formik.values?.effectFromDisaster;
		preveffectFromDisaster.push({ disaster: "", dead: 0, injured: 0 });
		formik.setValues({
			...formik.values,
			effectFromDisaster: preveffectFromDisaster,
		})
	};
	const deleteFormRow = (index) => {
		const preveffectFromDisaster = formik.values?.effectFromDisaster;
		preveffectFromDisaster.splice(index, 1);
		formik.setValues({
			...formik.values,
			effectFromDisaster: preveffectFromDisaster,
		})
	};
	const getFormRowList = () => {
		return formik.values?.effectFromDisaster.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-10">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>{disaster[selectedLanguage]}</label>
							<select
								className="form-control"
								id="ward"
								name={`effectFromDisaster[${i}].disaster`}
								onChange={formik.handleChange}
								value={formik.values?.effectFromDisaster[i].disaster}
							>
								{newEffectFromDistaters.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{dead[selectedLanguage]}</label>
							<input
								type="number"
								name={`effectFromDisaster[${i}].dead`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.effectFromDisaster[i].dead}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{injured[selectedLanguage]}</label>
							<input
								type="email"
								name={`effectFromDisaster[${i}].injured`}
								onChange={formik.handleChange}
								className="form-control"
								value={formik.values?.effectFromDisaster[i].injured}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<a
								href="javascript:;"
								onClick={() => deleteFormRow(i)}
								className="btn btn-sm font-weight-bolder btn-light-danger mt-10"
							>
								<i className="la la-trash-o"></i>
								{cancel[selectedLanguage]}
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<h3>विपदबाट मानवीय क्षती (विगत ५ वर्षको )</h3>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{riskArea[selectedLanguage]}
							</label>
							<input
								type="text"
								className="form-control"
								placeholder={riskArea[selectedLanguage]}
								name="riskArea"
								onChange={formik.handleChange}
								value={formik.values?.riskArea}
							/>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								{ward.map((woda) => (
									<option value={woda.value}>
										{woda[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{address[selectedLanguage]}
							</label>
							<input
								type="text"
								className="form-control"
								placeholder={address[selectedLanguage]}
								name="address"
								onChange={formik.handleChange}
								value={formik.values?.address}
							/>
						</div>
						<div className="col-md-6">
							<label>{photo[selectedLanguage]}</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div>
					</div>
					<div>
						<hr />
						<h3>{effectFromDisasterQn[selectedLanguage]}</h3>
						{getFormRowList()}
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">
									{riskArea[selectedLanguage]}
								</label>
								<input
									type="text"
									className="form-control"
									placeholder={riskArea[selectedLanguage]}
									name="riskArea"
									onChange={formik.handleChange}
									value={formik.values?.riskArea}
								/>
							</div>
							<div className="col-md-6">
								<label className="form-control-label">
									{wardQuestion[selectedLanguage]}
								</label>
								<select
									className="form-control"
									id="ward"
									name="ward"
									onChange={formik.handleChange}
									value={formik.values?.ward}
								>
									{ward.map((woda) => (
										<option value={woda.value}>
											{woda[selectedLanguage]}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">
									{address[selectedLanguage]}
								</label>
								<input
									type="text"
									className="form-control"
									placeholder={address[selectedLanguage]}
									name="address"
									onChange={formik.handleChange}
									value={formik.values?.address}
								/>
							</div>
							<div className="col-md-6">
								<label>{photo[selectedLanguage]}</label>
								<ImageUploader fileListContent={props.fileListContent} />
							</div>
						</div>
						<div>
							<hr />
							<h3>{effectFromDisasterQn[selectedLanguage]}</h3>
							{getFormRowList()}
							<div className="form-group row">
								<div className="col-lg-4">
									<a
										href="javascript:;"
										onClick={addFormRow}
										className="btn btn-sm font-weight-bolder btn-light-primary"
									>
										<i className="la la-plus"></i>
										{add[selectedLanguage]}
									</a>
								</div>
							</div>
						</div>

						<div className="card-footer text-right">
							<FormFooterButtons />
						</div>
					</div>
				</Card.Body>
			</Card>
		</form>
	);
}
export default NaturalDisaster;
