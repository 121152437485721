import React, { useEffect, useMemo } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap';
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../_metronic/_assets/js/components/util.js";
import SchoolGraph from './overview-sections/SchoolGraph';
import CollegeGraph from './overview-sections/CollegeGraph';
import PravidikGraph from './overview-sections/PravidikGraph';
import { Categories } from '../data/Categories';
const OrganizationDetail = () => {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily")
        };
    }, [uiService]);

    useEffect(() => {
        const element1 = document.getElementById("school_chart");
        renderChart(element1, layoutProps)
    }, [layoutProps]);
    return (
        <Card>
            <Card.Header>
                <h2>
                    शिक्षा सम्बन्धी बिबरण
                </h2>
            </Card.Header>
            <Card.Body>

                <Accordion defaultActiveKey="0">
                    <SchoolGraph />
                    <CollegeGraph />
                    <PravidikGraph />
                </Accordion>
            </Card.Body>
        </Card>
    )
}
export default OrganizationDetail;

function renderChart(element, layoutProps) {

    const height = parseInt(KTUtil.css(element, 'height'));
    const options = getChartOptions(layoutProps, height);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
        chart.destroy();
    };
}
function getChartOptions(layoutProps, height) {
    const options = {
        series: [{
            name: "सरकारी",
            data: [1750, 1750, 1750, 1750, 1750, 1750],
        }, {
            name: "निजि",
            data: [2500, 2500, 2500, 2500, 2500, 2500]
        }, {
            name: "समुदाईक",
            data: [2125, 2125, 2125, 2125, 2125, 2125]
        }],
        chart: {
            type: 'bar',
            height: 430
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'bottom',
                },
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: ["बाल विकास कक्ष्या", "पुर्व प्राथमिक", "आधारभूत तह", "माध्यमिक तह", "प्रबÎधिक एस. एल. सी.(Overseer)", "स्नातक तह"],
        },
        colors: ['#4c80ff', '#ff085b', '#ffac00'],
        legend: {
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            position: "top",
            horizontalAlign: "right"
        }
    };
    return options;
}