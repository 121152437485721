import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { houseFacility } from '../../data/houseSurvey';
import { selectedLanguage } from '../../data/institute';

const HouseFacility = (props) => {
    const houseFacilityData = props.houseFacility;
    return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title>
                        <h3>घरको सुविधाहरु</h3>
                    </Card.Title>
                </Card.Body>

                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>किसिम</th>
                            <th>संख्या</th>
                            {/* <th>सम्पर्क नम्बर</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            houseFacilityData.map((facility, i) => (
                                <>

                                    <tr
                                    >
                                        <td>{facility.title}</td>
                                        {/* <td>{houseFacility.find(i => i.english === facility.title) !== undefined ? houseFacility.find(i => i.english === facility.title)[selectedLanguage] : ""}</td> */}
                                        <td>{facility.count}</td>
                                    </tr>
                                </>
                            ))
                        }
                    </tbody>
                </Table>
            </Card>
        </>
    );
}

export default HouseFacility;