export const agricultureList = [
	{
		nepali: "कृषि सम्बन्धि",
		english: "Agriculture Detail",
		value: "agricultureReport",
		baseApiKey: "house-detail",
		child: [
			// {
			// 	nepali: "कृषि कार्यका लागि सिचाई अनुसार विवरण",
			// 	english: "Irrigation",
			// 	value: "irrigation",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ?",
				english: "Agriculture Yes No",
				value: "agricultureyesno",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "उत्पादित अनाज आफै उपभोग गर्नुहुन्छ कि विक्रि पनि",
				english: "Agriculture Yes No",
				value: "agricultureProductionOrNot",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: " कृषि बालीमा मलको प्रयोग गर्नु भएको छ / छैन",
			// 	english: "Total land area",
			// 	value: "isChemicalUsed",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "कृषि वालीमा मल प्रयोग गरेको आधारमा विवरण",
				english: "Agriculture Chemical Used",
				value: "chemicalUsed",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "परिवारको उत्पादनले खाना पुग्ने आधारमा घरधुरी विवरण",
				english: "Food For Month",
				value: "foodformonth",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "वार्षिक अन्न वालि उत्पादन विवरण",
				english: "Annual Crops Production Detail",
				value: "crops",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक नगदेवाली उत्पादन विवरण",
				english: "Annual Cash Crops Production Detail",
				value: "cashCrops",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक तरकारी बाली उत्पादन विवरण",
				english: "Annual Vegetable Production Detail",
				value: "vegetable",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक दलहनको उत्पादन विवरण",
				english: "Annual Daal Production Detail",
				value: "daal",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक तेलहनको उत्पादन विवरण",
				english: "Annual Oilseed Production Detail",
				value: "oilSeed",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक फलफुल खेतीको उत्पादन विवरण",
				english: "Annual Fruits Production Detail",
				value: "fruits",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वार्षिक फुलखेतीको उत्पादन विवरण",
				english: "Annual Flower Production Detail",
				value: "flower",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वाली अनुसार विवरण",
				english: "",
				value: "overallBali",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वडा अनुसार वालीनाली क्षेत्रफल तथा उत्पादन -१",
				english: "",
				value: "wardWiseBali",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वडा अनुसार वालीनाली क्षेत्रफल तथा उत्पादन -२",
				english: "",
				value: "wardWiseBaliTwo",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "वडा अनुसार वालीनाली क्षेत्रफल तथा उत्पादन -३",
				english: "",
				value: "wardWiseBaliThree",
				title: "agri",
				table: "agriculture",
			},
		],
	},
	{
		nepali: "पशुपन्छी सम्बन्धि",
		english: "Related to livestock",
		value: "livestockReport",
		baseApiKey: "house-detail",
		child: [
			{
				nepali: "पशुपन्छी पाल्ने/नपाल्ने आधारमा",
				english: "On the basis of raising / not raising livestock",
				value: "livestockRaising",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "घरेलु तथा व्यावसायिक पशु पन्छी पालन गर्ने घरधुरी विवरण",
				english:
					"Household details for raising domestic and commercial livestock",
				value: "livestockPurpose",
				title: "house",
				table: "wardWise",
			},
			{
				nepali: "पशुपन्छीको विवरण",
				english: "Description of livestock",
				value: "animal",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "पशु सम्बन्धी विवरण - १",
				english: "Animal details - 1",
				value: "bigAnimal",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "पशु सम्बन्धी विवरण - 2",
				english: "Animal details - 2",
				value: "smallAnimal",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "कुखुरा पालन सम्बन्धी विवरण",
				english: "",
				value: "birdMeat",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "हाँस परेवा पालन विवरण",
				english: "",
				value: "birdEgg",
				title: "agri",
				table: "agriculture",
			},
			{
				nepali: "माछा मौरी रेशम पालन गर्ने घरधुरी विवरण",
				english: "",
				value: "fishBeeHouseholdOrNot",
				title: "house",
				table: "wardWise",
			},
			// {
			// 	nepali: "पोखरि ,घार, कोयोको संख्या",
			// 	english: "",
			// 	value: "fishBeeSilkCount",
			// 	title: "house",
			// 	table: "wardWise",
			// },
			{
				nepali: "माछा, मौरी र रेशम पालनको विवरण",
				english: "",
				value: "fishBeeSilkDetail",
				title: "house",
				table: "agriculture",
			},
		],
	},
];
