import { useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import React from 'react'


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #3699FF;
`;

function Loader(props) {
  let [color, setColor] = useState("#ffffff");

  return (
    <div className="loader-container">
      <ClipLoader color={color} loading={props.loading} css={override} size={150} />
    </div>
  );
}

export default Loader;