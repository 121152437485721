import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

export function FileDropZone(props) {
    const onDrop = useCallback(acceptedFiles => {
        props.filesAdded(acceptedFiles)
        // Do something with the files
    }, [])
    const onDropRejected = useCallback((test) => {
        console.log("Drop reject")
        // Do something with the files
    }, [])
    const accept = 'image/*'
    const maxFiles = props.multiple ? 100 : 1;
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxFiles, onDropRejected })

    return (
        <div {...getRootProps()} className="file-dropzone">
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <div className="d-flex flex-column align-items-center">
                        <h2>
                            Drop files here
                        </h2>
                        <h2>

                            or
                        </h2>
                        <h2>
                            click to upload
                        </h2>
                    </div>
            }
        </div>
    )
}