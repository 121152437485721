import React from 'react'
import { Formik, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import { ward } from '../data/institute';

const Worker = (props) => (

    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                ward: "",
                uniqueIdentifier: "",
                industryName: "",
                workerCount: "",
                address: "",
                male: "",
                female: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.pondAddress) {
                //     errors.pondAddress = 'Required';
                // }
                // if (!values.address) {
                //     errors.address = 'Required';
                // }
                // if (!values.area) {
                //     errors.area = 'Required';
                // }
                // if (!values.farmType) {
                //     errors.farmType = 'Required';
                // }
                // if (!values.unit) {
                //     errors.unit = 'Required';
                // }
                // if (!values.rate) {
                //     errors.rate = 'Required';
                // }
                // if (!values.animal) {
                //     errors.animal = 'Required';
                // }
                // if (!values.presidentContact) {
                //     errors.presidentContact = 'Required';
                // }
                // if (!values.presidentName) {
                //     errors.presidentName = 'Required';
                // }
                // if (!values.total) {
                //     errors.total = 'Required';
                // }
                // if (!values.fetotal) {
                //     errors.fetotal = 'Required';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>
                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर </label>
                                        <select
                                            className="form-control"
                                            id="ward"
                                            name="ward"
                                            onChange={handleChange}
                                            value={values.ward}
                                        >
                                            {ward.map((woda) => (
                                                <option value={woda.value}>{woda.nepali}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >उधोगको नाम</label>
                                        <input type="number" className="form-control" placeholder="उधोगको नाम" name="traditionalCount" onChange={handleChange} value={values.traditionalCount} />
                                        <ErrorMessage className="invalid-feedback" name="traditionalCount" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="number" className="form-control" placeholder="ठेगाना" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कार्यरहत मजदुर संख्या</label>
                                        <input type="number" className="form-control" placeholder="कार्यरहत मजदुर संख्या" name="workerCount" onChange={handleChange} value={values.workerCount} />
                                        <ErrorMessage className="invalid-feedback" name="workerCount" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पुरुष संख्या</label>
                                        <input type="number" className="form-control" placeholder="पुरुष संख्या" name="male" onChange={handleChange} value={values.male} />
                                        <ErrorMessage className="invalid-feedback" name="male" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >महिला संख्या</label>
                                        <input type="number" className="form-control" placeholder="महिला संख्या" name="female" onChange={handleChange} value={values.female} />
                                        <ErrorMessage className="invalid-feedback" name="female" component="div" />
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </>
            )}
        </Formik>
    </div>

)

export default Worker;