

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const Temple = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                ward: "",
                uniqueIdentifier: "",
                address: "",
                establish: "",
                templeGroup: "",
                forestLegend: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.address) {
                //     errors.address = 'Required';
                // }
                // if (!values.clubType) {
                //     errors.clubType = 'Required';
                // }
                // if (!values.establishedAt) {
                //     errors.establishedAt = 'Required';
                // }
                // if (!values.memberCount) {
                //     errors.memberCount = 'Required';
                // }
                // if (!values.area) {
                //     errors.area = 'Required';
                // }
                // if (!values.clubUpdate) {
                //     errors.clubUpdate = 'Required';
                // }
                // if (!values.clubMainWork) {
                //     errors.clubMainWork = 'Required';
                // }
                // if (!values.presidentName) {
                //     errors.presidentName = 'Required';
                // }
                // if (!values.presidentContact) {
                //     errors.presidentContact = 'Required';
                // }
                // else 
                if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>

                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="text" className="form-control" placeholder="ठेगाना" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >निर्माण भएको बर्ष</label>
                                        <input type="date" className="form-control" id="example-date-input" placeholder="निर्माण भएको बर्ष" name="establish" onChange={handleChange} value={values.establish} />
                                        <ErrorMessage className="invalid-feedback" name="establish" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >सञ्चालनको लागि गुठी</label>
                                        <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input type="radio" checked="checked" name="templeGroup" />
                                                <span></span>
                                                छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input type="radio" name="templeGroup" />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <ErrorMessage className="invalid-feedback" name="templeGroup" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >मठ/मन्दिर सम्बन्धि ऐतिहाँसिक किम्वदन्ती</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="forestLegend" onChange={handleChange} value={values.forestLegend} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="forestLegend" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                </div>
                                <hr />
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </>

                // <Form>
                //     <Field type="area" name="area" />
                //     <ErrorMessage className="invalid-feedback" name="area" component="div" />
                //     <Field type="password" name="password" />
                //     <ErrorMessage className="invalid-feedback" name="password" component="div" />
                //     <button type="submit" disabled={isSubmitting}>
                //         Submit
                //     </button>
                // </Form>
            )}
        </Formik>
    </div>

);

export default Temple;