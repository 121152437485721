import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import {
	add,
	wardQuestion,
	photo,
	communityOrg,
	forestOrg,
	farmerOrg,
	tolSudhar,
	governmentOrg,
	club,
	traditionalOrg,
	amaOrg,
	others,
	citizenship,
	udhyami,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";
import { Card } from "react-bootstrap";
import FormFooterButtons from '../components/FormFooterButtons';

const CommunityOrg = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : {
				ward: "",
				communityOrg: "",
				farmerOrg: "",
				forestOrg: "",
				governmentOrg: "",
				club: "",
				traditionalOrg: "",
				amaOrg: "",
				citizenship: "",
				tolSudhar: "",
				udhyami: "",
				others: "",
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.communityOrg) {
				//     errors.communityOrg = 'Required';
				// }
				// if (!values.farmerOrg) {
				//     errors.farmerOrg = 'Required';
				// }
				// if (!values.forestOrg) {
				//     errors.forestOrg = 'Required';
				// }
				// if (!values.governmentOrg) {
				//     errors.governmentOrg = 'Required';
				// }
				// if (!values.club) {
				//     errors.club = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.traditionalOrg) {
				//     errors.traditionalOrg = 'Required';
				// }
				// if (!values.amaOrg) {
				//     errors.amaOrg = 'Required';
				// }
				// if (!values.citizenship) {
				//     errors.citizenship = 'Required';
				// }
				// if (!values.tolSudhar) {
				//     errors.tolSudhar = 'Required';
				// }
				// if (!values.udhyami) {
				//     errors.udhyami = 'Required';
				// }
				// if (!values.others) {
				//     errors.others = 'Required';
				// }
				// else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>

						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>{woda[selectedLanguage]}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{farmerOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={farmerOrg[selectedLanguage]}
											name="farmerOrg"
											onChange={handleChange}
											value={values.farmerOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="farmerOrg"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{communityOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={communityOrg[selectedLanguage]}
											name="communityOrg"
											onChange={handleChange}
											value={values.communityOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="communityOrg"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{forestOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={forestOrg[selectedLanguage]}
											name="forestOrg"
											onChange={handleChange}
											value={values.forestOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="forestOrg"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{governmentOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={governmentOrg[selectedLanguage]}
											name="governmentOrg"
											onChange={handleChange}
											value={values.governmentOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="governmentOrg"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{club[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={club[selectedLanguage]}
											name="club"
											onChange={handleChange}
											value={values.club}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="club"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{traditionalOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={traditionalOrg[selectedLanguage]}
											name="governmentOrg"
											onChange={handleChange}
											value={values.governmentOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="governmentOrg"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{amaOrg[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={amaOrg[selectedLanguage]}
											name="amaOrg"
											onChange={handleChange}
											value={values.amaOrg}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="amaOrg"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{citizenship[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={citizenship[selectedLanguage]}
											name="citizenship"
											onChange={handleChange}
											value={values.citizenship}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="citizenship"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{tolSudhar[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={tolSudhar[selectedLanguage]}
											name="tolSudhar"
											onChange={handleChange}
											value={values.tolSudhar}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="tolSudhar"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{udhyami[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="उधमी समूह"
											name="udhyami"
											onChange={handleChange}
											value={values.udhyami}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="udhyami"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{others[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={others[selectedLanguage]}
											name="others"
											onChange={handleChange}
											value={values.others}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="others"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default CommunityOrg;
