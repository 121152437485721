import React from 'react'
import { beeProduction, beeTitle, buffalloInfo, buffalloMilkProduction, buffalloMilkProductionSale, buffalloMilkSale, buffalloNumber, cowInfo, cowMilkProduction, cowMilkProductionSale, cowMilkSale, cowNumber, duckEggSale, duckInfo, duckMeatIncome, duckMeatProduction, duckNumber, fishProduction, fishTitle, gadhaInfo, gadhaMeatIncome, gadhaNumber, gadhaProduction, henEggSale, henInfo, henMeatIncome, henMeatProduction, henNumber, hybridChickenInfo, hybridEggSale, hybridMeatProduction, hybridNumber, khasiInfo, khasiMeatIncome, khasiMeatProduction, khasiNumber, oxInfo, oxMeatIncome, oxNumber, oxProduction, pigInfo, pigMeatIncome, pigMeatProduction, pigNumber, pondArea, rangaInfo, rangaMeatIncome, rangaMeatProduction, rangaNumber, reshamGhaar, reshamProduction, reshamTitle, sheepInfo, sheepMeatIncome, sheepMeatProduction, sheepNumber, totalGhaar, totalPond, yakInfo, yakMeatIncome, yakMilkProduction, yakNumber, yakProduction } from '../data/houseSurvey';
import { selectedLanguage } from '../data/institute';

export const CowSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{cowInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{cowNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowNumber`} onChange={formik.handleChange} value={agriLivestock.cowNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{cowMilkProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProduction`} onChange={formik.handleChange} value={agriLivestock.cowMilkProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{cowMilkProductionSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProductionSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{cowMilkSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkSale} />
                </div>
            </div>
        </>
    );
}

export const BuffalloSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{buffalloInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{buffalloNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.buffalloNumber`} onChange={formik.handleChange} value={agriLivestock.buffalloNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{buffalloMilkProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.buffalloMilkProduction`} onChange={formik.handleChange} value={agriLivestock.buffalloMilkProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{buffalloMilkProductionSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProductionSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{buffalloMilkSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkSale} />
                </div>
            </div>
        </>
    );
}

export const GoatSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{khasiInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{khasiNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.khasiNumber`} onChange={formik.handleChange} value={agriLivestock.khasiNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{khasiMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.khasiMeatProduction`} onChange={formik.handleChange} value={agriLivestock.khasiMeatProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{khasiMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProductionSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
        </>
    );
}

export const RangaSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{rangaInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{rangaNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.rangaNumber`} onChange={formik.handleChange} value={agriLivestock.rangaNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{rangaMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.rangaMeatProduction`} onChange={formik.handleChange} value={agriLivestock.rangaMeatProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{rangaMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.rangaMeatIncome`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{rangaMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.rangaMeatIncome`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
        </>
    );
}

export const PigSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{pigInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{pigNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.pigNumber`} onChange={formik.handleChange} value={agriLivestock.pigNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{pigMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProduction`} onChange={formik.handleChange} value={agriLivestock.cowMilkProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{pigMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProductionSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
        </>
    );
}

export const SheepSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{sheepInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{sheepNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.sheepNumber`} onChange={formik.handleChange} value={agriLivestock.sheepNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{sheepMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProduction`} onChange={formik.handleChange} value={agriLivestock.cowMilkProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{sheepMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.cowMilkProductionSale`} onChange={formik.handleChange} value={agriLivestock.cowMilkProductionSale} />
                </div>
            </div>
        </>
    );
}

export const GaruSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{oxInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{oxNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.oxNumber`} onChange={formik.handleChange} value={agriLivestock.oxNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{oxProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.oxProduction`} onChange={formik.handleChange} value={agriLivestock.oxProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{oxMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.oxMeatIncome`} onChange={formik.handleChange} value={agriLivestock.oxMeatIncome} />
                </div>
            </div>
        </>
    );
}

export const GadhaSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{gadhaInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{gadhaNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.gadhaNumber`} onChange={formik.handleChange} value={agriLivestock.gadhaNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{gadhaProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.gadhaProduction`} onChange={formik.handleChange} value={agriLivestock.gadhaProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{gadhaMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.gadhaMeatIncome`} onChange={formik.handleChange} value={agriLivestock.gadhaMeatIncome} />
                </div>
            </div>
        </>
    );
}

export const YakSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{yakInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{yakNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.yakNumber`} onChange={formik.handleChange} value={agriLivestock.yakNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{yakMilkProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.yakMilkProduction`} onChange={formik.handleChange} value={agriLivestock.yakMilkProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{yakProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.yakProduction`} onChange={formik.handleChange} value={agriLivestock.yakProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{yakMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.yakMeatIncome`} onChange={formik.handleChange} value={agriLivestock.yakMeatIncome} />
                </div>
            </div>
        </>
    );
}


export const ChickenSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{henInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{henNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.henNumber`} onChange={formik.handleChange} value={agriLivestock.henNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{henMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.henMeatProduction`} onChange={formik.handleChange} value={agriLivestock.henMeatProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{henMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.henMeatIncome`} onChange={formik.handleChange} value={agriLivestock.henMeatIncome} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{henEggSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.henEggSale`} onChange={formik.handleChange} value={agriLivestock.henEggSale} />
                </div>
            </div>
        </>
    );
}


export const HybridChickenSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{hybridChickenInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{hybridNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.hybridNumber`} onChange={formik.handleChange} value={agriLivestock.hybridNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{hybridMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.hybridMeatProduction`} onChange={formik.handleChange} value={agriLivestock.hybridMeatProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{hybridEggSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.hybridEggSale`} onChange={formik.handleChange} value={agriLivestock.hybridEggSale} />
                </div>
            </div>
        </>
    );
}


export const DuckSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{duckInfo[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{duckNumber[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.duckNumber`} onChange={formik.handleChange} value={agriLivestock.duckNumber} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{duckMeatProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.duckMeatProduction`} onChange={formik.handleChange} value={agriLivestock.duckMeatProduction} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{duckMeatIncome[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.duckMeatIncome`} onChange={formik.handleChange} value={agriLivestock.duckMeatIncome} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{duckEggSale[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.duckEggSale`} onChange={formik.handleChange} value={agriLivestock.duckEggSale} />
                </div>
            </div>
        </>
    );
}
export const FishSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{fishTitle[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{totalPond[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.totalPond`} onChange={formik.handleChange} value={agriLivestock.totalPond} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{pondArea[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.pondArea`} onChange={formik.handleChange} value={agriLivestock.pondArea} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{fishProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.fishProduction`} onChange={formik.handleChange} value={agriLivestock.fishProduction} />
                </div>
            </div>
        </>
    );
}
export const BeeSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{beeTitle[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{totalGhaar[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.totalGhaar`} onChange={formik.handleChange} value={agriLivestock.totalGhaar} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{beeProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.beeProduction`} onChange={formik.handleChange} value={agriLivestock.beeProduction} />
                </div>
            </div>
        </>
    );
}
export const SilkSection = (props) => {
    const { formik, agriLivestock } = props;
    return (
        <>
            <div className="col-12">
                <h5 class="mb-10 font-weight-bold text-dark">{reshamTitle[selectedLanguage]}</h5>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{reshamGhaar[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.reshamGhaar`} onChange={formik.handleChange} value={agriLivestock.reshamGhaar} />
                </div>
            </div>
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{reshamProduction[selectedLanguage]}</label>
                    <input type="number" class="form-control" name={`agriLivestock.reshamProduction`} onChange={formik.handleChange} value={agriLivestock.reshamProduction} />
                </div>
            </div>
        </>
    );
}
