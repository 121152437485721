import axiosHandler from "../../redux/axiosHandler";
const ADVANCED_SEARCH_FAMILY = '/advancedsearch/newAdvanceSearchFamily'
const ADVANCED_SEARCH_HOUSE = '/advancedsearch/newAdvanceSearchHouse'
const SEARCH_HOUSE = '/searchhouse/housesearch/'
const SEARCH_NAME = '/searchBy/name/'
const SEARCH_cONTACT = '/searchBy/phone/'
const GET_ALL_CONTACTS = '/advancedsearch/aboardCustomResult'

export function getAdvancedSearchResultsFamily(searchBody) {
    return axiosHandler.post(`${ADVANCED_SEARCH_FAMILY}`, searchBody);
}
export function getAdvancedSearchResultsHouse(searchBody) {
    return axiosHandler.post(`${ADVANCED_SEARCH_HOUSE}`, searchBody);
}
export function getResultByHouse(houseNumer) {
    return axiosHandler.get(`${SEARCH_HOUSE}${houseNumer}`);
}
export function getResultByPhone(phoneNumber, ward) {
    return axiosHandler.get(`${SEARCH_cONTACT}${phoneNumber}/${ward}`);
}
export function getResultByName(name, ward) {
    return axiosHandler.get(`${SEARCH_NAME}${name}/${ward}`);
}
export function getContactNumbersByHouse(houseIds) {
    return axiosHandler.post(`${GET_ALL_CONTACTS}`, houseIds);
}