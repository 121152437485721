import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	startAddress,
	endAddress,
	busStopCount,
	remarks,
	organization,
	ward,
	cancel,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	startAddress: "",
	endAddress: "",
	busStopCount: "",
	organization: "",
	remarks: "",
	uniqueIdentifier: GetUUID(),
};

const BusRoute = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{startAddress[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={startAddress[selectedLanguage]}
											name="startAddress"
											onChange={handleChange}
											value={values.startAddress}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="startAddress"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{endAddress[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={endAddress[selectedLanguage]}
											name="endAddress"
											onChange={handleChange}
											value={values.endAddress}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="endAddress"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{organization[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={organization[selectedLanguage]}
											name="organization"
											onChange={handleChange}
											value={values.organization}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="organization"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{busStopCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={busStopCount[selectedLanguage]}
											name="busStopCount"
											onChange={handleChange}
											value={values.busStopCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="busStopCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{remarks[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={remarks[selectedLanguage]}
											name="remarks"
											onChange={handleChange}
											value={values.remarks}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="remarks"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default BusRoute;
