import { useLocation } from "react-router";
import React from 'react'
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { AsideMenuSubItem } from "./AsideMenuSubItem";

export function AsideMenuItem({ menuItem }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>

            <li
                className={`menu-item ${getMenuItemActive(menuItem.link, menuItem.subMenuItems.length > 0)}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
                {!menuItem.link.includes("javascript") ? (
                    <>
                        <NavLink className="menu-link menu-toggle" to={menuItem.link}>
                            {/* <img src={toAbsoluteUrl("/media/svg/icons/Cooking/Shovel.svg")} /> */}

                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl(menuItem.icon)} />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                            {menuItem.subMenuItems.length > 0 && (

                                <i className="menu-arrow" />
                            )}
                        </NavLink>

                        {menuItem.subMenuItems.length > 0 && (
                            <>
                                {menuItem.subMenuItems.map(function (subMenuItem, index) {
                                    return <AsideMenuSubItem subMenuItem={subMenuItem} key={index} />;
                                })}
                            </>
                        )}
                    </>) : (
                    <>
                        <div className="menu-link menu-toggle" onClick={menuItem.action}>
                            {/* <img src={toAbsoluteUrl("/media/svg/icons/Cooking/Shovel.svg")} /> */}

                            <span className="svg-icon menu-icon">
                                <SVG src={toAbsoluteUrl(menuItem.icon)} />
                            </span>
                            <span className="menu-text">{menuItem.title}</span>
                            {menuItem.subMenuItems.length > 0 && (

                                <i className="menu-arrow" />
                            )}
                        </div>

                        {menuItem.subMenuItems.length > 0 && (
                            <>
                                {menuItem.subMenuItems.map(function (subMenuItem, index) {
                                    return <AsideMenuSubItem subMenuItem={subMenuItem} key={index} />;
                                })}
                            </>
                        )}
                    </>
                )
                }

            </li>
        </>
    )

}