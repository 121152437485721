import Table from 'rc-table';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom"
import Loader from './Loader';
import { allToleName } from '../data/houseSurvey';

const familyHeaders =
    [
        {
            title: "S.N",
            key: "serialNo",
            dataIndex: "serialNo",
            width: null,
        },
        {
            title: "वडा नं",
            key: "wardNumber",
            dataIndex: "wardNumber",
            width: null,
        },
        {
            title: "नाम",
            key: "fullName",
            dataIndex: "fullName",
            width: null,
        },
        {
            title: "लिङ्ग",
            key: "gender",
            dataIndex: "gender",
            width: null,
        },
        {
            title: "धर्म",
            key: "religion",
            dataIndex: "religion",
            width: null,
        },
        {
            title: "घर नं",
            key: "",
            dataIndex: "",
            width: null, render: function (val) {
                return (
                    <NavLink className="menu-link menu-toggle" to={`/home-survey/detail/${val.houseId}`}>
                        {val.houseNumber}
                    </NavLink>
                )
            }
        },
        {
            title: "टोलको नाम ",
            key: "toleName",
            dataIndex: "toleName",
            width: null,
        },
        {
            title: "फोन नम्बर",
            key: "contactNumber",
            dataIndex: "contactNumber",
            width: null,
        },
    ]

const houseHeaders =
    [
        {
            title: "S.N",
            key: "serialNo",
            dataIndex: "serialNo",
            width: null,
        },
        {
            title: "घर नं",
            key: "",
            dataIndex: "",
            width: null, render: function (val) {
                return (
                    <NavLink className="menu-link menu-toggle" to={`/home-survey/detail/${val.houseId}`}>
                        {val.houseNumber}
                    </NavLink>
                )
            }
        },
        {
            title: "टोलको नाम ",
            key: "toleName",
            dataIndex: "toleName",
            width: null,
        },
        {
            title: "वडा नं",
            key: "wardNumber",
            dataIndex: "wardNumber",
            width: null,
        },
        {
            title: "उत्तरदाताको नाम",
            key: "answererName",
            dataIndex: "answererName",
            width: null,
        },
    ]
const BibaranDataModal = (props) => {
    const [tableData, setTableData] = useState([])
    const [loading, setloading] = useState([])
    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);
    const [isFamily, setIsFamily] = useState(false)
    useEffect(() => {
        if (props.tableData?.length) {
            setloading(true);
            if (props.tableData[0].house !== undefined) {
                const family = true
                setIsFamily(true)
                const dataForTable = props.tableData?.map((datum, i) => {
                    return {
                        serialNo: i + 1,
                        houseId: datum.house._id,
                        wardNumber: datum.house?.wardNumber,
                        fullName: datum.fullName,
                        gender: mapGender(datum.gender),
                        religion: mapReligion(datum.religion),
                        contactNumber: datum.contactNumber,
                        houseNumber: datum.house?.houseNumber,
                        toleName: getToleName(datum.house?.toleName),
                    }
                })
                setTableData(dataForTable)
            }
            else {
                const dataForTable = props.tableData?.map((datum, i) => {
                    return {
                        serialNo: i + 1,
                        houseId: datum._id,
                        houseNumber: datum.houseNumber,
                        toleName: getToleName(datum.toleName),
                        wardNumber: datum.wardNumber,
                        answererName: datum.answererName,
                    }
                })
                setTableData(dataForTable)
                setIsFamily(false)
            }
        }
    }, [props.tableData])

    const mapReligion = (type) => {
        if (type === "hindu") return "हिन्दु";
        if (type === "buddhist") return "बौद्ध";
        if (type === "muslim") return "इस्लाम (मुस्लिम)";
        if (type === "christian") return "इसाई (क्रिश्चियन)";
        if (type === "kirat") return "किंरात";
        if (type === "other_religion") return "अन्य";
    };

    const mapGender = (gender) => (gender === "male" ? "पुरुष" : "महिला");
    const getToleName = (value) => {
        var tole = allToleName.filter((each) => each.value === value);

        return tole && tole[0] ? tole[0].nepali : "अन्य";
    };
    const getContent = () => {
        console.log(isFamily)
        if (props.tableData) {
            if (isFamily) {
                return <Table className="table table-bordered table-responsive" data={tableData} columns={familyHeaders} />
            }
            return <Table className="table table-bordered table-responsive" data={tableData} columns={houseHeaders} />
        }
        return (
            <Loader loading={true} />
        )
    }
    return (
        <>
            <Modal centered show={props.show} onHide={handleClose} scrollable={true} dialogClassName="data-detail-modal" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100">
                        <div className="d-flex justify-content-between">
                            <span>विवरण</span>
                            <span className="font-size-h6 text-primary">प्रतिफल: <span className="font-size-h6 text-dark-75">{tableData.length}</span></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        getContent()
                    }
                    {/* <Table /> */}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BibaranDataModal;