import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const College = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                ward: "",
                address: "",
                faculty: "",
                level: "",
                mainSubject: "",
                maleStudent: "",
                femaleStudent: "",
                maleTeacher: "",
                femaleTeacher: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.ward) {
                //     errors.ward = 'Required';
                // }
                // if (!values.infrastructure) {
                //     errors.infrastructure = 'Required';
                // }
                // if (!values.haatbazaarName) {
                //     errors.haatbazaarName = 'Required';
                // }
                // if (!values.haatrWard) {
                //     errors.haatrWard = 'Required';
                // }
                // if (!values.haatMarket) {
                //     errors.haatMarket = 'Required';
                // }
                // if (!values.haatInfra) {
                //     errors.haatInfra = 'Required';
                // }
                // if (!values.haatType) {
                //     errors.haatType = 'Required';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>
                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >
                                            कलेजको प्रकार</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>---------</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ईमेल</label>
                                        <input type="number" className="form-control" placeholder="ईमेल" name="email" onChange={handleChange} value={values.email} />
                                        <ErrorMessage className="invalid-feedback" name="email" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >फोन नम्बर</label>
                                        <input type="text" className="form-control" placeholder="phone" name="phone" onChange={handleChange} value={values.phone} />
                                        <ErrorMessage className="invalid-feedback" name="phone" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >मान्यताप्राप्त गिश्वगिद्यालय</label>
                                        <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input type="radio" checked="checked" name="isCberga" />
                                                <span></span>
                                                छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input type="radio" name="isCberga" />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <ErrorMessage className="invalid-feedback" name="templeGroup" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >निर्माण भएको बर्ष</label>
                                        <input type="date" className="form-control" id="example-date-input" placeholder="निर्माण भएको बर्ष" name="establishedAt" onChange={handleChange} value={values.establishedAt} />
                                        <ErrorMessage className="invalid-feedback" name="haatType" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कलेजको नाममा रहेको जग्िा क्षेत्रफल</label>
                                        <input type="date" className="form-control" id="example-date-input" placeholder="निर्माण भएको बर्ष" name="establishedAt" onChange={handleChange} value={values.establishedAt} />
                                        <ErrorMessage className="invalid-feedback" name="haatType" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कलेजलेप्रयोि िरेको जग्िाको लालपुजाषछ /छैन ?</label>
                                        <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input type="radio" checked="checked" name="isCberga" />
                                                <span></span>
                                                छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input type="radio" name="isCberga" />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <ErrorMessage className="invalid-feedback" name="templeGroup" component="div" />
                                    </div>
                                </div>
                                <h3>भौगतक अिस्था</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >
                                            विशिष्टता</label>
                                        <select className="form-control" id="level" name="level" onChange={handleChange} value={values.level}>
                                            <option disabled selected>---------</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="value" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कच्ची संख्या</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पक्की संख्या</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कच्ची कोठा संख्या</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पक्की कोठा संख्या</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >बेन्चको क्षमता</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <hr />
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >क्षेत्रफल</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कम्पाउण्ड िालको वकवसम</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >फोहर विसजगन गने उवचत ठाउँ</label>
                                        <input type="number" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <h3>कलेजलेभोि चलन िरेको जम्मा जग्िा क्षेत्रफल</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ऐलानी</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नम्बरी</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पद</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >स्थायी,करार</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >संकाय</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >फोन नम्बर</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ईमेल</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कैफयत</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>

                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >संकाय</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="sankaya" onChange={handleChange} value={values.sankaya} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="sankaya" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पढाई हुने तह</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="level" onChange={handleChange} value={values.level} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="levelsankaya" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पढाई हुने मुख्य विषयहरु</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="mainSubject" onChange={handleChange} value={values.mainSubject} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="mainSubjectsankaya" component="div" />
                                    </div>
                                </div>
                                <h3>विद्यार्थी संख्या</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >छात्र</label>
                                        <input type="text" className="form-control" placeholder="" name="maleStudent" onChange={handleChange} value={values.maleStudent} />
                                        <ErrorMessage className="invalid-feedback" name="maleStudent" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >छात्रा</label>
                                        <input type="text" className="form-control" placeholder="" name="femaleStudent" onChange={handleChange} value={values.femaleStudent} />
                                        <ErrorMessage className="invalid-feedback" name="femaleStudent" component="div" />
                                    </div>

                                </div>
                                <h3>शिक्षक शिक्षीका</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पुरुष</label>
                                        <input type="text" className="form-control" placeholder="" name="maleTeacher" onChange={handleChange} value={values.maleTeacher} />
                                        <ErrorMessage className="invalid-feedback" name="maleTeacher" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >महिला</label>
                                        <input type="text" className="form-control" placeholder="" name="femaleTeacher" onChange={handleChange} value={values.femaleTeacher} />
                                        <ErrorMessage className="invalid-feedback" name="femaleTeacher" component="div" />
                                    </div>

                                </div>
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>

                    </form>
                </>
            )}
        </Formik>
    </div >
);

export default College;