import React from "react";
import { Formik, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import {
	photo,
	address,
	name,
	rate,
	typeTitle,
	unit,
	wardQuestion,
	watertype,
	areaList,
	ward,
	selectedLanguage,
} from "../data/institute";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	address: "",
	rate: "",
	type: "",
	unit: "",
	uniqueIdentifier: GetUUID(),
};

const DisasterWater = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};

				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values?.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{typeTitle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="watertype"
											name="watertype"
											onChange={handleChange}
											value={values?.watertype}
										>
											{watertype.map((result) => (
												<option value={result.value}>
													{result[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="watertype"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<input
											className="form-control"
											placeholder={unit[selectedLanguage]}
											name="unit"
											onChange={handleChange}
											value={values.unit}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="unit"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{rate[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={rate[selectedLanguage]}
											name="rate"
											onChange={handleChange}
											value={values.rate}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="rate"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default DisasterWater;
