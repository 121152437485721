

import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const Hotel = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                ward: "",
                address: "",
                marketType: "",
                marketOpenDay: "",
                providedSerivce: "",
                mainSaleProduct: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.address) {
                //     errors.address = 'Required';
                // }
                // if (!values.clubType) {
                //     errors.clubType = 'Required';
                // }
                // if (!values.establishedAt) {
                //     errors.establishedAt = 'Required';
                // }
                // if (!values.memberCount) {
                //     errors.memberCount = 'Required';
                // }
                // if (!values.area) {
                //     errors.area = 'Required';
                // }
                // if (!values.clubUpdate) {
                //     errors.clubUpdate = 'Required';
                // }
                // if (!values.clubMainWork) {
                //     errors.clubMainWork = 'Required';
                // }
                // if (!values.presidentName) {
                //     errors.presidentName = 'Required';
                // }
                // if (!values.presidentContact) {
                //     errors.presidentContact = 'Required';
                // }
                // else 
                if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Invalid email address';
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>

                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >कृषि बजारस्थलको नाम</label>
                                        <input type="text" className="form-control" placeholder="कृषि बजारस्थलको नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >उपलब्ध प्रमुख पूर्वाधारहरु</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="providedService" onChange={handleChange} value={values.providedService} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="providedService" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >हाटबजार स्थलको नाम</label>
                                        <input type="text" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >हाटबजारको वडा नम्बर</label>
                                        <input type="number" className="form-control" id="example-date-input" placeholder="हाटबजारको वडा नम्बर" name="room" onChange={handleChange} value={values.room} />
                                        <ErrorMessage className="invalid-feedback" name="room" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >हाटबजार लाग्ने दिन वा वार</label>
                                        <input type="number" className="form-control" id="example-date-input" placeholder="हाटबजार लाग्ने दिन वा वार" name="marketOpenDay" onChange={handleChange} value={values.marketOpenDay} />
                                        <ErrorMessage className="invalid-feedback" name="marketOpenDay" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >उपलब्ध प्रमुख पूर्वाधारहरु</label>
                                        <input type="number" className="form-control" id="providedSevice" placeholder="उपलब्ध प्रमुख पूर्वाधारहरु" name="providedSevice" onChange={handleChange} value={values.providedSevice} />
                                        <ErrorMessage className="invalid-feedback" name="providedSevice" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >बजार</label>
                                        <input type="number" className="form-control" id="marketType" placeholder="बजार" name="marketType" onChange={handleChange} value={values.marketType} />
                                        <ErrorMessage className="invalid-feedback" name="marketType" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                </div>
                                <hr />
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </>

                // <Form>
                //     <Field type="area" name="area" />
                //     <ErrorMessage className="invalid-feedback" name="area" component="div" />
                //     <Field type="password" name="password" />
                //     <ErrorMessage className="invalid-feedback" name="password" component="div" />
                //     <button type="submit" disabled={isSubmitting}>
                //         Submit
                //     </button>
                // </Form>
            )}
        </Formik>
    </div>

);

export default Hotel;