import { Button, Modal, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik'
import { data } from 'jquery';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react'
import { useState } from 'react';
import * as Yup from "yup";
import { changePassword } from '../../modules/Auth/_redux/authCrud';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const formFields = [
    {
        label: "पुरानो पस्स्वोर्ड",
        value: "oldPassword",
    },
    {
        label: "नयाँ पस्स्वोर्ड",
        value: "newPassword",
    },
    {
        label: "नयाँ पासवर्ड पुष्टि",
        value: "confirmNewPassword",
    },
]
const fields = formFields.map((field) => {
    switch (field.value) {
        case "oldPassword":
            {
                const validation = Yup.string()
                    .required(`${field.label} अनिवार्य छ`)
                return {
                    ...field,
                    validation
                }
            }
        case "newPassword":
            {
                const validation = Yup.string()
                    .required(`${field.label} अनिवार्य छ`).test('passwords-match', 'पुरानो र नया पस्स्वोर्ड एकै नाराखिदिनुहोला ', function (value) {
                        return this.parent.oldPassword !== value
                    })
                return {
                    ...field,
                    validation
                }
            }
        case "confirmNewPassword":
            {
                const validation = Yup.string()
                    .required(`${field.label} अनिवार्य छ`).test('passwords-match', 'पस्स्वोर्ड म्याच भएन', function (value) {
                        return this.parent.newPassword === value
                    })
                return {
                    ...field,
                    validation
                }
            }

        default:
            break;
    }
})
const ChangePasswordSchema = Yup.object().shape(
    fields.reduce((o, key) => Object.assign(o, {
        [key.value]: key.validation
    }), {})
);
const defaultValues = fields.reduce((o, key) => Object.assign(o, {
    [key.value]: ''
}), {})
const initialViewPasswordState = {
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false
}
const ChangePassword = (props) => {
    const history = useHistory();
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [viewPassword, setViewPassword] = useState(initialViewPasswordState)
    const formik = useFormik({

        initialValues,
        validationSchema: ChangePasswordSchema,
        enableReinitialize: true,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            console.log(values)
            // setSubmitting(true)
            const changeBody = {
                newPassword: values.newPassword,
                oldPassword: values.oldPassword

            }
            changePassword(changeBody).then((res) => {
                setSubmitting(false)
                if (res.data.success) {
                    handleClose();
                    history.push("/logout");
                    toast.success("Password updated successfully, please relogin.")
                }
                else {
                    toast.error("An error occured")
                }
            }).catch((err) => {
                setSubmitting(false)
                toast.error("An error occured while saving data")
            });
        }
    })
    const toggleViewPassword = (field) => {
        setViewPassword({
            ...viewPassword,
            [field]: !viewPassword[field]
        })
    }
    const handleClose = () => {
        formik.resetForm();
        props.setShow(false)
    };
    const getContent = () => {
        const user = formik.values;
        return (
            <>
                <form onSubmit={formik.handleSubmit} >
                    <div className="row">
                        {
                            fields.map((field) => (
                                <div className="col-md-12 mb-4">
                                    <div className="form-group">
                                        <label>{field.label}</label>
                                        <div class="input-group">
                                            <input type={viewPassword[field.value] ? "text" : "password"} name={`${field.value}`} onChange={formik.handleChange} onBlur={formik.handleBlur} class="form-control" placeholder="" aria-describedby="basic-addon2" />
                                            <div class="input-group-append">
                                                <span class="input-group-text" style={{ cursor: 'pointer' }} onClick={() => toggleViewPassword(field.value)}>
                                                    {
                                                        viewPassword[field.value] ? (
                                                            <i className="far fa-eye"></i>
                                                        ) : (
                                                            <i className="far fa-eye-slash"></i>
                                                        )
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        {formik.touched[field.value] && formik.errors[field.value] ? (
                                            <div class="invalid-feedback">{formik.errors[field.value]}</div>
                                        ) : null}
                                    </div>
                                </div>
                            ))
                        }
                        <div className="col-12 d-flex justify-content-end">
                            <Button variant="light-primary" className="mr-3" type="submit" disabled={formik.isSubmitting}>
                                {formik.isSubmitting ? (
                                    <Spinner
                                        animation="grow"
                                        variant="primary"
                                        size="sm"
                                        className="mr-2"
                                    />
                                ) : null}सच्यानुहोस</Button>
                            <Button variant="light-danger" onClick={handleClose}>रद्द गर्नुहोस</Button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
    return (
        <>
            <Modal centered show={props.show} onHide={handleClose} scrollable={true} dialogClassName="data-detail-modal" size="md" style={{ zIndex: "15000" }}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>पासवर्ड परिवर्तन</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        getContent()
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ChangePassword
