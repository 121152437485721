import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Table from 'rc-table';
import { answererRelation, autoSuggetionString, genderList, livingTypeList, tableTitles } from '../data/advanceSearch';
import { selectedLanguage } from '../data/institute';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="First Page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Next Page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Last Page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, age, gender, houseNum, wardNum) {
    return { name, age, gender, houseNum, wardNum };
}


const useStyles2 = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}));

export function CustomPaginationActionsTable(props) {
    console.log(props)
    const classes = useStyles2();
    // const [page, setPage] = React.useState(0);
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    useEffect(() => {
        const columns = props.columnData.map((datum) => {
            const column = tableTitles.find(i => i.value == datum);
            console.log(datum)
            if (datum === "houseNumber") {
                return {
                    title: "घर न.",
                    key: "",
                    dataIndex: "",
                    width: 100, render: function (val) {
                        if (val.house) {
                            return (
                                <NavLink className="menu-link menu-toggle" to={`/home-survey/detail/${val.house}`}>
                                    {val.houseNumber}
                                </NavLink>
                            )
                        }
                        return (
                            <NavLink className="menu-link menu-toggle" to={`/home-survey/detail/${val._id}`}>
                                {val.houseNumber}
                            </NavLink>
                        )
                    }
                };
            }
            return {
                title: column ? column[selectedLanguage] : "",
                dataIndex: datum,
                key: datum,
                width: 100,
            }
        })
        console.log(columns)
        setColumns(columns)
        // console.log("headers", props.columnData);
        console.log("headers", props.columnData.includes("livingType"));
        if (props.columnData.includes("answererRelation") || props.columnData.includes("gender") || props.columnData.includes("livingType")) {
            const data = props.tableData.map((datum) => {
                return {
                    ...datum,
                    answererRelation: answererRelation.find(i => i.value === datum.answererRelation) ? answererRelation.find(i => i.value === datum.answererRelation)[selectedLanguage] : "",
                    gender: genderList.find(i => i.value === datum.gender) ? genderList.find(i => i.value === datum.gender)[selectedLanguage] : "",
                    livingType: livingTypeList.find(i => i.value === datum.livingType) ? livingTypeList.find(i => i.value === datum.livingType)[selectedLanguage] : ""
                }
            })
            setData(data)
        }
    }, [props.tableData])
    function handleChangePage(event, newPage) {
        console.log(newPage)
        props.handleChangePage(newPage)
        props.setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table columns={columns} data={data} className="table table-bordered table-responsive" />
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            colSpan={3}
                            count={props.total}
                            rowsPerPage={rowsPerPage}
                            page={props.page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'Rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </div>
        </Paper>
    );
}