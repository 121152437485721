import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import {
	add,
	wardQuestion,
	photo,
	disasterTitle,
	municipality,
	municipalityTitle,
	organizationName,
	countTitle,
	availableResource,
	disasterEffectQn,
	organizations,
	physicalTitle,
	name,
	disasterQn,
	cancel,
	ward,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
// import { KTFormRepeater } from '../data/helpers/Repeater_form.js'
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	data: {
		ward: "",
		municipality: "",
		organization: [
			{ organizationName: "", availableresource: "", resourceAmount: 0 },
		],
		uniqueIdentifier: GetUUID(),
	}
};

const PhysicalResource = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						organization: props.data.organization?.length ? props.data.organization : [
							{ organizationName: "", availableresource: "", resourceAmount: 0 },
						],
					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const addFormRow = () => {
		const prevOrganization = formik.values.organization;
		prevOrganization.push({ organizationName: "", availableresource: "", resourceAmount: 0 });
		formik.setValues({
			...formik.values,
			organization: prevOrganization,
		})
	};
	const deleteFormRow = (index) => {
		const prevOrganization = formik.values.organization;
		prevOrganization.splice(index, 1);
		formik.setValues({
			...formik.values,
			organization: prevOrganization,
		})
	};
	const getFormRowList = () => {
		return formik.values?.organization.map((row, i) => (
			<div className="form-group row" key={i}>
				<div className="col-lg-10">
					<div className="form-group row align-items-center">
						<div className="col-md-3">
							<label>{organizationName[selectedLanguage]}</label>
							<input
								type="text"
								className="form-control"
								name={`organization[${i}].organzationName`}
								onChange={formik.handleChange}
								value={formik.values?.organization[i].organizationName}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{availableResource[selectedLanguage]}</label>
							<select
								className="form-control"
								id="availableresource"
								name={`organization[${i}].availableresource`}
								onChange={formik.handleChange}
								value={formik.values?.organization[i].availableresource}
							>
								{organizations.map((level) => (
									<option value={level.value}>{level[selectedLanguage]}</option>
								))}
							</select>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<label>{countTitle[selectedLanguage]}</label>
							<input
								type="number"
								className="form-control"
								name={`organization[${i}].resourceAmount`}
								onChange={formik.handleChange}
								value={formik.values?.organization[i].resourceAmount}
							/>
							<div className="d-md-none mb-2"></div>
						</div>
						<div className="col-md-3">
							<a
								href="javascript:;"
								onClick={(e) => deleteFormRow(i)}
								className="btn btn-sm font-weight-bolder btn-light-danger mt-10"
							>
								<i className="la la-trash-o"></i>
								{cancel[selectedLanguage]}
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								{municipalityTitle[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="municipalityTitle"
								name="municipalityTitle"
								onChange={formik.handleChange}
								value={formik.values?.municipalityTitle}
							>
								{municipality.map((type) => (
									<option value={type.value}>
										{type[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label className="form-control-label">
								{wardQuestion[selectedLanguage]}
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								{ward.map((woda) => (
									<option value={woda.value}>
										{woda[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="form-group row">
						<div className="col-md-6">
							<label>{photo[selectedLanguage]}</label>
							<ImageUploader fileListContent={props.fileListContent} />
						</div>
					</div>
					<div>
						<hr />
						<h3>{physicalTitle[selectedLanguage]}</h3>
						{getFormRowList()}
						<div className="form-group row">
							<div className="col-lg-4">
								<a
									href="javascript:;"
									onClick={addFormRow}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>
									{add[selectedLanguage]}
								</a>
							</div>
						</div>
					</div>

					<div className="card-footer text-right">
						<FormFooterButtons />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
}
export default PhysicalResource;
