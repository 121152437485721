import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage, useFormik } from "formik";
import FormFooterButtons from "../components/FormFooterButtons";
import { wardSelect } from "../data/advanceSearch";
import { selectedLanguage, incidentList } from "../data/institute";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	data: {
		ward: "",
		fiscalYear: [""],
		population: [[{ incidents: "", total: 0 }]],
		uniqueIdentifier: GetUUID(),
	}
};
const PersonalIncident = (props) => {
	const [apiData, setApiData] = useState({})
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		}
	})
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						population: props.data.population?.length ? props.data.population : [[{ incidents: "", total: 0 }]],
					}
				}
			})
		}
		else {
			setApiData(initData)
		}
		console.log(props)
	}, [props])
	const deleteFormRowParent = (index) => {
		const prevPopulation = [...formik.values.population];
		prevPopulation.splice(index, 1);
		const prevFiscal = [...formik.values.fiscalYear];
		prevFiscal.splice(index, 1);
		formik.setValues({
			...formik.values,
			population: prevPopulation,
			fiscalYear: prevFiscal,
		});
	};
	const addFormRow = (parentIndex) => {
		const prevPopulation = [...formik.values.population];
		prevPopulation[parentIndex] = [
			...prevPopulation[parentIndex],
			{ incidents: "", total: 0 },
		];
		formik.setValues(
			{
				...formik.values,
				population: prevPopulation,
			}
		);
	};
	const deleteFormRow = (index, parentIndex) => {
		const prevPopulation = [...formik.values.population];
		prevPopulation[parentIndex].splice(index, 1);
		formik.setValues(
			{
				...formik.values,
				population: prevPopulation,
			});
	};
	const getFormRowListParent = () => {
		return formik.values?.population?.map((row, i) => (
			<Card key={i} className="mb-3">
				<Card.Body>
					<div>
						<div className="form-group row">
							<div className="col-12 d-flex justify-content-between">
								<h3>व्यक्तिगत घटना विवरण</h3>
								{i !== 0 && (
									<a
										href="javascript:;"
										onClick={(e) => deleteFormRowParent(i)}
										className="btn btn-sm font-weight-bolder btn-light-danger"
									>
										<i className="la la-plus"></i>Remove Section
									</a>
								)}
							</div>
						</div>
						<div className="form-group row">
							<div className="col-md-6">
								<label className="form-control-label">मिति</label>
								<input
									type="number"
									className="form-control"
									name={`fiscalYear[${i}]`}
									value={formik.values?.fiscalYear[i]}
									onChange={formik.handleChange}
									placeholder="YYYY"
									min="2017"
									max="2100"
								/>
							</div>
						</div>

						<div className="form-group row">
							<div className="col-lg-3">
								<a
									href="javascript:;"
									onClick={(e) => addFormRow(i)}
									className="btn btn-sm font-weight-bolder btn-light-primary"
								>
									<i className="la la-plus"></i>Add Row
								</a>
							</div>
						</div>
						{getFormRowList(i)}
					</div>
				</Card.Body>
			</Card>
		));
	};
	const getFormRowList = (parentIndex) => {
		return formik.values?.population[parentIndex].map((row, i) => (
			<div className="form-group row" key={`child-${parentIndex}-${i}`}>
				<div className="col-md-4">
					{i == 0 && (
						<label className="form-control-label">घटनाको प्रकार</label>
					)}
					<select
						className="form-control"
						id="incidentType"
						name={`population[${parentIndex}][${i}].incidents`}
						onChange={formik.handleChange}
						value={formik.values?.population[parentIndex][i]?.incidents}
					>
						<option disabled selected value="">
							घटनाको प्रकार छान्नुहोस{" "}
						</option>
						{incidentList.map((incident, index) => (
							<option value={incident.value}>
								{incident[selectedLanguage]}
							</option>
						))}
					</select>
				</div>
				<div className="col-md-2">
					{i == 0 && <label className="form-control-label">जम्मा </label>}
					<input
						type="number"
						className="form-control"
						placeholder="पुरुष"
						name={`population[${parentIndex}][${i}].total`}
						onChange={formik.handleChange}
						value={formik.values?.population[parentIndex][i]?.total}
					/>
				</div>
				{i !== 0 && (
					<div className="col-md-2">
						<a
							href="javascript:;"
							onClick={(e) => deleteFormRow(i, parentIndex)}
							className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
						>
							<i className="la la-trash-o"></i>Delete
						</a>
					</div>
				)}
			</div>
		));
	};

	const addFormRowParent = () => {
		const prevPopulation = [...formik.values.population, [{ incidents: "", total: 0 }]];
		console.log(prevPopulation)
		const prevFiscal = [...formik.values.fiscalYear, ""];
		formik.setValues(
			{
				...formik.values,
				population: prevPopulation,
				fiscalYear: prevFiscal,
			}
		);
	};
	return (
		<form className="form survey-form" onSubmit={formik.handleSubmit}>
			<Card>
				<Card.Body>
					<div className="form-group row">
						<div className="col-md-6">
							<label className="form-control-label">
								वडा नम्बर
							</label>
							<select
								className="form-control"
								id="ward"
								name="ward"
								onChange={formik.handleChange}
								value={formik.values?.ward}
							>
								<option disabled selected value="">
									वडा नम्बर छान्नुहोस{" "}
								</option>
								{wardSelect.map((ward, index) => (
									<option key={index} value={ward.value}>
										{ward[selectedLanguage]}
									</option>
								))}
							</select>
						</div>
					</div>
				</Card.Body>
			</Card>
			{getFormRowListParent()}
			<div className="form-group row">
				<div className="col-12 text-center">
					<a
						href="javascript:;"
						onClick={addFormRowParent}
						className="btn btn-sm font-weight-bolder btn-primary"
					>
						<i className="la la-plus"></i>Add Section
					</a>
				</div>
			</div>
			<div className="card-footer text-right">
				<FormFooterButtons isSubmitting={formik.isSubmitting} />
			</div>
		</form>
	);
}

export default PersonalIncident;
