import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	name,
	memberTitle,
	descriptionTitle,
	address,
	male,
	female,
	familyTitle,
	dalit,
	janjati,
	others,
	amountQn,
	investment,
	areaQn,
	womenInstituteType,
	instituteTitle,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";

const ChildHome = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : {
				ward: "",
				name: "",
				address: "",
				amount: "",
				investment: "",
				area: "",
				description: {
					member: {
						male: "",
						female: "",
					},
					family: {
						dalit: "",
						janjati: "",
						instituteType: "",
						others: "",
					},
				},
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				this.props.submit(values);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											classfestivalName="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{instituteTitle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="instituteType"
											name="description.family.instituteType"
											onChange={handleChange}
											value={values.description.family.instituteType}
										>
											{womenInstituteType.map((list) => (
												<option value={list.value}>
													{list[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="instituteType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<h3>{descriptionTitle[selectedLanguage]}</h3>
									<br />
									<h4>{memberTitle[selectedLanguage]}</h4>
									<div className="col-md-6">
										<label className="form-control-label">
											{male[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={male[selectedLanguage]}
											name="description.member.male"
											onChange={handleChange}
											value={values?.description.member.male}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="male"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{female[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={female[selectedLanguage]}
											name="description.member.female"
											onChange={handleChange}
											value={values?.description.member.female}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="female"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<h3>{familyTitle[selectedLanguage]}</h3>
									<div className="col-md-6">
										<label className="form-control-label">
											{janjati[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={janjati[selectedLanguage]}
											name="description.family.janjati"
											onChange={handleChange}
											value={values?.description.family.janjati}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="janjati"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{others[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={others[selectedLanguage]}
											name="description.family.others"
											onChange={handleChange}
											value={values?.description.family.others}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="others"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{amountQn[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={amountQn[selectedLanguage]}
											name="amount"
											onChange={handleChange}
											value={values?.amount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="amount"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label className="form-control-label">
											{investment[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={investment[selectedLanguage]}
											name="investment"
											onChange={handleChange}
											value={values?.investment}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="investment"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{areaQn[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={areaQn[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values?.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="janjati"
											component="div"
										/>
									</div>

									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default ChildHome;
