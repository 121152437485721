import Table from 'rc-table';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getTableData } from '../modules/InstituteSurvey/_redux/surveyCrud';
import Loader from './Loader';
import Pagination from './Pagination';

const DetailTable = (props) => {
    const { subCatRoute } = props;
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (subCatRoute) {
            setLoading(true);
            getTableData(subCatRoute).then((res) => {
                setLoading(false)
                console.log(res);
                if (res.data.data) {
                    const { title, data } = res.data
                    const pages = Math.ceil(data.length / 10);
                    setTotalPages(pages)
                    const dispData = data.slice(0, 10)
                    setColumns([...title,
                    {
                        title: '', dataIndex: '', key: 'd', render: function (val) {
                            return (
                                <div className="d-flex table-actions">
                                    <i className="flaticon-eye mr-3 mb-3" onClick={(e) => props.viewData(val.id)}></i>
                                    <i className="flaticon-edit mr-3 mb-3" onClick={(e) => props.editData(val.id)}></i>
                                    <i className="flaticon-delete" onClick={(e) => props.deleteData(val.id)}></i>
                                </div>
                            )
                        }
                    }])
                    setData(data)
                    setDisplayData(dispData)
                }
            }).catch((err) => {
                toast.error("An error occured while fetching data")
                setLoading(false)
            })
        }
    }, [subCatRoute])
    const handlePageChange = (val) => {
        const start = (val) * 10;
        const end = start + 10;
        const dispData = data.slice(start, end)
        setDisplayData(dispData);
    }
    return (
        <>
            {
                loading && <Loader loading={loading} />
            }
            {
                !loading && (
                    <>
                        <Table className="table table-hover" columns={columns} data={displayData} />
                        <Pagination pages={totalPages} handlePageChange={handlePageChange} />
                    </>
                )
            }
        </>
    );
}

export default DetailTable;