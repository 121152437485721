import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	cancel,
	selectedLanguage,
	ward,
	wardQuestion,
	photo,
	address,
	name,
	groundTypeQn,
	groundType,
	annualIncome,
	use,
	useQn,
	partner,
	partnerTitle,
	countTitle,
	functions,
	citizenTitle,
	types,
	areaList,
	area,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	name: "",
	ward: "",
	address: "",
	groundType: [],
	use: "",
	area: "",
	areaType: "",
	partnership: "",
	annualIncome: "",
	count: "",
	functions: "",
	types: "",
	uniqueIdentifier: GetUUID(),
};

const Ground = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{useQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="use"
											name="use"
											onChange={handleChange}
											value={values.use}
										>
											{use.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{area[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label
											style={{ marginTop: 19 }}
											className="form-control-label"
										></label>
										<select
											className="form-control"
											id="areaType"
											name="areaType"
											onChange={handleChange}
											value={values.areaType}
										>
											{areaList.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="areaType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{partnerTitle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="partnership"
											name="partnership"
											onChange={handleChange}
											value={values.partnership}
										>
											{partner.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="partnership"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{annualIncome[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="annualIncome"
											onChange={handleChange}
											value={values.annualIncome}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualIncome"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{countTitle[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="count"
											onChange={handleChange}
											value={values.count}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="count"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{functions[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="functions"
											onChange={handleChange}
											value={values.functions}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="functions"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{citizenTitle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="types"
											name="types"
											onChange={handleChange}
											value={values.level}
										>
											{types.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="types"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{groundTypeQn[selectedLanguage]}
										</label>
										<select
											type="multiple"
											className="form-control"
											id="groundType"
											name="groundType"
											onChange={handleChange}
											value={values.level}
										>
											{groundType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="groundType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Ground;
