import Table from "rc-table";
import React, { Component } from "react";
import Axios from "axios";
import { Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { getLogData } from "../modules/HomeSurvey/homeSurveyCrud";
import Pagination from "../components/Pagination";
import { CustomPaginationActionsTable } from "../components/CustomPaginationActionsTable";

const columns = [
	{
		title: "क्र.स",
		key: "sNo",
		dataIndex: "sNo",
		width: null,
	},
	{
		title: "लग मेसेज",
		key: "to",
		dataIndex: "to",
		width: null,
	},
	{
		title: "गतिविधी",
		key: "action",
		dataIndex: "action",
		width: null,
	},
	{
		title: "द्वारा गतिविधि",
		key: "by",
		dataIndex: "by",
		width: null,
	},
	{
		title: "ip",
		key: "ip",
		dataIndex: "ip",
		width: null,
	},
	{
		title: "मिति",
		key: "createdAt",
		dataIndex: "createdAt",
		width: null,
	},
];

class ListLog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			rowsPerPage: 10,
			sn: "",
			action: "",
			to: "",
			by: "",
			createdAt: "",
			logs: [],
			loading: true,
			totalPages: 0,
			filteredData: [],
			displayData: [],
		};
	}
	componentDidMount() {
		this.fetchLog();
	}

	fetchLog = () => {
		// let page = this.state.page;
		// let row = this.state.rowsPerPage;
		getLogData()
			.then((res) => {
				const newData = res.data.data.map((datum, i) => {
					return {
						...datum,
						sNo: i + 1,
						to: datum.to,
						action: datum.action,
						by: datum.by,
						ip: datum.ip,
						createdAt: datum.createdAt,
					};
				});
				const pages = Math.ceil(res.data.data.length / 10);
				const dispData = newData.slice(0, 10);
				this.setState({
					logs: newData,
					loading: false,
					totalPages: pages,
					filteredData: newData,
					displayData: dispData,
				});
			})
			.catch((err) => {});
	};

	handlePageChange = (val) => {
		const start = val * 10;
		const end = start + 10;
		const dispData = this.state.filteredData.slice(start, end);
		this.setState({ displayData: dispData });
	};

	render() {
		const { loading, totalPages, displayData } = this.state;
		return (
			<>
				<Card style={{ width: "100%", minHeight: "80vh" }}>
					<Card.Body>
						<Card.Title>
							<h1 className="mb-10">लग व्यवस्थापन</h1>
						</Card.Title>

						{loading && <Loader loading={loading} />}
						{!loading && (
							<>
								<Table
									className="table table-hover"
									columns={columns}
									data={displayData}
									data-filter-control="true"
								/>
								{/* <CustomPaginationActionsTable
										page={page}
										setPage={setPage}
										handleChangePage={handleChangePage}
										total={totalDataCount}
										tableData={tableData}
										columnData={tableColumns}
									/> */}
								<Pagination
									pages={totalPages}
									handlePageChange={this.handlePageChange}
								/>
							</>
						)}
					</Card.Body>
				</Card>
			</>
		);
	}
}

export default ListLog;
