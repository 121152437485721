import React, { useEffect, useMemo } from "react";
import { Accordion, Button, Card, Tab, Tabs } from "react-bootstrap";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import { reportList } from "../data/report";
import { selectedLanguage } from "../data/institute";
import BarGraphCard from "../detailTypes/overview-sections/BarGraphCard";
const HomeSurveyDataOverview = () => {
	const uiService = useHtmlClassService();

	const layoutProps = useMemo(() => {
		return {
			colorsGrayGray500: objectPath.get(
				uiService.config,
				"js.colors.gray.gray500"
			),
			colorsGrayGray200: objectPath.get(
				uiService.config,
				"js.colors.gray.gray200"
			),
			colorsGrayGray300: objectPath.get(
				uiService.config,
				"js.colors.gray.gray300"
			),
			colorsThemeBaseDanger: objectPath.get(
				uiService.config,
				"js.colors.theme.base.danger"
			),
			fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
		};
	}, [uiService]);

	useEffect(() => {
		const element1 = document.getElementById("school_chart");
		// renderChart(element1, layoutProps)
	}, [layoutProps]);
	const handleDetailTypeSelect = () => {};
	return (
		<>
			<Card>
				<Card.Body>
					<Tabs
						size="sm"
						variant="pills"
						defaultActiveKey={"populationReport"}
						id="uncontrolled-tab-example"
						onSelect={handleDetailTypeSelect}
					>
						{reportList.map((report, parentIndex) => {
							return (
								<Tab
									key={report.value}
									eventKey={report.value}
									title={report[selectedLanguage]}
								>
									<Card className="mb-3" key={parentIndex}>
										<Card.Header>
											<h2>{report[selectedLanguage]}</h2>
										</Card.Header>
										<Card.Body>
											<Accordion defaultActiveKey={0}>
												{report.child.map((eachChild, index) => (
													<BarGraphCard
														key={index}
														child={eachChild}
														parentIndex={parentIndex}
														childIndex={index}
													/>
												))}
											</Accordion>
										</Card.Body>
									</Card>
								</Tab>
							);
						})}
					</Tabs>
				</Card.Body>
			</Card>
			{/* {reportList.map((report, parentIndex) => (
            ))} */}
		</>
		// <Card>
		//     <Card.Header>
		//         <h2>
		//             शिक्षा सम्बन्धी बिबरण
		//         </h2>
		//     </Card.Header>
		//     <Card.Body>

		//         <Accordion defaultActiveKey="0">

		//         </Accordion>
		//     </Card.Body>
		// </Card>
	);
};

export default HomeSurveyDataOverview;
