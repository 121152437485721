import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import {
	wardQuestion,
	photo,
	name,
	consultant,
	others,
	fireManpower,
	searchManpower,
	healthManpower,
	disasterInstituteType,
	ward,
	selectedLanguage,
} from "../data/institute";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import { Card } from "react-bootstrap";

const initData = {
	ward: "",
	name: "",
	searchManpower: "",
	fireManpower: "",
	consultant: "",
	others: "",
	healthManpower: "",
	uniqueIdentifier: GetUUID(),
};

const DisasterOrg = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				// 	errors.ward = "Required";
				// }
				// if (!values.name) {
				// 	errors.name = "Required";
				// }
				// if (!values.searchManpower) {
				// 	errors.searchManpower = "Required";
				// }
				// if (!values.address) {
				// 	errors.address = "Required";
				// }
				// if (!values.midArea) {
				// 	errors.midArea = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.owner) {
				// 	errors.owner = "Required";
				// }
				// if (!values.mainAnimal) {
				// 	errors.mainAnimal = "Required";
				// } else if (
				// 	!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				// ) {
				// 	errors.email = "Invalid email address";
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values?.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="name"
											name="name"
											onChange={handleChange}
											value={values?.name}
										>
											{disasterInstituteType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{fireManpower[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={fireManpower[selectedLanguage]}
											name="fireManpower"
											onChange={handleChange}
											value={values.fireManpower}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="fireManpower"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{healthManpower[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={healthManpower[selectedLanguage]}
											name="healthManpower"
											onChange={handleChange}
											value={values.healthManpower}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="healthManpower"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{searchManpower[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={searchManpower[selectedLanguage]}
											name="searchManpower"
											onChange={handleChange}
											value={values.searchManpower}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="searchManpower"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{consultant[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={consultant[selectedLanguage]}
											name="consultant"
											onChange={handleChange}
											value={values.consultant}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="consultant"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{others[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={others[selectedLanguage]}
											name="others"
											onChange={handleChange}
											value={values.others}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="others"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default DisasterOrg;
