import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	address,
	count,
	type,
	instituteTitle,
	institutionType,
	workingField,
	instituteHead,
	instituteService,
	name,
	phone,
	photo,
	position,
	wardQuestion,
	mainfield,
	helperName,
	annualBudget,
	worker,
	selectedLanguage,
	ward,
} from "../data/institute";

const Institution = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : {
				ward: "",
				name: "",
				address: "",
				institutionType: "",
				helperName: "",
				worker: "",
				annualBudget: "",
				mainfield: "",
				instituteService: "",
				workingField: "",
				presidentName: "",
				presidentContact: "",
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.institutionType) {
				//     errors.institutionType = 'Required';
				// }
				// if (!values.helperName) {
				//     errors.helperName = 'Required';
				// }
				// if (!values.worker) {
				//     errors.worker = 'Required';
				// }
				// if (!values.annualBudget) {
				//     errors.annualBudget = 'Required';
				// }
				// if (!values.mainfield) {
				//     errors.mainfield = 'Required';
				// }
				// if (!values.instituteService) {
				//     errors.instituteService = 'Required';
				// }
				// if (!values.workingField) {
				//     errors.workingField = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{type[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="institutionType"
											name="institutionType"
											onChange={handleChange}
											value={values.institutionType}
										>
											{institutionType.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="institutionType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									{/* <div className="col-md-6">
                                            <label className="form-control-label" >उपलब्ध सेवाहरु</label>
                                            <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="instituteService" onChange={handleChange} value={values.instituteService} ></textarea>
                                            <ErrorMessage className="invalid-feedback" name="instituteService" component="div" />
                                        </div> */}
									<div className="col-md-6">
										<label className="form-control-label">
											{workingField[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="mainfield"
											onChange={handleChange}
											value={values.mainfield}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="mainfield"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{annualBudget[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={annualBudget[selectedLanguage]}
											name="annualBudget"
											onChange={handleChange}
											value={values.annualBudget}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualBudget"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{worker[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={worker[selectedLanguage]}
											name="worker"
											onChange={handleChange}
											value={values.worker}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="worker"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{mainfield[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={mainfield[selectedLanguage]}
											name="workingField"
											onChange={handleChange}
											value={values.workingField}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="workingField"
											component="div"
										/>
									</div>
								</div>
								{/* <hr />
                                    <h3>संस्था प्रमुखको</h3>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label" >नाम</label>
                                            <input type="text" className="form-control" placeholder="नाम" name="presidentName" onChange={handleChange} value={values.presidentName} />
                                            <ErrorMessage className="invalid-feedback" name="presidentName" component="div" />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label" >फोन नम्बर</label>
                                            <input type="text" className="form-control" placeholder="फोन नम्बर" name="presidentContact" onChange={handleChange} value={values.presidentContact} />
                                            <ErrorMessage className="invalid-feedback" name="presidentContact" component="div" />
                                        </div>
                                    </div> */}
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{helperName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={helperName[selectedLanguage]}
											name="helperName"
											onChange={handleChange}
											value={values.presidentContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentContact"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Institution;
