/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import jwtDecode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export function Routes() {
    const [isAuthorized, setIsAuthorized] = useState(undefined);
    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth?.token,
        }),
        shallowEqual
    );
    useEffect(() => {
        let token = authToken?.split("Bearer: ")[0];
        if (token) {
            let decodedToken = jwtDecode(token);
            let currentDate = new Date();

            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                setIsAuthorized(false);
            } else {
                setIsAuthorized(true);
                // result = true;
            }
        } else {
            setIsAuthorized(false);
        }
    }, [authToken]);

    // return (
    //     <Layout>
    //         <BasePage />
    //     </Layout>
    // )
    return (
        <>
            {
                isAuthorized !== undefined && (
                    <Switch>
                        {!isAuthorized ? (
                            /*Render auth page when user at `/auth` and not authorized.*/
                            <Route>
                                <AuthPage />
                            </Route>
                        ) : (
                            /*Otherwise redirect to root page (`/`)*/
                            <Redirect from="/auth" to="/" />
                        )}

                        <Route path="/error" component={ErrorsPage} />
                        <Route path="/logout" component={Logout} />

                        {!isAuthorized ? (
                            /*Redirect to `/auth` when user is not authorized*/
                            <Redirect to="/auth/login" />
                        ) : (
                            <Layout>
                                <BasePage />
                            </Layout>
                        )}
                    </Switch>

                )
            }
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
