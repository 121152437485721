import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import FormFooterButtons from '../components/FormFooterButtons';
import { GetUUID } from '../data/helpers/RandomNumGenerator';
import { Card } from 'react-bootstrap';
const initData = {
    name: '', ward: '', address: '', area: '', farmType: '', animal: '', unit: '', rate: '', presidentName: '', presidentContact: '', male: '', female: '',
    uniqueIdentifier: GetUUID(),
};

const AnimalFarm = (props) => (
    <Formik
        enableReinitialize
        initialValues={props.data ? props.data : initData}
        validate={values => {
            const errors = {};
            // if (!values.name) {
            //     errors.name = 'Required';
            // }
            // if (!values.ward) {
            //     errors.ward = 'Required';
            // }
            // if (!values.address) {
            //     errors.address = 'Required';
            // }
            // if (!values.area) {
            //     errors.area = 'Required';
            // }
            // if (!values.farmType) {
            //     errors.farmType = 'Required';
            // }
            // if (!values.unit) {
            //     errors.unit = 'Required';
            // }
            // if (!values.rate) {
            //     errors.rate = 'Required';
            // }
            // if (!values.animal) {
            //     errors.animal = 'Required';
            // }
            // if (!values.presidentContact) {
            //     errors.presidentContact = 'Required';
            // }
            // if (!values.presidentName) {
            //     errors.presidentName = 'Required';
            // }
            // if (!values.male) {
            //     errors.male = 'Required';
            // }
            // if (!values.female) {
            //     errors.female = 'Required';
            // }
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
                props.submit(values);
                setSubmitting(false);
            }, 400);
        }}
    >
        {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset, }) => (
            <>
                <form className="form survey-form" onSubmit={handleSubmit}>
                    <Card>
                        <Card.Body>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >नाम</label>
                                    <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                    <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >वडा नम्बर</label>
                                    <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                    <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >
                                        ठेगाना</label>
                                    <textarea class="form-control" name="infrastructure" placeholder="यदि त्यहाँ धेरै ठेगानाहरू छन् भने कृपया तिनीहरूलाई अल्पविरामद्वारा विभाजित गर्नुहोस्" rows="3" onChange={handleChange} value={values.address}></textarea>
                                    <ErrorMessage className="invalid-feedback" name="infrastructure" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >क्षेत्र</label>
                                    <input type="text" className="form-control" placeholder="क्षेत्र" name="area" onChange={handleChange} value={values.area} />
                                    <ErrorMessage className="invalid-feedback" name="area" component="div" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >खेतको प्रकार</label>
                                    <input type="text" className="form-control" placeholder="प्रकार" name="farmType" onChange={handleChange} value={values.farmType} />
                                    <ErrorMessage className="invalid-feedback" name="farmType" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >एकाइ</label>
                                    <input type="number" className="form-control" placeholder="एकाइ" name="unit" onChange={handleChange} value={values.unit} />
                                    <ErrorMessage className="invalid-feedback" name="unit" component="div" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >दर</label>
                                    <input type="number" className="form-control" placeholder="दर" name="rate" onChange={handleChange} value={values.rate} />
                                    <ErrorMessage className="invalid-feedback" name="rate" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >अध्यक्षको नाम</label>
                                    <input type="text" className="form-control" placeholder="नाम" name="presidentName" onChange={handleChange} value={values.presidentName} />
                                    <ErrorMessage className="invalid-feedback" name="presidentName" component="div" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >अध्यक्षको सम्पर्क</label>
                                    <input type="text" className="form-control" placeholder="सम्पर्क" name="presidentContact" onChange={handleChange} value={values.presidentContact} />
                                    <ErrorMessage className="invalid-feedback" name="presidentContact" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >पशु गणना</label>
                                    <input type="text" className="form-control" placeholder="गणना" name="animal" onChange={handleChange} value={values.animal} />
                                    <ErrorMessage className="invalid-feedback" name="animal" component="div" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6">
                                    <label className="form-control-label" >पुरुष गणना</label>
                                    <input type="number" className="form-control" placeholder="गणना" name="male" onChange={handleChange} value={values.male} />
                                    <ErrorMessage className="invalid-feedback" name="male" component="div" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-control-label" >महिला गणना</label>
                                    <input type="number" className="form-control" placeholder="गणना" name="female" onChange={handleChange} value={values.female} />
                                    <ErrorMessage className="invalid-feedback" name="female" component="div" />
                                </div>
                            </div>

                            <div className="card-footer text-right">
                                <FormFooterButtons />
                            </div>
                        </Card.Body>
                    </Card>
                </form>
            </>
        )}
    </Formik >
);

export default AnimalFarm;