export const allToleName = [
	// 1
	{
		nepali: "कालितारा",
		english: "kaalitaaraa_tole",
		value: "kaalitaaraa_tole",
	},
	{
		nepali: "हाउरे",
		english: "haure",
		value: "haure",
	},
	{
		nepali: "ठाँटी",
		english: "thati",
		value: "thati",
	},
	{
		nepali: "मलै",
		english: "mlai",
		value: "mlai",
	},
	{
		nepali: "बुर्केसाल",
		english: "burkesaal",
		value: "burkesaal",
	},
	{
		nepali: "रहरपुर",
		english: "raharpur",
		value: "raharpur",
	},
	{
		nepali: "बाँकी",
		english: "banki",
		value: "banki",
	},
	{
		nepali: "रामपुर",
		english: "raampur",
		value: "raampur",
	},
	{
		nepali: "गोल्टाकुरी",
		english: "goltakuri",
		value: "goltakuri",
	},
	{
		nepali: "लामितरा",
		english: "lamitara",
		value: "lamitara",
	},
	{
		nepali: "छर्कट्टा",
		english: "charkatta",
		value: "charkatta",
	},
	{
		nepali: "गनारी",
		english: "ganaari",
		value: "ganaari",
	},
	// 2
	{
		nepali: "गोल्टाकुरी",
		english: "goltakuri",
		value: "goltakuri",
	},
	{
		nepali: "नारायणपुर",
		english: "narayanpur",
		value: "narayanpur",
	},
	{
		nepali: "मधपुर",
		english: "madhpur",
		value: "madhpur",
	},
	{
		nepali: "सरुवाडाँडा",
		english: "saruwadanda",
		value: "saruwadanda",
	},
	{
		nepali: "कामिटोल",
		english: "kamitole",
		value: "kamitole",
	},
	{
		nepali: "सिमलकुना",
		english: "simal_kuna",
		value: "simal_kuna",
	},
	{
		nepali: "बंगालपुर",
		english: "bangalpur",
		value: "bangalpur",
	},
	{
		nepali: "नमुनावस्ती",
		english: "namuna_basti",
		value: "namuna_basti",
	},
	{
		nepali: "कौवाघारी",
		english: "kauwaghari",
		value: "kauwaghari",
	},
	{
		nepali: "सिमतारा",
		english: "simtara",
		value: "simtara",
	},
	{
		nepali: "साट्टा कुरा",
		english: "sattakura",
		value: "sattakura",
	},
	{
		nepali: "कलङ्गखोला",
		english: "kalang_khola",
		value: "kalang_khola",
	},
	// 3
	{
		nepali: "चितानपुर",
		english: "chintanpur",
		value: "chintanpur",
	},
	{
		nepali: "पदमपुर",
		english: "padampur",
		value: "padampur",
	},
	{
		nepali: "एकली",
		english: "ekali",
		value: "ekali",
	},
	{
		nepali: "बिजुलिपुर",
		english: "bijulipur",
		value: "bijulipur",
	},
	{
		nepali: "रावत गाउँ टोल",
		english: "rawat_gaun_tole",
		value: "rawat_gaun_tole",
	},
	{
		nepali: "चखौरा",
		english: "chakhaura",
		value: "chakhaura",
	},
	{
		nepali: "हेकुली",
		english: "hekuli",
		value: "hekuli",
	},
	// 4
	{
		nepali: "सुकदेवा",
		english: "sukdewa",
		value: "sukdewa",
	},
	{
		nepali: "बालुवा",
		english: "baluwa",
		value: "baluwa",
	},
	{
		nepali: "सरैया",
		english: "sareya",
		value: "sareya",
	},
	{
		nepali: "मौलि",
		english: "mauli",
		value: "mauli",
	},
	{
		nepali: "मुर्घेवा",
		english: "murghewa",
		value: "murghewa",
	},
	{
		nepali: "बैबांग पुर्व गल्ली",
		english: "purwa_galli",
		value: "purwa_galli",
	},
	{
		nepali: "बैबांग पश्चिम गल्ली",
		english: "paschim_galli",
		value: "paschim_galli",
	},
	{
		nepali: "बैबांग गीटखोली डाँडा",
		english: "gitkhori_danda",
		value: "gitkhori_danda",
	},
	{
		nepali: "कुर्यहवा",
		english: "kuryahawaa",
		value: "kuryahawaa",
	},
	{
		nepali: "दबरा टोल",
		english: "dabara_tole",
		value: "dabara_tole",
	},
	{
		nepali: "माया खोला पारी",
		english: "maaya_kholaa_paari",
		value: "maaya_kholaa_paari",
	},
	{
		nepali: "बैबांग कुकुर खैला",
		english: "kukur_khaila",
		value: "kukur_khaila",
	},
	// 5
	{
		nepali: "सलौरा",
		english: "salaura",
		value: "salaura",
	},
	{
		nepali: "लौवस्ता",
		english: "laubasta",
		value: "laubasta",
	},
	{
		nepali: "खलुवाचीस",
		english: "kaluwachis",
		value: "kaluwachis",
	},
	{
		nepali: "देविगंज",
		english: "dewganj",
		value: "dewganj",
	},
	{
		nepali: "डाँडाचीस",
		english: "danda_chis",
		value: "danda_chis",
	},
	{
		nepali: "बालापुर",
		english: "balapur",
		value: "balapur",
	},
	{
		nepali: "नंद्रागाउँ",
		english: "nandra_gaun",
		value: "nandra_gaun",
	},
	{
		nepali: "कुर्री",
		english: "kurri",
		value: "kurri",
	},
	{
		nepali: "कुमालगडी",
		english: "kumalgadi",
		value: "kumalgadi",
	},
	{
		nepali: "मिरौली",
		english: "mirauli",
		value: "mirauli",
	},
	{
		nepali: "पोटलि",
		english: "potali",
		value: "potali",
	},
	{
		nepali: "विकासनगर",
		english: "bikasnagar",
		value: "bikasnagar",
	},
	// 6
	{
		nepali: "लेटार",
		english: "letar",
		value: "letar",
	},
	{
		nepali: "बागर",
		english: "baagar",
		value: "baagar",
	},
	{
		nepali: "राजपुर",
		english: "rajpur",
		value: "rajpur",
	},
	{
		nepali: "प्रसडुवा",
		english: "prasduwa",
		value: "prasduwa",
	},
	{
		nepali: "खड्गपुर",
		english: "khadgapur",
		value: "khadgapur",
	},
	{
		nepali: "थापगाउँ",
		english: "thapa_gaun",
		value: "thapa_gaun",
	},
	// 7
	{
		nepali: "सिमठानी",
		english: "simthani",
		value: "simthani",
	},
	{
		nepali: "बुटनिया",
		english: "budniya",
		value: "budniya",
	},
	{
		nepali: "बोक्टि",
		english: "bokti",
		value: "bokti",
	},
	{
		nepali: "रचपल",
		english: "rachpal",
		value: "rachpal",
	},
	{
		nepali: "सिमठाना",
		english: "simthana",
		value: "simthana",
	},
	{
		nepali: "बुट्टीपुर",
		english: "battipur",
		value: "battipur",
	},
	{
		nepali: "बाँका",
		english: "banka",
		value: "banka",
	},
	{
		nepali: "श्रीगाउँ",
		english: "shree_gaun",
		value: "shree_gaun",
	},
	{
		nepali: "मंत्रीडाँडा",
		english: "mantri_danda",
		value: "mantri_danda",
	},
	{
		nepali: "बोकटि टोल",
		english: "bokati_tole",
		value: "bokati_tole",
	},
	// EOF
];

export const toleWardOne = [
	{
		nepali: "कालितारा",
		english: "kaalitaaraa_tole",
		value: "kaalitaaraa_tole",
	},
	{
		nepali: "हाउरे",
		english: "haure",
		value: "haure",
	},
	{
		nepali: "ठाँटी",
		english: "thati",
		value: "thati",
	},
	{
		nepali: "मलै",
		english: "mlai",
		value: "mlai",
	},
	{
		nepali: "बुर्केसाल",
		english: "burkesaal",
		value: "burkesaal",
	},
	{
		nepali: "रहरपुर",
		english: "raharpur",
		value: "raharpur",
	},
	{
		nepali: "बाँकी",
		english: "banki",
		value: "banki",
	},
	{
		nepali: "रामपुर",
		english: "raampur",
		value: "raampur",
	},
	{
		nepali: "गोल्टाकुरी",
		english: "goltakuri",
		value: "goltakuri",
	},
	{
		nepali: "लामितरा",
		english: "lamitara",
		value: "lamitara",
	},
	{
		nepali: "छर्कट्टा",
		english: "charkatta",
		value: "charkatta",
	},
	{
		nepali: "गनारी",
		english: "ganaari",
		value: "ganaari",
	},
];

export const toleWardTwo = [
	{
		nepali: "गोल्टाकुरी",
		english: "goltakuri",
		value: "goltakuri",
	},
	{
		nepali: "नारायणपुर",
		english: "narayanpur",
		value: "narayanpur",
	},
	{
		nepali: "मधपुर",
		english: "madhpur",
		value: "madhpur",
	},
	{
		nepali: "सरुवाडाँडा",
		english: "saruwadanda",
		value: "saruwadanda",
	},
	{
		nepali: "कामिटोल",
		english: "kamitole",
		value: "kamitole",
	},
	{
		nepali: "सिमलकुना",
		english: "simal_kuna",
		value: "simal_kuna",
	},
	{
		nepali: "बंगालपुर",
		english: "bangalpur",
		value: "bangalpur",
	},
	{
		nepali: "नमुनावस्ती",
		english: "namuna_basti",
		value: "namuna_basti",
	},
	{
		nepali: "कौवाघारी",
		english: "kauwaghari",
		value: "kauwaghari",
	},
	{
		nepali: "सिमतारा",
		english: "simtara",
		value: "simtara",
	},
	{
		nepali: "साट्टा कुरा",
		english: "sattakura",
		value: "sattakura",
	},
	{
		nepali: "कलङ्गखोला",
		english: "kalang_khola",
		value: "kalang_khola",
	},
];

export const toleWardThree = [
	{
		nepali: "चितानपुर",
		english: "chintanpur",
		value: "chintanpur",
	},
	{
		nepali: "पदमपुर",
		english: "padampur",
		value: "padampur",
	},
	{
		nepali: "एकली",
		english: "ekali",
		value: "ekali",
	},
	{
		nepali: "बिजुलिपुर",
		english: "bijulipur",
		value: "bijulipur",
	},
	{
		nepali: "रावत गाउँ टोल",
		english: "rawat_gaun_tole",
		value: "rawat_gaun_tole",
	},
	{
		nepali: "चखौरा",
		english: "chakhaura",
		value: "chakhaura",
	},
	{
		nepali: "हेकुली",
		english: "hekuli",
		value: "hekuli",
	},
];

export const toleWardFour = [
	{
		nepali: "सुकदेवा",
		english: "sukdewa",
		value: "sukdewa",
	},
	{
		nepali: "बालुवा",
		english: "baluwa",
		value: "baluwa",
	},
	{
		nepali: "सरैया",
		english: "sareya",
		value: "sareya",
	},
	{
		nepali: "मौलि",
		english: "mauli",
		value: "mauli",
	},
	{
		nepali: "मुर्घेवा",
		english: "murghewa",
		value: "murghewa",
	},
	{
		nepali: "बैबांग पुर्व गल्ली",
		english: "purwa_galli",
		value: "purwa_galli",
	},
	{
		nepali: "बैबांग पश्चिम गल्ली",
		english: "paschim_galli",
		value: "paschim_galli",
	},
	{
		nepali: "बैबांग गीटखोली डाँडा",
		english: "gitkhori_danda",
		value: "gitkhori_danda",
	},
	{
		nepali: "कुर्यहवा",
		english: "kuryahawaa",
		value: "kuryahawaa",
	},
	{
		nepali: "दबरा टोल",
		english: "dabara_tole",
		value: "dabara_tole",
	},
	{
		nepali: "माया खोला पारी",
		english: "maaya_kholaa_paari",
		value: "maaya_kholaa_paari",
	},
	{
		nepali: "बैबांग कुकुर खैला",
		english: "kukur_khaila",
		value: "kukur_khaila",
	},
];

export const toleWardFive = [
	{
		nepali: "सलौरा",
		english: "salaura",
		value: "salaura",
	},
	{
		nepali: "लौवस्ता",
		english: "laubasta",
		value: "laubasta",
	},
	{
		nepali: "खलुवाचीस",
		english: "kaluwachis",
		value: "kaluwachis",
	},
	{
		nepali: "देविगंज",
		english: "dewganj",
		value: "dewganj",
	},
	{
		nepali: "डाँडाचीस",
		english: "danda_chis",
		value: "danda_chis",
	},
	{
		nepali: "बालापुर",
		english: "balapur",
		value: "balapur",
	},
	{
		nepali: "नंद्रागाउँ",
		english: "nandra_gaun",
		value: "nandra_gaun",
	},
	{
		nepali: "कुर्री",
		english: "kurri",
		value: "kurri",
	},
	{
		nepali: "कुमालगडी",
		english: "kumalgadi",
		value: "kumalgadi",
	},
	{
		nepali: "मिरौली",
		english: "mirauli",
		value: "mirauli",
	},
	{
		nepali: "पोटलि",
		english: "potali",
		value: "potali",
	},
	{
		nepali: "विकासनगर",
		english: "bikasnagar",
		value: "bikasnagar",
	},
];

export const toleWardSix = [
	{
		nepali: "लेटार",
		english: "letar",
		value: "letar",
	},
	{
		nepali: "बागर",
		english: "baagar",
		value: "baagar",
	},
	{
		nepali: "राजपुर",
		english: "rajpur",
		value: "rajpur",
	},
	{
		nepali: "प्रसडुवा",
		english: "prasduwa",
		value: "prasduwa",
	},
	{
		nepali: "खड्गपुर",
		english: "khadgapur",
		value: "khadgapur",
	},
	{
		nepali: "थापगाउँ",
		english: "thapa_gaun",
		value: "thapa_gaun",
	},
];

export const toleWardSeven = [
	{
		nepali: "सिमठानी",
		english: "simthani",
		value: "simthani",
	},
	{
		nepali: "बुटनिया",
		english: "budniya",
		value: "budniya",
	},
	{
		nepali: "बोक्टि",
		english: "bokti",
		value: "bokti",
	},
	{
		nepali: "रचपल",
		english: "rachpal",
		value: "rachpal",
	},
	{
		nepali: "सिमठाना",
		english: "simthana",
		value: "simthana",
	},
	{
		nepali: "बुट्टीपुर",
		english: "battipur",
		value: "battipur",
	},
	{
		nepali: "बाँका",
		english: "banka",
		value: "banka",
	},
	{
		nepali: "श्रीगाउँ",
		english: "shree_gaun",
		value: "shree_gaun",
	},
	{
		nepali: "मंत्रीडाँडा",
		english: "mantri_danda",
		value: "mantri_danda",
	},
	{
		nepali: "बोकटि टोल",
		english: "bokati_tole",
		value: "bokati_tole",
	},
];
