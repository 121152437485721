import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const CollegeGraph = () => {
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle className="w-100 d-flex" as={Button} variant="primary-light" eventKey="">
                    <h3 className="p-5">
                        कलेजको विवरण
                    </h3>
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <div
                        id="college_chart"
                        className="card-rounded-bottom"
                        style={{ height: "500px" }}
                    >
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default CollegeGraph;