import React from 'react'
import { Button, Card } from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers/index.js";
import { Categories } from '../data/Categories.js';

export function InstituteSurveyList() {
    const categories = Categories;
    return (
        <>
            <div className="container">
                <div className="row">
                    {categories.map(function (category, index) {
                        return (
                            <div className="col-xl-3 col-lg-4 col-sm-6 mb-5" key={index}>
                                <Card style={{ width: '100%' }} className="d-flex flex-column align-items-center justify-content-center">
                                    <Card.Body >
                                        <div className="category-img mb-5 mx-auto">

                                            <SVG width={100} height={100} src={toAbsoluteUrl(category.icon)} />
                                        </div>
                                        <div className="category-card-title d-flex justify-content-center">
                                            <h2 className="text-center mb-5">
                                                {category.title}
                                            </h2>

                                        </div>
                                        <div className="text-center">
                                            <NavLink className="menu-link menu-toggle" to={'/survey/form/' + category.id}>
                                                <button className="btn btn-light-primary font-weight-bold px-16 font-size-h6">View</button>

                                            </NavLink>

                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}