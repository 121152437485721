import { Button, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { data } from "jquery";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
	deathCertificate,
	deathInYearReasonQuestion,
	deathReason,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import CustomSelectMulti from "./CustomSelectMulti";
import { DeclareDeathSave } from "../modules/HomeSurvey/homeSurveyCrud";

const formFields = [
	{
		label: "मृत्यु भएको मिति",
		value: "deathDate",
		type: "text",
		placeholder: "YYY/MM/DD",
	},
	{
		label: "को मृत्यु दर्ता गर्नु भएको छ ?",
		value: "deathCertificate",
		type: "radio",
		placeholder: "",
	},
	{
		label: deathInYearReasonQuestion[selectedLanguage],
		value: "deathReason",
		type: "multiSelect",
		placeholder: "",
		list: deathReason,
	},
	{ label: "", value: "familyId", type: "hidden", placeholder: "" },
	// {
	//     label: "पुरानो पस्स्वोर्ड",
	//     value: "oldPassword",
	// },
	// {
	//     label: "नयाँ पस्स्वोर्ड",
	//     value: "newPassword",
	// },
	// {
	//     label: "नयाँ पासवर्ड पुष्टि",
	//     value: "confirmNewPassword",
	// },
];
const fields = formFields.map((field) => {
	switch (field.value) {
		case "deathDate": {
			const validation = Yup.string().required(`${field.label} अनिवार्य छ`);
			return {
				...field,
				validation,
			};
		}
		default:
			return field;
	}
});
const DeclareDeathSchema = Yup.object().shape(
	fields.reduce(
		(o, key) =>
			Object.assign(o, {
				[key.value]: key.validation,
			}),
		{}
	)
);
const defaultValues = fields.reduce(
	(o, key) =>
		Object.assign(o, {
			[key.value]: "",
		}),
	{}
);
const initialViewPasswordState = {
	oldPassword: false,
	newPassword: false,
	confirmNewPassword: false,
};
const DeclareDeath = (props) => {
	const history = useHistory();
	const [initialValues, setInitialValues] = useState(defaultValues);
	const [viewPassword, setViewPassword] = useState(initialViewPasswordState);
	const formik = useFormik({
		initialValues,
		validationSchema: DeclareDeathSchema,
		enableReinitialize: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			console.log(values);
			// setSubmitting(true)
			DeclareDeathSave(values)
				.then((res) => {
					setSubmitting(false);
					if (res.data.success) {
						handleClose();
						toast.success("Death declared successfully.");
					} else {
						toast.error("An error occured");
					}
				})
				.catch((err) => {
					setSubmitting(false);
					toast.error("An error occured while saving data");
				});
		},
	});
	const toggleViewPassword = (field) => {
		setViewPassword({
			...viewPassword,
			[field]: !viewPassword[field],
		});
	};
	const handleClose = () => {
		formik.resetForm();
		props.setShow(false);
	};
	useEffect(() => {
		formik.setFieldValue("familyId", props.familyId);
	}, [props.familyId]);
	const getFieldsView = (field) => {
		switch (field.type) {
			case "text": {
				return (
					<div className="form-group">
						<label>{field.label}</label>
						<input
							type="text"
							className="form-control mr-3"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							placeholder={field.placeholder}
							name={field.value}
						/>
						{formik.touched[field.value] && formik.errors[field.value] ? (
							<div class="invalid-feedback">{formik.errors[field.value]}</div>
						) : null}
					</div>
				);
			}
			case "radio": {
				return (
					<div class="form-group">
						<label>{field.label}</label>
						<div class="radio-inline">
							{deathCertificate.map((value, i) => (
								<label class="radio radio-rounded">
									<input
										type="radio"
										name={field.value}
										value={value.value}
										checked={value.value === formik.values[field.value]}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
									<span></span> {value[selectedLanguage]}
								</label>
							))}
						</div>
					</div>
				);
			}
			case "multiSelect": {
				return (
					<div className="form-group">
						<label>{field.label}</label>
						<CustomSelectMulti
							isMulti={true}
							list={field.list}
							formik={formik}
							field={field.value}
						/>
					</div>
				);
			}
			default:
				break;
		}
	};
	const getContent = () => {
		const user = formik.values;
		return (
			<>
				<form onSubmit={formik.handleSubmit}>
					<div className="row">
						{fields.map((field) => (
							<div className="col-md-12 mb-4">{getFieldsView(field)}</div>
						))}
						<div className="col-12 d-flex justify-content-end">
							<Button
								variant="light-primary"
								className="mr-3"
								type="submit"
								disabled={formik.isSubmitting}
							>
								{formik.isSubmitting ? (
									<Spinner
										animation="grow"
										variant="primary"
										size="sm"
										className="mr-2"
									/>
								) : null}
								बुझाउनुहोस
							</Button>
							<Button variant="light-danger" onClick={handleClose}>
								रद्द गर्नुहोस
							</Button>
						</div>
					</div>
				</form>
			</>
		);
	};
	return (
		<>
			<Modal
				centered
				show={props.show}
				onHide={handleClose}
				scrollable={true}
				dialogClassName="data-detail-modal"
				size="md"
				style={{ zIndex: "15000" }}
			>
				<Modal.Header closeButton={true}>
					<Modal.Title>Declare Death</Modal.Title>
				</Modal.Header>
				<Modal.Body>{getContent()}</Modal.Body>
			</Modal>
		</>
	);
};

export default DeclareDeath;
