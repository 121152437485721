import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const SchoolGraph = (props) => {
    const { detail } = props;
    return (

        <Card>
            <Card.Header>
                <Accordion.Toggle className="w-100 d-flex justify-content-between" as={Button} variant="primary-light" eventKey="0">
                    <h3 className="p-5">
                        स्कुलको विवरण
                </h3>
                    {
                        !detail && <>
                            <NavLink to="/organization/detail">
                                <button className="btn btn-light-primary font-weight-bold px-16 font-size-h6 mt-2">विवरण हेर्नुहोस</button>
                            </NavLink>
                        </>
                    }
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <div
                        id="school_chart"
                        className="card-rounded-bottom"
                        style={{ height: "500px" }}
                    >
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default SchoolGraph;