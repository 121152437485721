import React from 'react'
import SingleProgressBarCard from './SingleProgressBarCard';

const ProgressBarCard = (props) => {
    const { moreData, fewData } = props;
    return (
        <>
            <div className="row">
                {
                    fewData.map((datum, i) => (
                        <div className="col-md-4" key={i}>
                            <SingleProgressBarCard datum={datum} index={i} />

                        </div>
                    ))
                }
                {
                    moreData.map((datum, i) => (
                        <div className="col-md-4" key={i}>
                            <SingleProgressBarCard datum={datum} index={i} />

                        </div>
                    ))
                }

            </div>
        </>
    );
}

export default ProgressBarCard;