import React, { useEffect, useState } from 'react'
import { anyDeath, deathAge, deathCertificate, deathCertificateQn, deathDiseaseList, deathGender, deathInYeargenderQuestion, deathInYearReasonQuestion, deathName, deathQuestion, deathReason, ifDiseaseDeathQn, ifOtherValueQuestion } from '../data/houseSurvey';
import { selectedLanguage } from '../data/institute';
import Select from 'react-select';
import CustomSelectMulti from '../components/CustomSelectMulti';

const MrityuBibaran = (props) => {
    const [memberCount, setMemberCount] = useState(0)
    const [deathData, setDeathData] = useState(false)
    const [repeaterForm, setRepeaterForm] = useState([])
    const { formik } = props;
    const { death } = formik.values;
    const handleDeathYesNo = (e) => {
        console.log(e.target)
        setDeathData(e.target.value);
    }
    const handleDeathCountChange = (e) => {
        formik.handleChange(e)
        if (parseInt(e.target.value)) {
            setMemberCount(parseInt(e.target.value))
        }
        else {
            setMemberCount(0)
        }
        // setMemberCount(parseInt(e.target.value))
    }
    useEffect(() => {
        console.log(deathData)
        const repeaterForms = [...Array(memberCount).keys()]
        console.log("death", death)
        setRepeaterForm(repeaterForms)
    }, [memberCount])
    useEffect(() => {
        console.log(deathData)
    }, [deathData])
    return (
        <>

            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-8">
                    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                        <h3 class="mb-10 font-weight-bold text-dark">मृत्यु</h3>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{deathQuestion[selectedLanguage]}</label>
                                <div class="radio-inline">
                                    {
                                        anyDeath.map((val, i) => (
                                            <label class="radio radio-rounded">
                                                <input type="radio" name={`death.anyDeath`} value={val.value} onChange={handleDeathYesNo} defaultChecked={val.value === death.anyDeath} />
                                                <span></span>
                                                {val[selectedLanguage]}
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            deathData == "death" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>{deathQuestion[selectedLanguage]}</label>
                                        <input type="number" class="form-control" name="death.deathCount" onChange={handleDeathCountChange} value={death.deathCount} />
                                    </div>
                                </div>
                            )
                        }
                        {
                            deathData == "death" && repeaterForm.map((ind) => (
                                <>
                                    <div className="row mb-7">
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>{deathName[selectedLanguage]}</label>
                                                <input type="text" class="form-control" name={`death.deathName${ind}`} placeholder={deathName[selectedLanguage]} onChange={formik.handleChange} value={death[`deathName${ind}`]} />
                                            </div></div>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>{deathInYeargenderQuestion[selectedLanguage]}</label>
                                                <div class="radio-inline">
                                                    {
                                                        deathGender.map((val, i) => (
                                                            <label class="radio radio-rounded">
                                                                <input type="radio" name={`death.deathGender${ind}`} value={val.value} onChange={formik.handleChange} value={death[`deathGender${ind}`]} defaultChecked={val.value === death[`deathGender${ind}`]} />
                                                                <span></span>
                                                                {val[selectedLanguage]}
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>{deathAge[selectedLanguage]}</label>
                                                <input type="number" class="form-control" name={`death.deathAge${ind}`} onChange={formik.handleChange} value={death[`deathAge${ind}`]} />
                                            </div></div>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>{deathInYearReasonQuestion[selectedLanguage]}</label>
                                                <Select onChange={(e) => formik.setFieldValue(`death.deathReason${ind}`, e.value)} value={death[`deathReason${ind}`]} isMulti={true} options={deathReason.map((child, i) => {
                                                    return {
                                                        value: child.value,
                                                        label: child.nepali
                                                    }
                                                })} />
                                            </div></div>
                                        {
                                            death[`death.deathReason${ind}`] === "tranferable" && (
                                                <>
                                                    <div className="col-lg-6">
                                                        <div class="form-group">
                                                            <label>{ifDiseaseDeathQn[selectedLanguage]}</label>
                                                            <CustomSelectMulti formik={formik} value={death[`deathDiseaseList${ind}`]} isMulti={true} list={deathDiseaseList} field={`death.deathDiseaseList${ind}`} />
                                                        </div></div>
                                                    {
                                                        death[`deathDiseaseList${ind}`]?.includes("other_disease") && (
                                                            <div className="col-lg-6">
                                                                <div class="form-group">
                                                                    <label>{ifOtherValueQuestion[selectedLanguage]}</label>
                                                                    <input type="text" class="form-control" name={`death.deathDiseaseList${ind}Other`} onChange={formik.handleChange} value={death[`deathDiseaseList${ind}Other`]} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>{deathCertificateQn[selectedLanguage]}</label>
                                                <div class="radio-inline">
                                                    {
                                                        deathCertificate.map((val, i) => (
                                                            <label class="radio radio-rounded">
                                                                <input type="radio" name={`death.isDeathCertificate${ind}`} value={val.value} onChange={formik.handleChange} value={death[`isDeathCertificate${ind}`]} defaultChecked={val.value === death[`isDeathCertificate${ind}`]} />
                                                                <span></span>
                                                                {val[selectedLanguage]}
                                                            </label>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>

        </>
    );
}

export default MrityuBibaran;