import React from "react";
import { Formik, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	remove,
	countTitle,
	raga,
	cow,
	korali,
	pada,
	goru,
	annualExpenditure,
	annualIncome,
	meatProduction,
	milkProduction,
	wardQuestion,
	name,
	photo,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";

const CowFarm = () => (
	<div>
		<Formik
			initialValues={{
				name: "",
				count: {
					raga: "",
					cow: "",
					korali: "",
					pada: "",
					goru: "",
				},
				ward: "",
				uniqueIdentifier: "",
				annualExpenditure: "",
				annualIncome: "",
				meatProduction: "",
				milkProduction: "",
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.pondAddress) {
				//     errors.pondAddress = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.area) {
				//     errors.area = 'Required';
				// }
				// if (!values.farmType) {
				//     errors.farmType = 'Required';
				// }
				// if (!values.unit) {
				//     errors.unit = 'Required';
				// }
				// if (!values.rate) {
				//     errors.rate = 'Required';
				// }
				// if (!values.animal) {
				//     errors.animal = 'Required';
				// }
				// if (!values.presidentContact) {
				//     errors.presidentContact = 'Required';
				// }
				// if (!values.presidentName) {
				//     errors.presidentName = 'Required';
				// }
				// if (!values.total) {
				//     errors.total = 'Required';
				// }
				// if (!values.fetotal) {
				//     errors.fetotal = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					alert(JSON.stringify(values, null, 2));
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">फर्मको नाम</label>
										<input
											type="number"
											className="form-control"
											placeholder="फर्मको नाम"
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">वडा नम्बर </label>
										<input
											type="number"
											className="form-control"
											placeholder="वडा नम्बर "
											name="ward"
											onChange={handleChange}
											value={values.ward}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">राँगा</label>
										<input
											type="number"
											className="form-control"
											placeholder="राँगा"
											name="raga"
											onChange={handleChange}
											value={values.raga}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="raga"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">गाई</label>
										<input
											type="number"
											className="form-control"
											placeholder="गाई"
											name="cow"
											onChange={handleChange}
											value={values.cow}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="cow"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">कोरली</label>
										<input
											type="number"
											className="form-control"
											placeholder="कोरली"
											name="korali"
											onChange={handleChange}
											value={values.korali}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="korali"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">पाडा</label>
										<input
											type="number"
											className="form-control"
											placeholder="पाडा"
											name="pada"
											onChange={handleChange}
											value={values.pada}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="pada"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">गोरु</label>
										<input
											type="number"
											className="form-control"
											placeholder="गोरु"
											name="goru"
											onChange={handleChange}
											value={values.goru}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="goru"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">वार्षिक खर्च</label>
										<input
											type="number"
											className="form-control"
											placeholder="वार्षिक खर्च"
											name="annualExpenditure"
											onChange={handleChange}
											value={values.annualExpenditure}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualExpenditure"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											वार्षिक आम्दानी
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="वार्षिक आम्दानी"
											name="annualIncome"
											onChange={handleChange}
											value={values.annualIncome}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="annualIncome"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">मासु उत्पादन</label>
										<input
											type="number"
											className="form-control"
											placeholder="मासु उत्पादन"
											name="meatProduction"
											onChange={handleChange}
											value={values.meatProduction}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="meatProduction"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">दुध उत्पादन</label>
										<input
											type="number"
											className="form-control"
											placeholder="दुध उत्पादन"
											name="milkProduction"
											onChange={handleChange}
											value={values.milkProduction}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="milkProduction"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default CowFarm;
