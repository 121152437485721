import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { constructMessage } from '../modules/Miscellanous/miscellanousActions';
import CustomSelectMulti from './CustomSelectMulti';
import Loader from './Loader';

const initialValues = {
    message: "",
    contacts: [],
    subject: "",
    newNumber: 0
}
const SmsModal = (props) => {
    const { phoneNumbersForSms, setShow, show, loading, setphoneNumbersForSms } = props;
    const [list, setList] = useState([])
    const [value, setValue] = useState([])
    const handleClose = () => setShow(false);
    const [messageSendBody, setMessageSendBody] = useState({
        pageCount: 0,
        typed: 0,
        selectedMember: "",
        scheduleFlag: false,
        scheduleDate: "",
        remaining: 160
    })

    const handleMessageInput = (event) => {
        let message = event.target.value;
        let typed = message.length;
        let remaining = 160 - (typed % 160);
        let pageCount = Math.ceil(typed / 159);
        formik.handleChange(event)
        setMessageSendBody({ ...messageSendBody, pageCount, typed, remaining });
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        // validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            const {
                pageCount,
                typed,
                selectedMember,
                scheduleFlag,
                scheduleDate,
            } = messageSendBody;
            let messageBody = {};
            console.log(values)
            const {
                message,
                contacts,
                subject,
            } = values;
            messageBody.subject = subject;
            messageBody.page = pageCount;
            messageBody.character = typed;
            messageBody.phone = contacts.toString();
            messageBody.message = message;
            messageBody.to = [];
            if (contacts)
                messageBody.to = messageBody.to.concat(
                    contacts.map((item) => item.trim())
                );
            messageBody.type = messageBody.to.length > 1 ? "bulk" : "single";
            if (selectedMember) {
                messageBody.group = selectedMember
                    .filter((each) => each.isGroup)
                    .map((each) => each.value._id);
                messageBody.people = selectedMember
                    .filter((each) => each.isGroup == false)
                    .map((each) => each.value._id);
                //fetch all phone numbers of custom, person from group and person from person.
                selectedMember.map((each) => {
                    if (each.isGroup) {
                        // if selected is a group
                        // group may have comma separated phone numbers ; fetch them
                        if (each.value.phone !== "") {
                            each.value.phone.split(",").map((i) => messageBody.to.push(i));
                        }

                        // fetch person from group
                        if (each.value.person.length > 0) {
                            each.value.person.map((each) =>
                                messageBody.to.push(each.primaryPhone)
                            );
                        }
                    } else {
                        messageBody.to.push(each.value.primaryPhone);
                    }
                });
            }
            messageBody.scheduleFlag = scheduleFlag;
            if (scheduleFlag) messageBody.scheduleDate = scheduleDate;
            constructMessage(messageBody)
                .then((res) => {
                    setSubmitting(false)
                    if (res.data.success) {
                        toast.success("Message sent successfully")
                        setShow(false)
                    }
                    else {
                        toast.error("An error occured while sending message")
                    }
                })
                .catch((error) => {
                    setSubmitting(false)
                    if (error.response) {
                        /* the request was made and the server responded
                        with a status code that falls out of the range of 2xx */
                        console.log(error.response.data.error);
                        toast.error(error.response.data.error)
                    }
                });
        },
    });
    useEffect(() => {
        const contactList = phoneNumbersForSms.map((num) => {
            return {
                nepali: num,
                value: num,
            }
        })
        setList(contactList);
        formik.setFieldValue("contacts", phoneNumbersForSms)
        setValue(phoneNumbersForSms)
    }, [phoneNumbersForSms])
    const addNum = () => {
        if (isValidPhone(formik.values.newNumber)) {
            if (!phoneNumbersForSms.includes(formik.values.newNumber.toString())) {
                const newNumForSms = [...phoneNumbersForSms, formik.values.newNumber.toString()]
                setphoneNumbersForSms(newNumForSms);
                formik.setFieldValue("newNumber", 0)
            }
            else {
                toast.error("Phone number already exists in the list");
            }
        }
        else {
            toast.error("Invalid phone number");
        }
    }
    const isValidPhone = (phoneNumber) =>
        phoneNumber &&
        phoneNumber.toString().length === 10 &&
        phoneNumber.toString().startsWith("98");
    const getContent = () => {
        return (
            <>
                {
                    loading && (
                        <Loader loading={loading} />
                    )
                }
                {
                    !loading && (
                        <form onSubmit={formik.handleSubmit} >
                            <div className="row">
                                <div className="col-12 mb-4">
                                    <label>फोन नम्बर: </label>
                                    <CustomSelectMulti isMulti={true} value={value} isMulti={true} list={list} formik={formik} field="contacts" />
                                    <label className="mt-4">फोन नम्बर थप्नुहोस:</label>
                                    <div className="d-flex">
                                        <input type="number" className="form-control mr-3" onChange={formik.handleChange} name="newNumber" value={formik.values.newNumber} />
                                        <button type="button" className="btn btn-light-success" onClick={addNum}>थप्नुहोस</button>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <label>
                                        विषय :
                                    </label>
                                    <input type="text" className="form-control" name="subject" id="subject" rows="10" onChange={formik.handleChange} />
                                </div>
                                <div className="col-12 mb-4">
                                    <label className="d-flex justify-content-between">
                                        <span>सूचना:</span>
                                        <span>{`पृष्ठ : ${messageSendBody.pageCount} अक्षर: ${messageSendBody.remaining}`}</span>
                                    </label>
                                    <textarea className="form-control" name="message" id="message" rows="10" onChange={(e) => handleMessageInput(e)}></textarea>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-light-primary" disabled={formik.isSubmitting}>
                                        {
                                            formik.isSubmitting ? (<Spinner animation="grow" variant="success" size="sm" className="mr-2" />) : (
                                                <></>)
                                        }Send</button>
                                </div>
                            </div>
                        </form>
                    )
                }
            </>
        )
    }
    return (
        <>
            <Modal centered show={show} onHide={handleClose} scrollable={true} dialogClassName="data-detail-modal" size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Send SMS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        getContent()
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default SmsModal;