import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	wardQuestion,
	photo,
	jungleType,
	daura,
	ayurvedic,
	area,
	annualPro,
	ownersName,
	rate,
	wood,
	mainJungle,
	name,
	areaList,
	unit,
	ward,
	selectedLanguage,
	cancel,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import { Card } from "react-bootstrap";
import FormFooterButtons from "../components/FormFooterButtons";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	area: "",
	areaType: "",
	rate: "",
	jungleType: "",
	ownerName: "",
	wood: "",
	woodType: "",
	daura: "",
	dauraType: "",
	ayurvedic: "",
	ayurvedicType: "",
	uniqueIdentifier: GetUUID(),
};

const Jungle = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				// 	errors.ward = "Required";
				// }
				// if (!values.name) {
				// 	errors.name = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.rate) {
				// 	errors.rate = "Required";
				// }
				// if (!values.jungleType) {
				// 	errors.jungleType = "Required";
				// }
				// if (!values.area) {
				// 	errors.area = "Required";
				// }
				// if (!values.ownerName) {
				// 	errors.ownerName = "Required";
				// }
				// if (!values.wood) {
				// 	errors.wood = "Required";
				// }
				// if (!values.daura) {
				// 	errors.daura = "Required";
				// }
				// if (!values.ayurvedic) {
				// 	errors.ayurvedic = "Required";
				// } else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{mainJungle[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="jungleType"
											name="jungleType"
											onChange={handleChange}
											value={values.jungleType}
										>
											{jungleType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="jungleType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{ownersName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={ownersName[selectedLanguage]}
											name="ownerName"
											onChange={handleChange}
											value={values.ownerName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ownerName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-3">
										<label className="form-control-label">
											{area[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={area[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="areaType"
											name="areaType"
											onChange={handleChange}
											value={values.areaType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="areaType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{rate[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={rate[selectedLanguage]}
											name="rate"
											onChange={handleChange}
											value={values.rate}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="rate"
											component="div"
										/>
									</div>
								</div>
								<hr />
								<h3>{annualPro[selectedLanguage]}</h3>
								<div className="form-group row">
									<div className="col-md-3">
										<label className="form-control-label">
											{wood[selectedLanguage]}{" "}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={wood[selectedLanguage]}
											name="wood"
											onChange={handleChange}
											value={values.wood}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="wood"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="woodType"
											name="woodType"
											onChange={handleChange}
											value={values.woodType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="woodType"
											component="div"
										/>
									</div>
									<div className="col-md-4">
										<label className="form-control-label">
											{daura[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={daura[selectedLanguage]}
											name="daura"
											onChange={handleChange}
											value={values.daura}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="daura"
											component="div"
										/>
									</div>
									<div className="col-md-2">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="dauraType"
											name="dauraType"
											onChange={handleChange}
											value={values.dauraType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="dauraType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-3">
										<label className="form-control-label">
											{ayurvedic[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={ayurvedic[selectedLanguage]}
											name="ayurvedic"
											onChange={handleChange}
											value={values.ayurvedic}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="ayurvedic"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ayurvedicType"
											name="ayurvedicType"
											onChange={handleChange}
											value={values.ayurvedicType}
										>
											{areaList.map((alist) => (
												<option value={alist.value}>{alist.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ayurvedicType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Jungle;
