

import axiosHandler from "../../../../redux/axiosHandler";

export function getAnaysisPopulation(ward) {
    return axiosHandler.get(`/analysis/population/${ward}`);
}
export function getFewData(ward) {
    return axiosHandler.get(`/analysis/population/dashboard/few/${ward}`);
}
export function getMoreData(ward) {
    return axiosHandler.get(`/analysis/population/dashboard/more/${ward}`);
}
export function getCummilativeData() {
    return axiosHandler.get(`/cummulative/all-ward-data`);
}