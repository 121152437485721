import React, { useEffect } from 'react'
import {
    add,
    selectedLanguage,
    update,
    cancel,
} from "../data/institute";
import { useHistory, useParams } from 'react-router-dom';

const FormFooterButtons = (props) => {
    const { isSubmitting } = props;
    const params = useParams();
    let history = useHistory();
    const dataId = params.dataId;
    useEffect(() => {
        // console.log(params.dataId)
    })
    const back = () => {
        if (dataId) {
            history.goBack();
        }
        else {
            window.location.reload()
        }
    }
    return (
        <>
            {!dataId ? (<button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary font-weight-bold mr-2"
            >
                {add[selectedLanguage]}
            </button>) : (<button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary font-weight-bold mr-2"
            >
                {update[selectedLanguage]}
            </button>)}

            <button
                type="button"
                className="btn btn-light-primary font-weight-bold"
                onClick={() => back()}
            >
                {cancel[selectedLanguage]}
            </button>
        </>
    );
}

export default FormFooterButtons;