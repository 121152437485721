import React, { createContext, useEffect } from "react";
import { useState } from "react";

export const SubCatRoute = createContext(
    {
        route: "",
        fileListContent: {

        }
    }
);

export const SubCatRouteConsumer = SubCatRoute.Consumer;

export function SubCatRouteContext({ children, route, fileListContent }) {
    // const [routeVal, setRoute] = useState(route)
    // useEffect(() => {
    //     console.log(route)
    //     setRoute(route)
    // })
    return <SubCatRoute.Provider value={{ route, fileListContent }}>{children}</SubCatRoute.Provider>;
}
