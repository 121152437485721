import React from "react";
import { selectedLanguage } from "../data/institute";

const SingleSelectInput = ({
	formik,
	name,
	value,
	selectList,
	id,
	handleWardChange,
}) => {
	return (
		<>
			{name == "primaryHouse.wardNumber" ? (
				<select
					className="form-control"
					id={id}
					name={name}
					onChange={handleWardChange}
					value={value}
				>
					<option disabled selected>
						कुनै एक छान्नुहोस्
					</option>
					{selectList?.map((value) => (
						<option value={value.value}>{value[selectedLanguage]}</option>
					))}
				</select>
			) : (
				<select
					className="form-control"
					id={id}
					name={name}
					onChange={formik.handleChange}
					value={value}
				>
					<option disabled selected>
						कुनै एक छान्नुहोस्
					</option>
					{selectList?.map((value) => (
						<option value={value.value}>{value[selectedLanguage]}</option>
					))}
				</select>
			)}
		</>
	);
};

export default SingleSelectInput;
