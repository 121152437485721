import React, { useState } from "react";
import { useEffect } from "react";
import ReactExport from "react-export-excel";
import { livingTypeList } from "../data/advanceSearch";
import { selectedLanguage } from "../data/institute";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelExport = (props) => {
    const [data, setData] = useState([])
    useEffect(() => {
        if (props.headers.find(i => i.key == "livingType")) {

            const data = props.data.map((datum) => {
                return {
                    ...datum,
                    livingType: livingTypeList.find(i => i.value === datum.livingType)[selectedLanguage]
                }
            })
            setData(data)
        }
        else {
            const data = props.data
            setData(data)
        }

    }, [props.data])
    return (
        <ExcelFile element={<div id="exportElement" style={{ display: "none" }}></div>}>
            <ExcelSheet data={data} name="Sheet 1">
                {
                    props.headers.map((col) => {
                        return (
                            <ExcelColumn label={col.label} value={col.key} />
                        )
                    })
                }
            </ExcelSheet>
        </ExcelFile>);
}

export default ExcelExport;