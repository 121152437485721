import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	remove,
	address,
	farmType,
	animalFarmType,
	animalFarmProduction,
	farmTypeQn,
	name,
	wardQuestion,
	photo,
	area,
	presidentName,
	presidentContact,
	male,
	female,
	animal,
	unit,
	rate,
	annualTitle,
	employeeTitle,
	presidentTitle,
	areaList,
	areaTypeQn,
	cancel,
	selectedLanguage,
	ward,
} from "../data/institute";

const AgricultureMarket = () => (
	<div>
		<Formik
			initialValues={{
				name: "",
				address: "",
				area: "",
				presidentName: "",
				presidentContact: "",
				farmType: "",
				ward: "",
				annualProduction: {
					animal: "",
					unit: "",
					rate: "",
				},
				employee: {
					male: "",
					female: "",
				},
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				this.props.submit(values);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{farmTypeQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="farmType"
											name="farmType"
											onChange={handleChange}
											value={values.farmType}
										>
											{animalFarmType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="farmType"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{area[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={area[selectedLanguage]}
											name="area"
											onChange={handleChange}
											value={values.area}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="area"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<h3>{annualTitle[selectedLanguage]}</h3>
									<br />
									<div className="col-md-6">
										<label className="form-control-label">
											{animal[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="animal"
											name="animal"
											onChange={handleChange}
											value={values?.annualProduction.animal}
										>
											{animalFarmProduction.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="animal"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{unit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="unit"
											name="unit"
											onChange={handleChange}
											value={values?.annualProduction.unit}
										>
											{areaList.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="animal"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{rate[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={rate[selectedLanguage]}
											name="rate"
											onChange={handleChange}
											value={values?.annualProduction.rate}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="rate"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<h3>{employeeTitle[selectedLanguage]}</h3>
									<br />
									<div className="col-md-6">
										<label className="form-control-label">
											{male[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={male[selectedLanguage]}
											name="male"
											onChange={handleChange}
											value={values?.employee.male}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="male"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{female[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={female[selectedLanguage]}
											name="female"
											onChange={handleChange}
											value={values?.employee.female}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="female"
											component="div"
										/>
									</div>
								</div>

								<div className="form-group row">
									<h3>{presidentTitle[selectedLanguage]}</h3>
									<br />
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={presidentName[selectedLanguage]}
											name="presidentName"
											onChange={handleChange}
											value={values?.presidentName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{presidentContact[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={presidentContact[selectedLanguage]}
											name="presidentContact"
											onChange={handleChange}
											value={values?.presidentContact}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="presidentContact"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default AgricultureMarket;
