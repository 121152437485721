import React, { Component } from 'react'
import DetailTable from '../components/DetailTable';

const SurveyList = (props) => {
    return (<DetailTable subCatRoute={props.subCatRoute} editData={props.editData} deleteData={props.deleteData} viewData={props.viewData} />);
}

// export default SurveyList;
// class SurveyList extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         }
//     }

//     render() {
//         return (

//         );
//     }
// }

export default SurveyList;