import React from 'react'
import { Formik, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import { Card } from 'react-bootstrap';

import FormFooterButtons from '../components/FormFooterButtons';
import { ward } from '../data/institute';
const BusinessInstitute = (props) => (

    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                ward: "",
                companyName: "",
                annualIncome: "",
                businessName: "",
                businessType: "",
                partnership: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.pondAddress) {
                //     errors.pondAddress = 'Required';
                // }
                // if (!values.address) {
                //     errors.address = 'Required';
                // }
                // if (!values.area) {
                //     errors.area = 'Required';
                // }
                // if (!values.farmType) {
                //     errors.farmType = 'Required';
                // }
                // if (!values.unit) {
                //     errors.unit = 'Required';
                // }
                // if (!values.rate) {
                //     errors.rate = 'Required';
                // }
                // if (!values.animal) {
                //     errors.animal = 'Required';
                // }
                // if (!values.presidentContact) {
                //     errors.presidentContact = 'Required';
                // }
                // if (!values.presidentName) {
                //     errors.presidentName = 'Required';
                // }
                // if (!values.total) {
                //     errors.total = 'Required';
                // }
                // if (!values.fetotal) {
                //     errors.fetotal = 'Required';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>
                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर </label>
                                        <select
                                            className="form-control"
                                            id="ward"
                                            name="ward"
                                            onChange={handleChange}
                                            value={values.ward}
                                        >
                                            {ward.map((woda) => (
                                                <option value={woda.value}>{woda.nepali}</option>
                                            ))}
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >व्यापार तथा व्यसाय संचालक तथा कम्पनीको नाम</label>
                                        <input type="text" className="form-control" placeholder="व्यापार तथा व्यसाय संचालक तथा कम्पनीको नाम" name="companyName" onChange={handleChange} value={values.companyName} />
                                        <ErrorMessage className="invalid-feedback" name="companyName" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >व्यापार र व्यवसायको किसिम</label>
                                        <select className="form-control" id="businessType" name="businessType" onChange={handleChange} value={values.businessType}>
                                            <option disabled selected>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <ErrorMessage className="invalid-feedback" name="businessType" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पसलको प्रकार</label>
                                        <input type="text" className="form-control" placeholder="पसलको प्रकार" name="workerCount" onChange={handleChange} value={values.workerCount} />
                                        <ErrorMessage className="invalid-feedback" name="workerCount" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ब्यापार व्यवसायको स्वामित्व</label>
                                        <input type="number" className="form-control" placeholder="ब्यापार व्यवसायको स्वामित्व" name="male" onChange={handleChange} value={values.male} />
                                        <ErrorMessage className="invalid-feedback" name="male" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वार्षिक आम्दानी</label>
                                        <input type="number" className="form-control" placeholder="वार्षिक आम्दानी" name="annualIncome" onChange={handleChange} value={values.annualIncome} />
                                        <ErrorMessage className="invalid-feedback" name="annualIncome" component="div" />
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>
                    </form>
                </>
            )}
        </Formik>
    </div>

)

export default BusinessInstitute;