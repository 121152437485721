import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
	add,
	bridgeType,
	riverName,
	from,
	to,
	heightUnit,
	type,
	statusBridge,
	address,
	establishDate,
	height,
	name,
	photo,
	wardQuestion,
	areaList,
	ward,
	selectedLanguage,
	cancel,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	riverName: "",
	type: "",
	status: "",
	address: "",
	establishedAt: "",
	bridgeHeight: "",
	bridgeHeightType: "",
	workingBody: "",
	from: "",
	to: "",
	uniqueIdentifier: GetUUID(),
};

const Bridge = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.riskArea) {
				//     errors.riskArea = 'Required';
				// }
				// if (!values.address) {
				//     errors.address = 'Required';
				// }
				// if (!values.effectFromDisasters) {
				//     errors.effectFromDisasters = 'Required';
				// }
				// else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{riverName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={riverName[selectedLanguage]}
											name="riverName"
											onChange={handleChange}
											value={values.riverName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="riverName"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{from[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={from[selectedLanguage]}
											name="from"
											onChange={handleChange}
											value={values.from}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="from"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{to[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={to[selectedLanguage]}
											name="to"
											onChange={handleChange}
											value={values.to}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="to"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{type[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="type"
											name="type"
											onChange={handleChange}
											value={values.type}
										>
											{bridgeType.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="type"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{statusBridge[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={statusBridge[selectedLanguage]}
											name="status"
											onChange={handleChange}
											value={values.status}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="status"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{address[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={address[selectedLanguage]}
											name="address"
											onChange={handleChange}
											value={values.address}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="address"
											component="div"
										/>
									</div>

									<div className="col-md-3">
										<label className="form-control-label">
											{height[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder={height[selectedLanguage]}
											name="bridgeHeight"
											onChange={handleChange}
											value={values.bridgeHeight}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="bridgeHeight"
											component="div"
										/>
									</div>
									<div className="col-md-3">
										<label className="form-control-label">
											{heightUnit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="bridgeHeightType"
											name="bridgeHeightType"
											onChange={handleChange}
											value={values.bridgeHeightType}
										>
											{areaList.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="bridgeHeightType"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{establishDate[selectedLanguage]}
										</label>
										<input
											type="date"
											id="example-date-input"
											className="form-control"
											placeholder={establishDate[selectedLanguage]}
											name="establishedAt"
											onChange={handleChange}
											value={values.establishedAt}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="establishedAt"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Bridge;
