import React from 'react'
import { Card } from 'react-bootstrap';
import LeafletMap from '../LeafletMap';

const NaksaBibaran = ({ houseDetails }) => {
    const zoom = 15;
    return (
        <Card>
            <Card.Body>
                <Card.Title>
                    <h3>नक्सा विवरण</h3>
                </Card.Title>
                <div className="row">
                    <div className="col-12">
                        <div style={{ height: "50vh" }}>
                            <LeafletMap
                                zoom={zoom}
                                // position={position}
                                lat={houseDetails.gps.lat}
                                lng={houseDetails.gps.lng}
                            // addMarker={addMarker}
                            >
                            </LeafletMap>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default NaksaBibaran;