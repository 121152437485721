import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	add,
	gender,
	name,
	age,
	gameName,
	sportsLevelQn,
	sportsLevel,
	genderQn,
	wardQuestion,
	prize,
	ward,
	cancel,
	selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	age: "",
	gender: "",
	gameName: "",
	sportsLevel: "",
	prize: "",
	uniqueIdentifier: GetUUID(),
};

const Sports = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{genderQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="gender"
											name="gender"
											onChange={handleChange}
											value={values.gender}
										>
											{gender.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="gender"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{age[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="age"
											onChange={handleChange}
											value={values.age}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="age"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{prize[selectedLanguage]}
										</label>
										<textarea
											type="text"
											class="form-control"
											id="exampleTextarea"
											rows="3"
											name="prize"
											onChange={handleChange}
											value={values.prize}
										></textarea>
										<ErrorMessage
											className="invalid-feedback"
											name="prize"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{gameName[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											name="gameName"
											onChange={handleChange}
											value={values.gameName}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="gameName"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{sportsLevelQn[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="sportsLevel"
											name="sportsLevel"
											onChange={handleChange}
											value={values.sportsLevel}
										>
											{sportsLevel.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="sportsLevel"
											component="div"
										/>
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default Sports;
