import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
	add,
	wardQuestion,
	photo,
	address,
	name,
	designation,
	phone,
	electionParty,
	selectedLanguage,
	ward,
} from "../data/institute";

const RmFamily = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : {
				ward: "",
				name: "",
				phone: "",
				electionParty: "",
				designation: "",
			}}
			validate={(values) => {
				const errors = {};
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.phone) {
				//     errors.phone = 'Required';
				// }
				// if (!values.electionParty) {
				//     errors.electionParty = 'Required';
				// }
				// if (!values.designation) {
				//     errors.designation = 'Required';
				// }
				// else
				if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					errors.email = "Invalid email address";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{name[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={name[selectedLanguage]}
											name="name"
											onChange={handleChange}
											value={values.name}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{designation[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={designation[selectedLanguage]}
											name="designation"
											onChange={handleChange}
											value={values.designation}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="designation"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>
													{woda[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{phone[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={phone[selectedLanguage]}
											name="phone"
											onChange={handleChange}
											value={values.phone}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="phone"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{electionParty[selectedLanguage]}
										</label>
										<input
											type="text"
											className="form-control"
											placeholder={electionParty[selectedLanguage]}
											name="electionParty"
											onChange={handleChange}
											value={values.electionParty}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="electionParty"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>
								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default RmFamily;
