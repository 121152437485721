import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	ward,
	add,
	cancel,
	palikaDiseaseName,
	sickCount,
	treatmentCount,
	followupCount,
	referCount,
	diseaseDeathCount,
	wardQuestion,
	photo,
	selectedLanguage,
	diseaseList,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
	ward: "",
	name: "",
	sickCount: "",
	treatmentCount: "",
	followupCount: "",
	referCount: "",
	deathCount: "",
	uniqueIdentifier: GetUUID(),
};

const InsidePalikaDisease = (props) => (
	<div>
		<Formik
			enableReinitialize
			initialValues={props.data ? props.data : initData}
			validate={(values) => {
				const errors = {};
				// if (!values.name) {
				//     errors.name = 'Required';
				// }
				// if (!values.ward) {
				//     errors.ward = 'Required';
				// }
				// if (!values.infrastructure) {
				//     errors.infrastructure = 'Required';
				// }
				// if (!values.haatbazaarName) {
				//     errors.haatbazaarName = 'Required';
				// }
				// if (!values.haatrWard) {
				//     errors.haatrWard = 'Required';
				// }
				// if (!values.haatMarket) {
				//     errors.haatMarket = 'Required';
				// }
				// if (!values.haatInfra) {
				//     errors.haatInfra = 'Required';
				// }
				// if (!values.haatType) {
				//     errors.haatType = 'Required';
				// }
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values);
				setTimeout(() => {
					props.submit(values);
					setSubmitting(false);
				}, 400);
			}}
		>
			{({
				values,
				touched,
				errors,
				dirty,
				isSubmitting,
				handleChange,
				handleBlur,
				handleSubmit,
				handleReset,
			}) => (
				<>
					<form className="form survey-form" onSubmit={handleSubmit}>
						<Card>
							<Card.Body>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{palikaDiseaseName[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="name"
											name="name"
											onChange={handleChange}
											value={values.name}
										>
											{diseaseList.map((each) => (
												<option value={each.value}>{each.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="name"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{wardQuestion[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="ward"
											name="ward"
											onChange={handleChange}
											value={values.ward}
										>
											{ward.map((woda) => (
												<option value={woda.value}>{woda.nepali}</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="ward"
											component="div"
										/>
									</div>
								</div>
								<div className="form-group row">
									<div className="col-md-6">
										<label className="form-control-label">
											{sickCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="sickCount"
											onChange={handleChange}
											value={values.sickCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="sickCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{treatmentCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="treatmentCount"
											onChange={handleChange}
											value={values.treatmentCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="treatmentCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{followupCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="followupCount"
											onChange={handleChange}
											value={values.followupCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="followupCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{referCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="referCount"
											onChange={handleChange}
											value={values.referCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="referCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label className="form-control-label">
											{diseaseDeathCount[selectedLanguage]}
										</label>
										<input
											type="number"
											className="form-control"
											placeholder=""
											name="deathCount"
											onChange={handleChange}
											value={values.deathCount}
										/>
										<ErrorMessage
											className="invalid-feedback"
											name="deathCount"
											component="div"
										/>
									</div>
									<div className="col-md-6">
										<label>{photo[selectedLanguage]}</label>
										<ImageUploader fileListContent={props.fileListContent} />
									</div>
								</div>

								<div className="card-footer text-right">
									<FormFooterButtons />
								</div>
							</Card.Body>
						</Card>
					</form>
				</>
			)}
		</Formik>
	</div>
);

export default InsidePalikaDisease;
