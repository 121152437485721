import React from 'react'
import { toAbsoluteUrl } from '../../_metronic/_helpers';

const NoData = (props) => {
    return (
        <div className="no-data-container">
            <h3 className="mb-5">डाटा भेटिएन</h3>
            <img src={toAbsoluteUrl("/media/hamropalika/no-data.svg")} alt="" />
        </div>
    );
}

export default NoData;