import React, { useState } from 'react'
import { useEffect } from 'react';
import Select from "react-select"
const CustomSelectMulti = (props) => {
    const { value, isMulti, list, formik, field, parentObject } = props;
    const [currValue, setCurrValue] = useState([])
    useEffect(() => {
        if (formik.values[field]) {
            const currList = list.filter(i => formik.values[field].includes(i.value)).map((datum) => {
                return {
                    value: datum.value,
                    label: datum.nepali
                }
            })
            setCurrValue(currList)
        }
    }, [value])
    const setFieldValue = (e) => {
        if (e) {
            const newVal = e.map((val) => {
                return val.value
            })
            const currList = e.map((datum) => {
                return {
                    value: datum.value,
                    label: datum.label
                }
            })
            setCurrValue(currList)
            formik.setFieldValue(field, newVal)
        }
        else {
            setCurrValue([])
            formik.setFieldValue(field, [])
        }
        // if (!isMulti) {
        //     formik.setFieldValue(field, e.value)
        // }
        // else {
        //     if (parentObject && formik.values[field]) {
        //         if (formik.values[field].includes(e.value)) {
        //             const newFields = formik.values[field].filter(i => i !== e.value)
        //             formik.setFieldValue(field, newFields)
        //         }
        //         else {
        //             if (formik.values[field].length) {
        //                 const newFields = [...formik.values[field], e.value]
        //                 formik.setFieldValue(field, newFields)
        //             }
        //             else {
        //                 const newFields = [e.value]
        //                 formik.setFieldValue(field, newFields)
        //             }
        //         }
        //     }
        //     else {
        //         const newFields = [e.value]
        //         formik.setFieldValue(field, newFields)
        //     }
        // }
    }
    return (

        <Select onChange={(e) => setFieldValue(e)} value={currValue} isMulti={isMulti} options={list.map((child, i) => {
            return {
                value: child.value,
                label: child.nepali
            }
        })} />
    );
}

export default CustomSelectMulti;