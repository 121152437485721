import React from "react";
import {
	doHouseCriteriaFullfill,
	doHouseCriteriaFullfillQuestion,
	familyRiskPlan,
	familyRiskPlanQn,
	familyRiskPlanYesQn,
	hasHouseMapPass,
	hasHouseMapPassQuestion,
	hasLandDocument,
	hasLandDocumentQuestion,
	houseAge,
	houseCount,
	houseFoundation,
	houseFoundationQn,
	houseMapPass,
	houseType,
	houseTypeQuestion,
	houseUsedLiving,
	houseUsedLivingOption,
	ifOtherValueQuestion,
	isHouseOnRent,
	isHouseOnRentQuestion,
	otherHouseLocation,
	otherHouseLocationQn,
	rentGivenTo,
	rentGivenToQuestion,
	riskAreaAroundHouse,
	riskAreaAroundHouseQn,
	roofType,
	roofTypeQuestion,
	totalRoom,
	totalStorey,
	womanOwnedHome,
	womanOwnedHomeQn,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

const PrathamikGharForm = (props) => {
	const { formik } = props;
	const { houseDetail } = formik.values;
	return (
		<div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
			<div class="col-xl-12 col-xxl-8">
				<div
					class="pb-5"
					data-wizard-type="step-content"
					data-wizard-state="current"
				>
					<h3 class="mb-10 font-weight-bold text-dark">प्रथामिक घर विवरण</h3>
					<div className="row">
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseCount[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name="houseDetail.houseCount"
									onChange={formik.handleChange}
									value={houseDetail.houseCount}
								/>
							</div>
						</div>
						{houseDetail.houseCount > 1 && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{otherHouseLocationQn[selectedLanguage]}</label>
									<SingleSelectInput
										id="otherHouseLocation"
										name={`houseDetail.otherHouseLocation`}
										value={houseDetail[`otherHouseLocation`]}
										formik={formik}
										selectList={otherHouseLocation}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{totalRoom[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name="houseDetail.totalRoom"
									onChange={formik.handleChange}
									value={houseDetail.totalRoom}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{totalStorey[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name="houseDetail.totalStorey"
									onChange={formik.handleChange}
									value={houseDetail.totalStorey}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{isHouseOnRentQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{isHouseOnRent.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.isHouseOnRent`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`isHouseOnRent`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{houseDetail.isHouseOnRent == "rent" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{rentGivenToQuestion[selectedLanguage]}</label>
									<CustomSelectMulti
										value={houseDetail.rentType}
										isMulti={true}
										list={rentGivenTo}
										formik={formik}
										field={"houseDetail.rentType"}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseUsedLiving[selectedLanguage]}</label>
								<div class="radio-inline">
									{houseUsedLivingOption.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.houseUsedLiving`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`houseUsedLiving`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						{houseDetail.houseUsedLiving == "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.houseUsedLivingOther"
										onChange={formik.handleChange}
										value={houseDetail.houseUsedLivingOther}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseTypeQuestion[selectedLanguage]}</label>
								<SingleSelectInput
									id="houseType"
									name={`houseDetail.houseType`}
									onChange={formik.handleChange}
									formik={formik}
									selectList={houseType}
								/>
							</div>
						</div>
						{houseDetail.houseType == "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.houseTypeOther"
										onChange={formik.handleChange}
										value={houseDetail.houseTypeOther}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{roofTypeQuestion[selectedLanguage]}</label>
								<SingleSelectInput
									id="roofType"
									name={`houseDetail.roofType`}
									value={houseDetail[`roofType`]}
									formik={formik}
									selectList={roofType}
								/>
							</div>
						</div>
						{houseDetail.roofType == "other" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.roofTypeOther"
										onChange={formik.handleChange}
										value={houseDetail.roofTypeOther}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{hasLandDocumentQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{hasLandDocument.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.houseConstructionCertificate`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`houseConstructionCertificate`] ==
													value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>
									{doHouseCriteriaFullfillQuestion[selectedLanguage]}
								</label>
								<div class="radio-inline">
									{doHouseCriteriaFullfill.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.doHouseCriteriaFullfill`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`doHouseCriteriaFullfill`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{riskAreaAroundHouseQn[selectedLanguage]}</label>
								<CustomSelectMulti
									value={houseDetail.riskAreaAroundHouse}
									isMulti={true}
									list={riskAreaAroundHouse}
									formik={formik}
									field={"houseDetail.riskAreaAroundHouse"}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{hasHouseMapPassQuestion[selectedLanguage]}</label>
								<div class="radio-inline">
									{hasHouseMapPass.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`houseDetail.hasHouseMapPass`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													houseDetail[`hasHouseMapPass`] == value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseAge[selectedLanguage]}</label>
								<input
									type="number"
									class="form-control"
									name="houseDetail.houseAge"
									onChange={formik.handleChange}
									value={houseDetail.houseAge}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div class="form-group">
								<label>{houseFoundationQn[selectedLanguage]}</label>
								<SingleSelectInput
									t
									id="houseFoundation"
									name={`houseDetail.houseFoundation`}
									value={houseDetail[`houseFoundation`]}
									formik={formik}
									selectList={houseFoundation}
								/>
							</div>
						</div>
						{houseDetail.houseFoundation == "other_foundation" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{ifOtherValueQuestion[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.houseFoundationOther"
										onChange={formik.handleChange}
										value={houseDetail.houseFoundationOther}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{familyRiskPlanQn[selectedLanguage]}</label>
								<SingleSelectInput
									t
									id="isFamilyRiskPlan"
									name={`houseDetail.isFamilyRiskPlan`}
									value={houseDetail[`isFamilyRiskPlan`]}
									formik={formik}
									selectList={familyRiskPlan}
								/>
							</div>
						</div>
						{houseDetail.familyRiskPlan == "plan_yes" && (
							<div className="col-lg-6">
								<div class="form-group">
									<label>{familyRiskPlanYesQn[selectedLanguage]}</label>
									<input
										type="text"
										class="form-control"
										name="houseDetail.familyRiskPlan"
										onChange={formik.handleChange}
										value={houseDetail.familyRiskPlan}
									/>
								</div>
							</div>
						)}
						<div className="col-lg-6">
							<div class="form-group">
								<label>{womanOwnedHomeQn[selectedLanguage]}</label>
								<SingleSelectInput
									t
									id="womanOwnedHome"
									name={`houseDetail.womanOwnedHome`}
									value={houseDetail[`womanOwnedHome`]}
									formik={formik}
									selectList={womanOwnedHome}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrathamikGharForm;
