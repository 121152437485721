import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FileDropZone } from '../../_metronic/layout/components/dropzone/FileDropZone'
import FormFooterButtons from '../components/FormFooterButtons';
import { Card } from 'react-bootstrap';
import ImageUploader from "../components/ImageUploader";

const TechnicalEducation = (props) => (
    <div>
        <Formik
            enableReinitialize
            initialValues={props.data ? props.data : {
                name: "",
                uniqueIdentifier: "",
                ward: "",
                address: "",
                faculty: "",
                level: "",
                mainSubject: "",
                maleStudent: "",
                femaleStudent: "",
                maleTeacher: "",
                femaleTeacher: "",
            }}
            validate={values => {
                const errors = {};
                // if (!values.name) {
                //     errors.name = 'Required';
                // }
                // if (!values.ward) {
                //     errors.ward = 'Required';
                // }
                // if (!values.infrastructure) {
                //     errors.infrastructure = 'Required';
                // }
                // if (!values.haatbazaarName) {
                //     errors.haatbazaarName = 'Required';
                // }
                // if (!values.haatrWard) {
                //     errors.haatrWard = 'Required';
                // }
                // if (!values.haatMarket) {
                //     errors.haatMarket = 'Required';
                // }
                // if (!values.haatInfra) {
                //     errors.haatInfra = 'Required';
                // }
                // if (!values.haatType) {
                //     errors.haatType = 'Required';
                // }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                console.log(values)
                setTimeout(() => {
                    props.submit(values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset, }) => (
                <>
                    <form className="form survey-form" onSubmit={handleSubmit}>
                        <Card>
                            <Card.Body>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >नाम</label>
                                        <input type="text" className="form-control" placeholder="नाम" name="name" onChange={handleChange} value={values.name} />
                                        <ErrorMessage className="invalid-feedback" name="name" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >वडा नम्बर</label>
                                        <input type="number" className="form-control" placeholder="वडा नम्बर" name="ward" onChange={handleChange} value={values.ward} />
                                        <ErrorMessage className="invalid-feedback" name="ward" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >ठेगाना</label>
                                        <input type="text" className="form-control" placeholder="" name="address" onChange={handleChange} value={values.address} />
                                        <ErrorMessage className="invalid-feedback" name="address" component="div" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label>फोटो</label>
                                        <ImageUploader fileListContent={props.fileListContent} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >संकाय</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="sankaya" onChange={handleChange} value={values.sankaya} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="sankaya" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पढाई हुने तह</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="level" onChange={handleChange} value={values.level} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="levelsankaya" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पढाई हुने मुख्य विषयहरु</label>
                                        <textarea type="text" class="form-control" id="exampleTextarea" rows="3" name="mainSubject" onChange={handleChange} value={values.mainSubject} ></textarea>
                                        <ErrorMessage className="invalid-feedback" name="mainSubjectsankaya" component="div" />
                                    </div>
                                </div>
                                <h3>विद्यार्थी संख्या</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >छात्र</label>
                                        <input type="text" className="form-control" placeholder="" name="maleStudent" onChange={handleChange} value={values.maleStudent} />
                                        <ErrorMessage className="invalid-feedback" name="maleStudent" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >छात्रा</label>
                                        <input type="text" className="form-control" placeholder="" name="femaleStudent" onChange={handleChange} value={values.femaleStudent} />
                                        <ErrorMessage className="invalid-feedback" name="femaleStudent" component="div" />
                                    </div>

                                </div>
                                <h3>शिक्षक शिक्षीका</h3>
                                <div className="form-group row">
                                    <div className="col-md-6">
                                        <label className="form-control-label" >पुरुष</label>
                                        <input type="text" className="form-control" placeholder="" name="maleTeacher" onChange={handleChange} value={values.maleTeacher} />
                                        <ErrorMessage className="invalid-feedback" name="maleTeacher" component="div" />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-control-label" >महिला</label>
                                        <input type="text" className="form-control" placeholder="" name="femaleTeacher" onChange={handleChange} value={values.femaleTeacher} />
                                        <ErrorMessage className="invalid-feedback" name="femaleTeacher" component="div" />
                                    </div>

                                </div>
                                <div className="card-footer text-right">
                                    <FormFooterButtons />
                                </div>
                            </Card.Body>
                        </Card>

                    </form>
                </>
            )}
        </Formik>
    </div >
);

export default TechnicalEducation;