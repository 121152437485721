import Table from 'rc-table';
import React, { useEffect, useState } from 'react';
import { selectedLanguage } from '../data/institute';
import { getTableData } from '../modules/InstituteSurvey/_redux/surveyCrud';
import Pagination from './Pagination';

const isNumber = (value) =>
    typeof value === "number" && value === value && Number.isFinite(value);
const SingleDataReport = (props) => {
    const [keys, setKeys] = useState([])
    const [data, setData] = useState(undefined)
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    useEffect(() => {

        const { data, mapValue, mapLabel, header } = props.data;
        setKeys(Object.keys(data))
        setData(data)
        // const pages = Math.ceil(data.length / 10);
        // setTotalPages(pages)
        // // setColumns(title)
        // setData(data)
        // setDisplayData(dispData)
    }, [])
    const handlePageChange = (val) => {
        const start = (val) * 10;
        const end = start + 10;
        const dispData = data.slice(start, end)
        setDisplayData(dispData);
    }
    const renderGeneric = (on, key) => {
        const value = on[key];
        console.log("vals", value)
        console.log("props.data", props.data)
        if (value === undefined || value === null) return null;
        if (Array.isArray(value) && value.length > 0) {
            if (typeof value[0] === "string")
                return renderCommaSeparated(key, value);
            else if (typeof value[0] === "object")
                return renderTable(value, key);
            else return null;
        } else if (typeof value === "object") {
            const { mapLabel } = props.data;
            if (mapLabel[key]) return renderBoxObject(value, key); //if key is exist in mapLabel then render the box else just recursive
            return Object.keys(value).map((k) => renderGeneric(value, k));
        } else if (typeof value === "boolean") {
            return renderBoolean(key, value);
        } else if (typeof value === "string" || isNumber(value)) {
            return renderKey(key, value);
        } else return null;
    };
    const renderCommaSeparated = (key, value) => {
        return (
            <div>
                {renderHeading(key, ":")}
                <h6 style={{ marginLeft: 8 }}>
                    {value.map((each) => getValue(key, each)).join(", ")}
                </h6>
            </div>
        );
    };
    const renderKey = (key, value) => {
        return (
            <div style={{ display: "flex" }}>
                {renderHeading(key, ":")} {renderValue(key, value)}
            </div>
        );
    };

    const renderBoolean = (key, value) => {
        return (
            <div style={{ display: "flex" }}>
                {renderHeading(key, ":")}
                <h6 style={{ marginLeft: 5 }}>
                    {value ? " छ" : " छैन"}
                </h6>
            </div>
        );
    };

    const renderBoxObject = (on, key) => {
        return (
            <div
                className="container"
                style={{
                    padding: 5,
                    margin: "5px 0px",
                }}
            >
                <div className="container">
                    {Object.keys(on).map((k) => renderGeneric(on, k))}
                </div>
                <hr />
            </div>
        );
    };
    const getValue = (key, value) => {
        const { mapValue } = props.data;
        var text = value || "N/A";
        if (mapValue[key]) {
            const foundOne = mapValue[key].find((each) => each.value === value);
            if (foundOne) text = foundOne[selectedLanguage];
        }
        return text;
    };

    const notPrintIF = (key) => {
        return key === "_id" || !key;
    };
    const renderTable = (on, key) => {
        const { mapLabel } = props.data;
        const keys = Object.keys(on[0]);
        var columns = [];
        keys.forEach((each) => {
            if (notPrintIF(each)) return;
            else
                columns.push({
                    title: (
                        <p style={{ fontSize: 14, fontWeight: 600 }}>
                            {mapLabel[each] || each}
                        </p>
                    ),
                    dataIndex: each,
                    key: each,
                    render: (text) => <p>{text}</p>,
                });
        });
        var data = [];
        on.forEach((each) => {
            if (notPrintIF(each)) return;
            let body = {};
            keys.forEach((one) => (body[one] = getValue(one, each[one])));
            data.push(body);
        });
        console.log("data", data);
        return (
            <Table className="table table-hover"
                style={{ width: "100%" }}
                columns={columns}
                data={data}
            />
        );
    }
    const renderHeading = (text, extraText = "") => {
        const { mapLabel } = props.data;
        console.log(props.data)
        console.log("maplable", mapLabel)
        return (
            <h6 >
                {(mapLabel[text] || text) + extraText}
            </h6>
        );
        // <p style={style.p}>{(mapLabel[text] || text) + extraText} </p>;
    };
    const renderValue = (key, value) => {
        return (
            <h6 style={{ marginLeft: 8 }}>
                {isNaN(value)
                    ? getValue(key, value)
                    : (getValue(key, value))}
                {/* {getNepaliNumber(getValue(key, value))} */}
            </h6>
        );
    };
    return (
        <>
            {data && keys.map((key) => renderGeneric(data, key))}
            {/* <Table className="table table-hover" columns={columns} data={displayData} />
            <Pagination pages={totalPages} handlePageChange={handlePageChange} /> */}
        </>
    );
}

export default SingleDataReport;